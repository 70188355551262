import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import {Breadcrumbs} from "../../../../Components";
import {FundingRequestsPanel} from "./Panels/FundingRequestsPanel";
import {useDispatch, useSelector} from "react-redux";
import {
    CompanyWiseOutstandingPanel,
    BusinessStatsPanel,
    UpcomingPaymentPanel,
    OverduePaymentPanel,
    InvoicesPanel,
    CustomersPanel,
    FundingStatsPanel,
    ProposalStatsPanel, DefaultPaymentPanel
} from "../index";
import {setConfig} from "../../../Corporate/Services";
import {Loader} from "../../../../Components/Elements/Loader";
import {
    DisbursementCollectionMiniChart
} from "../../../Corporate/FundStatement";
import {TasksPanel} from "./Panels/TasksPanel";

export const DashboardAdministration = () => {

    const dispatch = useDispatch();
    const permissions = useSelector((store) => store.app.permissions);
    const serviceFilter = useSelector((store) => store.services.config.filters);
    const services = useSelector((store) => store.app.services);
    const [isConfigSet, setIsConfigSet] = useState(false);

    useEffect(() => {
        const updatedServiceFilter = {...serviceFilter, status: ['PENDING','VERIFIED','APPROVED','IN_PROGRESS']}
        dispatch(setConfig({filters:updatedServiceFilter, pagination:{page:0, pageSize:5}}));
        setIsConfigSet(true);
    },[]);

    const DashboardContent = () => (
        <>
            <Grid container spacing={2} direction="row" justifyContent="top" alignItems="center">
                <Grid spacing={2} item container direction="row">
                    <Grid item xs={12}>
                        <Breadcrumbs items={'DASHBOARD'} />
                    </Grid>
                </Grid>
                <Grid spacing={2} container direction="row" justifyContent="top">
                    <Grid item md={3} xs={6}>

                    </Grid>
                </Grid>

                <Grid spacing={2} item container direction="row" justifyContent="top">
                    { (permissions).includes("show-business-stats-panel") &&
                    <Grid item lg={2} md={4} xs={12}>
                        <BusinessStatsPanel />
                    </Grid>
                    }

                    { (permissions).includes("show-proposal-stats-panel") &&
                        <Grid item lg={2} md={4} xs={12}>
                            <ProposalStatsPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-funding-stats-panel") &&
                        <Grid item lg={2} md={4} xs={12}>
                            <FundingStatsPanel />
                        </Grid>
                    }

                    <Grid item xs={12} md={12} lg={6}>
                        <DisbursementCollectionMiniChart />
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    { (permissions).includes("show-company-wise-panel") &&
                        <Grid item xs={12}>
                            <CompanyWiseOutstandingPanel />
                        </Grid>
                    }
                </Grid>

                <Grid spacing={2} item container direction="row" justifyContent="top">

                    { (permissions).includes("show-funding-requests-panel") &&
                        <Grid item md={12} lg={6} xs={12}>
                            <FundingRequestsPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-upcoming-payments-panel") &&
                        <Grid item md={12} lg={6} xs={12}>
                            <UpcomingPaymentPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-overdue-payments-panel") &&
                        <Grid item md={12} lg={6} xs={12}>
                            <OverduePaymentPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-task-panel") &&
                        <Grid item md={12} lg={6} xs={12}>
                            <TasksPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-invoices-panel") && (services !== undefined && services.some((s) => s.short_code === 'ID')) &&
                        <Grid item md={12} lg={6} xs={12}>
                            <InvoicesPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-customers-panel") && (services !== undefined && services.some((s) => s.short_code === 'ID')) &&
                        <Grid item md={12} lg={6} xs={12}>
                            <CustomersPanel />
                        </Grid>
                    }

                    { (permissions).includes("show-overdue-payments-panel") &&
                        <Grid item md={12} lg={6} xs={12}>
                            <DefaultPaymentPanel />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            { isConfigSet ? ( <DashboardContent /> )
            :(
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{height:'80vh'}}>
                <Loader type="linear" height={3} label="Preparing Dashboard..." fontSize={28} />
            </Grid>
            )}
        </>
    );
}
