import CONST from "./Constant";

const INITIAL_STATE = {
    fundedStatement:{
        list:[],
        selected:{},
        error: [],
        code: "",
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:10,
            },
            filters:{
                keyword:'',
                sort_by:'total_funding',
                sort_dir:'desc',
            }
        },
    },
    overdueStatement:{
        list:[],
        selected:{},
        error: [],
        code: "",
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:10,
            },
            filters:{
                keyword:'',
                sort_by:'overdue',
                sort_dir:'desc',
            }
        },
    },
    earningStatement:{
        list:[],
        selected:{},
        error: [],
        code: "",
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:10,
            },
            filters:{
                keyword:'',
                sort_by:'earning',
                sort_dir:'desc',
            }
        },
    },
    outstandingStatement:{
        list:[],
        selected:{},
        error: [],
        code: "",
        config:{
            from:0,
            to:0,
            lastPage:1,
            rowCount:0,
            pagination:{
                page:0,
                pageSize:10,
            },
            filters:{
                keyword:'',
                sort_by:'outstanding',
                sort_dir:'desc',
            }
        },
    },


};

const Reducer = (state = INITIAL_STATE, action) => {

    switch (action.type){

        case CONST.SET_FUNDED_CONFIG:
            return {
                ...state,
                fundedStatement: {
                    ...state.fundedStatement,
                    config: {
                        ...state.fundedStatement.config,
                        ...action.payload
                    }
                }
            };
        case CONST.FETCH_FUNDED_STATEMENT_SUCCESS:
            return {
                ...state,
                fundedStatement: {
                    list:action.payload.data.data,
                    config:{
                        ...state.fundedStatement.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.fundedStatement.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    code:action.payload.status,
                    message: ["Funded Statement loaded successfully"],
                }
            }
        case CONST.SET_OVERDUE_CONFIG:
            return {
                ...state,
                overdueStatement: {
                    ...state.overdueStatement,
                    config: {
                        ...state.overdueStatement.config,
                        ...action.payload
                    }
                }
            };
        case CONST.FETCH_OVERDUE_STATEMENT_SUCCESS:
            return {
                ...state,
                overdueStatement: {
                    list:action.payload.data.data,
                    config:{
                        ...state.overdueStatement.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.overdueStatement.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    code:action.payload.status,
                    message: ["Overdue Statement loaded successfully"],
                }
            }

        case CONST.SET_EARNING_CONFIG:
            return {
                ...state,
                earningStatement: {
                    ...state.earningStatement,
                    config: {
                        ...state.earningStatement.config,
                        ...action.payload
                    }
                }
            };
        case CONST.FETCH_EARNING_STATEMENT_SUCCESS:
            return {
                ...state,
                earningStatement: {
                    list:action.payload.data.data,
                    config:{
                        ...state.earningStatement.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.earningStatement.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    code:action.payload.status,
                    message: ["Earning Statement loaded successfully"],
                }
            }

        case CONST.SET_OUTSTANDING_CONFIG:
            return {
                ...state,
                outstandingStatement: {
                    ...state.outstandingStatement,
                    config: {
                        ...state.outstandingStatement.config,
                        ...action.payload
                    }
                }
            };
        case CONST.FETCH_OUTSTANDING_STATEMENT_SUCCESS:
            return {
                ...state,
                outstandingStatement: {
                    list:action.payload.data.data,
                    config:{
                        ...state.outstandingStatement.config,
                        from:action.payload.data.from,
                        to:action.payload.data.to,
                        lastPage:action.payload.data.last_page,
                        rowCount:action.payload.data.total,
                        pagination:{
                            ...state.outstandingStatement.config.pagination,
                            page:action.payload.data.current_page - 1,
                            pageSize:action.payload.data.per_page,
                        },
                    },
                    error: [],
                    code:action.payload.status,
                    message: ["Outstanding Statement loaded successfully"],
                }
            }
        case CONST.FETCH_FUNDED_STATEMENT_FAILED:
        case CONST.FETCH_OVERDUE_STATEMENT_FAILED:
        case CONST.FETCH_EARNING_STATEMENT_FAILED:
        case CONST.FETCH_OUTSTANDING_STATEMENT_FAILED:
            return {
                ...state,
                error: action.payload.data.errors,
                message: action.payload.data.message,
                code:action.payload.status,
            }
        default:
            return state;
    }
}

export default Reducer ;