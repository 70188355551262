import {combineReducers} from "redux";

import AppReducer from '../../Modules/Common/App/Redux/Reducer';
import AuthReducer from '../../Modules/Common/Auth/Redux/Reducer';
import MonitorReducer from '../../Modules/Administration/Monitor/Redux/Reducer';
import TaskReducer from "../../Modules/Common/Tasks/Redux/Reducer";
import BusinessReducer from '../../Modules/Corporate/Business/Redux/Reducer';
import UserReducer from '../../Modules/Administration/Users/Redux/Reducer';
import ProfileReducer from '../../Modules/Investor/Profile/Redux/Reducer';
import RoleReducer from "../../Modules/Administration/Roles/Redux/Reducer";
import PermissionReducer from "../../Modules/Administration/Permissions/Redux/Reducer";
import NotificationReducer from "../../Modules/Common/Notifications/Redux/Reducer";
import SettingReducer from "../../Modules/Administration/Settings/Redux/Reducer";
import CommentReducer from "../../Modules/Corporate/Comments/Redux/Reducer";
import SummaryReducer from "../../Modules/Corporate/Summary/Redux/Reducer";
import FundStatementReducer from "../../Modules/Corporate/FundStatement/Redux/Reducer";

import AdminDashboardReducer  from "../../Modules/Administration/Dashboard/Redux/Reducer";
import InvestorDashboardReducer from "../../Modules/Investor/Dashboard/Redux/Reducer";
import CorporateDashboardReducer from "../../Modules/Corporate/Dashboard/Redux/Reducer";
import ServiceReducer from "../../Modules/Corporate/Services/Redux/Reducer";
import AccountReducer from "../../Modules/Corporate/Accounts/Redux/Reducer";
import InvoiceReducer from "../../Modules/Corporate/Invoices/Redux/Reducer";
import LoanReducer from "../../Modules/Corporate/Loan/Redux/Reducer";
import DocumentReducer from "../../Modules/Common/Documents/Redux/Reducer";

import ListingReducer from "../../Modules/Investor/Listings/Redux/Reducer";
import FundingReducer from "../../Modules/Investor/Fundings/Redux/Reducer";
import WalletReducer from "../../Modules/Investor/Wallets/Redux/Reducer";
import RequestReducer from "../../Modules/Investor/Requests/Redux/Reducer";
import TransactionReducer from "../../Modules/Investor/Transactions/Redux/Reducer";
import PositionReducer from "../../Modules/Investor/Positions/Redux/Reducer";
import RepaymentReducer from "../../Modules/Investor/Repayments/Redux/Reducer";
import StatementReducer from "../../Modules/Investor/Statements/Redux/Reducer";


export const defaultReducer = combineReducers({
    app: AppReducer,
    auth:AuthReducer,
})

export const adminReducer = combineReducers({
    app: AppReducer,
    auth:AuthReducer,
    monitor:MonitorReducer,
    accounts:AccountReducer,
    dashboard:AdminDashboardReducer,
    fundStatement:FundStatementReducer,
    documents:DocumentReducer,
    users:UserReducer,
    roles:RoleReducer,
    permissions:PermissionReducer,
    business:BusinessReducer,
    invoices:InvoiceReducer,
    loans:LoanReducer,
    services:ServiceReducer,
    notifications:NotificationReducer,
    fundings:FundingReducer,
    listings:ListingReducer,
    wallets:WalletReducer,
    requests: RequestReducer,
    transactions:TransactionReducer,
    positions:PositionReducer,
    repayments:RepaymentReducer,
    tasks:TaskReducer,
    settings:SettingReducer,
    comments:CommentReducer,
    summary: SummaryReducer,
})

export const staffReducer = combineReducers({
    app: AppReducer,
    auth:AuthReducer,
    accounts:AccountReducer,
    dashboard:AdminDashboardReducer,
    fundStatement:FundStatementReducer,
    documents:DocumentReducer,
    business:BusinessReducer,
    invoices:InvoiceReducer,
    loans:LoanReducer,
    services:ServiceReducer,
    notifications:NotificationReducer,
    fundings:FundingReducer,
    listings:ListingReducer,
    wallets:WalletReducer,
    requests: RequestReducer,
    transactions:TransactionReducer,
    positions:PositionReducer,
    repayments:RepaymentReducer,
    tasks:TaskReducer,
    comments:CommentReducer,
    summary: SummaryReducer,
})

export const corporateReducer = combineReducers({
    app: AppReducer,
    auth:AuthReducer,
    accounts:AccountReducer,
    dashboard:CorporateDashboardReducer,
    documents:DocumentReducer,
    business:BusinessReducer,
    invoices:InvoiceReducer,
    loans:LoanReducer,
    services:ServiceReducer,
    tasks:TaskReducer,
})

export const investorReducer = combineReducers({
    app: AppReducer,
    auth:AuthReducer,
    profile: ProfileReducer,
    dashboard:InvestorDashboardReducer,
    documents:DocumentReducer,
    listings:ListingReducer,
    fundings:FundingReducer,
    wallets:WalletReducer,
    requests: RequestReducer,
    transactions:TransactionReducer,
    positions:PositionReducer,
    repayments:RepaymentReducer,
    statements:StatementReducer,
    tasks:TaskReducer,
})