import React, {useEffect, useState} from 'react'
import {Grid, Stack, } from "@mui/material";
import {useDispatch,} from "react-redux";
import {Breadcrumbs} from "../../../../Components";
import {
    fetchCustomer,
} from "../index";
import ActionButtons from "./ActionButtons";
import CustomerDetailsCard from "./Cards/CustomerDetailsCard";
import CustomerConfigPanel from "./Panels/CustomerConfigPanel";
import {CustomerPropertiesPanel} from "./Panels/CustomerPropertiesPanel";
import {useParams} from "react-router-dom";
import DocumentsList from "../../../Common/Documents/Components/DocumentsList";

export const CustomerView = () => {

    const { customer_id } = useParams();
    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
    },[customer_id])

    const fetchData = async() => {
        await fetchCustomer({dispatch, id:customer_id}).then((res) => {
            setResponse(res);
        });
    }

    return (
        <>
            {(response?.success !== undefined) && (
                <Grid container spacing={2} elevation={3}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <Breadcrumbs items={'DASHBOARD/CUSTOMERS'} />
                            <ActionButtons />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <CustomerDetailsCard />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={2}>
                            <DocumentsList entityId={customer_id} tab={"customers"} setResponse={setResponse} />
                            <CustomerPropertiesPanel />
                            <CustomerConfigPanel />
                        </Stack>
                    </Grid>
                </Grid>
            )}
        </>
    );
}