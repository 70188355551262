import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate } from "react-router-dom";
import {fetchLoans, setConfig} from "../../Redux/Action";
import {DataGridPro} from "@mui/x-data-grid-pro";
import moment from "moment";
import {currencyFormatter} from "../../../../../Setup/Config";
import {isBrowser} from "react-device-detect";
import {StatusBall} from "../../../../../Components";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {Typography, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";

export const LoanTable = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector((store) => store.loans);
    const auth = useSelector((store) => store.auth);
    const filters = useSelector((store) => store.loans?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.loans?.config?.pagination);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();

    },[pagination, filters]);

    const fetchData = async() => {
        await fetchLoans({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/business-finance/' + id);
        },
        [],
    );

    const columns = React.useMemo(
        () => [

            { field: 'loan_no', align:'center', headerAlign:"center", headerName:'C No', type: 'string', width: 70 },
            { field: 'Related', align:'center', headerAlign:"center", headerName:'Related', type: 'string', flex:1, minWidth: 80,
                renderCell: (params) => {
                    return (
                        <Tooltip title={params.row?.restructured_loan?.amount ?? currencyFormatter.format(params.row?.restructured_loan?.amount)}>
                        <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.row?.restructured_loan?.loan_id)}>{params.row?.restructured_loan?.loan_no}</Typography>
                    </Tooltip>);
                }
            },
            { field: 'business_name', headerName:'Business', type: 'string', minWidth:250, flex: 1,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.row?.business?.business_id)}>{params.row?.business?.name}</Typography>
                }
            },
            { field: 'amount', headerName:'Amount', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'start_date', headerName:'Start Date', type: 'string', flex:1, minWidth:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),
            },
            { field: 'end_date', headerName:'End Date', type: 'string', flex:1, minWidth:100, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY") },
            { field: 'repayment_period', align:'center', headerAlign:"center", headerName:'Period', type: 'string', flex:1, minWidth: 80 },
            { field: 'repayment_frequency', align:'center', headerAlign:"center", headerName:'Frequency', type: 'string', flex:1, minWidth: 90 },

            { field: 'status', align:'center', headerAlign:"center", headerName:'Status', type: 'string', flex:1, minWidth:120,
                renderCell: (params) => {
                    const value = params.value?.name ?? 'DEFAULT';
                    const status = {
                        'DRAFT': 'warning',
                        'ACTIVE':'primary',
                        'PAID':'success',
                        'DOCUMENTED':'info',
                        'OVERDUE':'error',
                        'DEFAULT':'error',
                        'CANCELLED':'error',
                        'DELETED':'secondary',
                        'VERIFIED':'success',
                        'RETURNED':'warning',
                        'REJECTED':'error',
                        'RESTRUCTURED':'warning',
                    }
                    return(<StatusBall
                                variant="contained"
                                sx={{fontWeight:'normal'}}
                                label={value}
                                color={status[value]}
                            />)
                },
            },
            { field: 'service_type', headerName:'Service Type', type: 'string', minWidth: 220, flex:1,
                valueGetter: (params) => {
                    return params.row?.service?.service_type?.name;
                }
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Details',
                width: 70,
                getActions: (params) => [
                    (['DRAFT','RETURNED'].includes(params.row.status) && auth.user.role_type === 'CLIENT') ||
                    (['SUBMITTED','PROCESSING','PENDING'].includes(params.row.status) && auth.user.role_type !== 'CLIENT')
                        ?
                        <GridActionsCellItem
                            icon={<EditIcon sx={{zIndex:-1}} />}
                            label="Edit"
                            data-id={params.id}
                            data-action={"EditItem"}
                        />
                    :
                        <GridActionsCellItem
                            icon={<FindInPageIcon />}
                            label="View"
                            onClick={ viewItem(params.id) }
                        />
                    ,
                ],
            },
        ],
        [],
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.loan_id}
                    columns={columns}
                    rows={items.list}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={pagination}
                    rowCount={items?.config?.rowCount ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => !isLoading && (setIsLoading(true) || dispatch(setConfig({ pagination: { ...pagination, page: params.page, pageSize: params.pageSize } })))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'start_date', sort: 'desc' }],
                        },
                        pinnedColumns: { right: isBrowser ?  ['actions'] : [] },
                        columns: {
                            columnVisibilityModel: {
                            }
                        },
                        pagination: { paginationModel: { ...pagination } },
                    }}
                />
            </>
        );
}
