import React, {useEffect, useState} from 'react'
import {Stack} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../../Common/App";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {relateBusiness} from "../../Redux/Action";
import {clearData} from "../../../../Administration/Users";
import {useNavigate} from "react-router-dom";
import {BusinessAutocomplete} from "../BusinessAutocomplete";

export const RelateBusinessModal = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business = useSelector((store) => store.business.selected);
    const [disabled, setDisabled] = useState(true);
    const [response, setResponse] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        dispatch(clearData());
        setData({
            business_id: business.business_id,
            related_business_id: null,
        });
    }, [business]);

    const handleSubmit = async () => {
        setDisabled(true);
        await relateBusiness({dispatch, data}).then((res) => {
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    return(
        <>
            <DialogTitle>Related Business</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText>
                        Select the business you want to relate this business with.
                    </DialogContentText>
                    <BusinessAutocomplete errors={response?.error?.data?.errors} setDisabled={setDisabled} setData={setData} data={data} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Relate</Button>
            </DialogActions>
        </>
    )
}
