import * as React from 'react';
import {
    Button,
    Grid,
    Card,
    Typography,
    List as ListUI,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Chip,
    Stack
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useDispatch, useSelector} from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import {addComment, fetchComments} from "../../Redux/Action";
import {setModal} from "../../../../Common/App";
import {styled} from "@mui/material/styles";
import moment from "moment";
import Toastr from "../../../../../Components/Elements/Toastr";

export function CommentCard({section}) {

    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const items = useSelector((store) => store.comments.list);
    const auth = useSelector((state) => state.auth);
    const business = useSelector((store) => store.business.selected);
    const permissions = useSelector((state) => state.app.permissions);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [hideInput, setHideInput] = useState(true);

    useEffect(() => {
        if (business) {
            const newData = {entity: sections[section], business_id: business.business_id, };
            setData(newData);
            fetchData(newData);
        }
    }, [business, section]);

    const fetchData = async (data) => {

        if(business !== undefined){
            await fetchComments({dispatch, data});
        }
    }

    const handleSubmit = async () => {

        setDisabled(true);
        await addComment({dispatch, data}).then((res)=>{
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                setData({...data, comment: ''});
            }
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData({...data, [e.target.name]: e.target.value});
    }

    const handleOnFocus = () => {

        const currentItem = items.find(item => item.entity_type === sections[section] && item.status_id === business.status_id);

        setData({...data, comment: currentItem?.comment ?? ''});
    }

    const handleCancel = () => {
        setData({...data, summary: ''});
        setHideInput(!hideInput);
    }

    const handleClick = () => {
        setHideInput(!hideInput);
    }

    const sections = {
        "business" : "PROFILE",
        "partners" : "PARTNER",
        "bankDetails" : "BANK_DETAIL",
        "bankBorrowings" : "BANK_BORROWING",
        "otherBorrowings" : "OTHER_BORROWING",
        "cheques" : "CHEQUE",
        "creditCards" : "CREDIT_CARD",
        "financials" : "FINANCIAL",
        "customers" : "CUSTOMER",
        "customersSuppliers" : "CUSTOMER_SUPPLIER",
        "vatReturns" : "VAT",
        "wpsDetails" : "WPS",
        "addresses" : "ADDRESS",
    }

    const List = styled(ListUI)(({ theme, business }) => ({
        '& .MuiListItem-root': {
            cursor: business?.status !== 'APPROVED' ? 'pointer' : '',
            backgroundColor: '#f8f8f8',
            marginBottom: '0.5rem',
            borderRadius: '0.5rem',
            border: '1px solid #ffffff',
        },
        '& .MuiListItem-root:hover': {
            backgroundColor: '#f0f0f0',
            transition: '0.3s',
            border: '1px solid #e0e0e0',
        }
    }));

    const PrimaryText = ({item}) => {

        return (
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Chip label={item.status?.name} variant="outlined" size="small" />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="body2" sx={{color:'#999999'}}>{item.created_by.first_name + ' ' + item.created_by.last_name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" sx={{color:'#999999'}}>{moment(item.created_at).format('DD/MM/YYYY, h:mm a')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: '1rem', color: '#444444' }}>{item.comment}</Typography>
                </Grid>
            </Grid>
        )
    }

    const isAllowedToComment = business.assigned_to.user_id === auth.user.user_id;

    return (
        <>
            <Card sx={{ overflow:'visible' }}>
                <CardHeader title={"Comments"} titleTypographyProps={{variant: 'headline'}}
                    action={ <IconButton onClick={() => setIsExpanded(!isExpanded)} > {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} </IconButton> }
                />
                <Collapse in={isExpanded}>
                    <CardContent sx={{paddingBottom:'1rem!important'}}>
                        {items && items.length > 0 ?
                        <List>
                            {items && items.map((item, index) => (
                                <ListItem key={index} onClick={ () => handleClick() }>
                                    <ListItemText primary={<PrimaryText item={item} />}/>
                                </ListItem>
                            ))}
                        </List> :
                            <>
                                <Typography>No Comments</Typography>
                            </>
                        }

                        {(permissions.includes('add-business-comment') && isAllowedToComment && business.status?.name !== 'APPROVED') && hideInput &&
                            <TextField
                                fullWidth
                                label="Your Comments"
                                multiline
                                name={"comment"}
                                onFocus={ () => handleOnFocus() }
                                rows={4}
                                value={data['comment'] ?? ''}
                                variant="outlined"
                                onChange={handleChange}
                            />
                        }
                    </CardContent>
                    { isAllowedToComment && (
                    ((permissions.includes('add-business-comment') && business.status?.name !== 'APPROVED') && hideInput) ?
                        <CardActions sx={{p:2}}>
                            <Button disabled={disabled} variant={"contained"} color={"primary"} onClick={ () => handleSubmit() }>Add Notes</Button>
                            <Button variant={"contained"} color={"secondary"} onClick={ () => handleCancel() }>Close</Button>
                        </CardActions>
                    :
                        <CardActions sx={{p:2}}>
                            <Button variant={"contained"} color={"primary"} onClick={ () => handleClick() }>Add New</Button>
                        </CardActions>
                    )}
                </Collapse>
            </Card>
            <Toastr response={response} />
        </>
    );
}
