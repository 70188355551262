import React from 'react'
import {CardContent, CardHeader, Grid, Box} from "@mui/material";
import {Breadcrumbs, Panel} from "../../../../Components";
import { FundedStatementTable } from "../index";

export const FundedStatement = () => {

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Box justifyContent={"space-between"} display={"flex"}>
                        <Breadcrumbs items={'DASHBOARD/FUND_STATEMENT/FUNDED'} />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <CardHeader
                            title={"Funded Statement"}
                        />
                        <CardContent>
                            <FundedStatementTable />
                        </CardContent>
                    </Panel>
                </Grid>
            </Grid>
        </>
    );
}
