import React from 'react';
import {LinearProgress as LinearProgressUI, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import { Box, Typography, Stack } from '@mui/material';
import {styled} from "@mui/material/styles";

export const Loader = ({ type, height, label, fontSize = 12, color = 'primary', variant = 'indeterminate', value = 0, valueBuffer = 0, size = 40, styles = {} }) => {

    const LinearProgress = styled(LinearProgressUI)(({ theme }) => ({
        height: 3,
        borderRadius: 5,
        [`&.${LinearProgressUI}`]: {
            backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : '#303030',
        },
        [`& .${LinearProgressUI}-bar`]: {
            borderRadius: 5,
            backgroundColor: color === 'primary' ? theme.palette.primary.main : theme.palette[color].main,
        },
    }));

    const renderLoader = () => {
        if (type === 'linear') {
            return (
                <Stack direction="column" alignItems="center">
                    {label && (
                        <Typography
                            fontSize={fontSize}
                            variant="body2"
                            align="center"
                            sx={{
                                fontFamily:'Open Sans',
                                fontWeight: 300,
                                marginBottom: '8px',
                                fontSmooth: 'always',
                                WebkitFontSmoothing: 'antialiased',
                                MozOsxFontSmoothing: 'grayscale'
                            }}
                        >
                            {label}
                        </Typography>
                    )}
                    <LinearProgress
                        color={color}
                        variant={variant}
                        value={value}
                        valueBuffer={variant === 'buffer' ? valueBuffer : undefined}
                        sx={{ width: '100%', ...styles }}
                    />
                </Stack>
            );
        } else if (type === 'circular') {
            return (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <CircularProgress
                        color={color}
                        variant={variant}
                        value={value}
                        size={size}
                        sx={{ ...styles }}
                    />
                    {label && (
                        <Typography fontSize={fontSize} variant="body2" align="center" sx={{ marginTop: '8px' }}>
                            {label}
                        </Typography>
                    )}
                </Box>
            );
        } else {
            return null;
        }
    };

    return <>{renderLoader()}</>;
};


Loader.propTypes = {
    type: PropTypes.oneOf(['linear', 'circular']).isRequired,
    label: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
    variant: PropTypes.oneOf(['determinate', 'indeterminate', 'buffer', 'query']),
    value: PropTypes.number,
    valueBuffer: PropTypes.number,
    size: PropTypes.number,
    styles: PropTypes.object,
    fontSize: PropTypes.number,
};