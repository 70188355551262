import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid} from 'recharts';
import {Panel} from "../../../../../Components";
import {fetchMonthlyStats} from "../../Redux/Action";
import {MonthlyPaymentDateRangeSelectorPopover} from "../Popover/MonthlyPaymentDateRangeSelectorPopover";
import {isBrowser} from "react-device-detect";
import {Typography, Card as CardUI, Box, Card, CardHeader, CardContent} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useParams} from "react-router-dom";
import {currencyFormatter} from "../../../../../Setup/Config";

export const MonthlyPaymentPanel = () => {

    const dispatch = useDispatch();
    const {business_id} = useParams();
    const data = useSelector(store => store.dashboard.monthlyStats);

    useEffect(() => {
        fetchData();
    },[]);

    let fetchData = async () => {
        await fetchMonthlyStats({dispatch, data:{dates:[], business_id}}).then((res) => {
            if (res?.success?.status === 200) {
                // console.log(res?.success?.data?.monthlyStats);
            }
        });
    }

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle " fontSize={11} fill="#666" transform="rotate(-0)">
                    {payload.value}
                </text>
            </g>
        );
    };

    const calculateBarSize = (dataLength) => {
        const barSizes = {
            1: 10,
            2: 10,
            3: 10,
            4: 10,
            5: 10,
            6: 10,
            7: 10,
            8: 10,
            9: 10,
            10: 10,
            11: 10,
            12: 10,
        };
        return barSizes[dataLength] || 15; // Default size if data length exceeds predefined sizes
    };

    const barSize = calculateBarSize(data.length);

    const CustomTooltip = ({ active, payload, label }) => {

        const months = {
            Dec: "December",
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November"
        };

        const Card = styled(CardUI)({
            boxShadow:'0px 0px 6px 0px rgba(0,0,0,0.2)',
            '& .MuiCardHeader-root':{
                padding:8
            },
            '& .MuiCardContent-root':{
                padding:8
            }
        })

        if (active && payload && payload.length) {
            const payload0 = currencyFormatter.format(payload[0].value);
            const payload1 = currencyFormatter.format(payload[1].value);
            return (
                <Card sx={{background:'rgba(255,255,255,0.8)'}}>
                    <CardHeader title={months[label]} />
                    <CardContent>
                        <Typography>{`${payload[0].name} : ${payload0}`}</Typography>
                        <Typography>{`${payload[1].name} : ${payload1}`}</Typography>
                    </CardContent>
                </Card>
            );
        }

        return null;
    };

    const CustomizedYAxisTick = ({ x, y, payload }) => (
        <g transform={`translate(${x},${y})`}>
            {/*<line x2={-10} stroke="#666" strokeDasharray="3 3" />*/}
            <text x={-16} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">{payload.value}</text>
        </g>
    );

    return (
        <>
            <Panel>
                <CardHeader
                    title={"Monthly Payment Overview"}
                    // action={<MonthlyPaymentDateRangeSelectorPopover />}
                />
                <CardContent sx={{ padding: '15px!important', display:'flex', justifyContent:'center' }}>
                    <ResponsiveContainer width="100%" height={ isBrowser ? 160 : 200} >
                        { data.length === 0 ?
                            <Box display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  height="100vh">
                                <Typography>No Data to show</Typography>
                            </Box>
                            :
                        <BarChart
                            height={300}
                            data={data}
                            sx={{ width: '100%'}} // Set the width to 100%
                        >
                            <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                            <XAxis dataKey="month" tick={<CustomizedAxisTick />} axisLine={false} tickLine={false} />
                            <YAxis tick={<CustomizedYAxisTick />} tickLine={false} axisLine={false}  />
                            <Tooltip content={<CustomTooltip />}  />
                            <Legend wrapperStyle={{ marginTop: '10px', fontSize: '11px' }} />
                            <Bar dataKey="Paid" label={"Paid"} formatter={(value) => `AED ${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`} fill="#3597BD" barSize={barSize} radius={[10, 10, 0, 0]} />
                            <Bar dataKey="Outstanding" label={"Outstanding"} formatter={(value) => `AED ${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`} fill="#e56305" barSize={barSize} radius={[10, 10, 0, 0]} />
                        </BarChart>}
                    </ResponsiveContainer>

                </CardContent>
            </Panel>
        </>
    );
}
