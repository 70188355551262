import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    DialogTitle,
    DialogContent,
    Grid,
    DialogActions,
    TextField, FormControl, Box, Stack, InputLabel, Select, FormHelperText, List, ListItem, ListItemText
} from "@mui/material";
import { setModal } from "../../../../Common/App";
import { useEffect, useRef, useState } from "react";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import SelectCountry from "../../../../../Components/Elements/SelectCountry";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { updateExpiredDocument } from "../../Redux/Action";
import Divider from "@mui/material/Divider";
import {Link} from "react-router-dom";

export const UploadExpiredDocumentModal = () => {

    const dispatch = useDispatch();
    const item = useSelector((store) => store.dashboard?.tasks.selected);
    const submitted = item.meta.submitted ?? {};
    const [data, setData] = useState({});
    const [response, setResponse] = useState({});
    const [formSection, setFormSection] = useState(null);
    const [type, setType] = useState(null);
    const fileInputRef = useRef(null); // Single fileInputRef
    const lov = useSelector((store) => store.app.lov);

    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState(false);

    useEffect(() => {
        console.log(item?.meta?.requested?.entity_name)
        setFormSection(item?.meta?.requested?.entity_name);
        setType(item?.meta?.requested?.type);
    }, [item]);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
        }
    };

    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const toggleShowFile = (event) => {
        event.stopPropagation();
        setShowFile(!showFile);
    };

    const handleSubmit = async () => {

        if (!file) {
            console.error("No file selected or file input is not available.");
            return;
        }

        const payload = new FormData();

        payload.append('file', file, file.name);
        payload.append('task_id', item.id);

        // Dynamic fields based on entity_name and type
        switch (formSection) {
            case 'partners':
                payload.append('entity_name', 'partners')
                if (type === 'license_doc') {
                    payload.append('type', 'license_doc')
                    payload.append('license_no', data?.license_no ?? '');
                    payload.append('license_issue_date', data?.license_issue_date ?? '');
                    payload.append('license_expiry_date', data?.license_expiry_date ?? '');
                    payload.append('license_issue_country', data?.license_issue_country ?? '');
                } else if (type === 'passport_doc') {
                    payload.append('type', 'passport_doc')
                    payload.append('passport_no', data?.passport_no ?? '');
                    payload.append('passport_expiry_date', data?.passport_expiry_date ?? '');
                } else if (type === 'visa_doc') {
                    payload.append('type', 'visa_doc')
                    payload.append('visa_no', data?.visa_no ?? '');
                    payload.append('visa_issued_by', data?.visa_issued_by ?? '');
                    payload.append('visa_expiry_date', data?.visa_expiry_date ?? '');
                } else if (type === 'eid_doc') {
                    payload.append('type', 'eid_doc')
                    payload.append('eid_no', data?.eid_no ?? '');
                    payload.append('eid_expiry_date', data?.eid_expiry_date ?? '');
                }
                break;

            case 'business':
                payload.append('entity_name', 'business')
                if(type === 'trade_license_doc') {
                    payload.append('type', 'trade_license_doc')
                    payload.append('trade_license_expiry_date', data?.trade_license_expiry_date ?? '');
                } else if(type === 'tenancy_doc') {
                    payload.append('type', 'tenancy_doc')
                    payload.append('tenancy_expiry_date', data?.tenancy_expiry_date ?? '');
                }
                break;

            case 'addresses':
                payload.append('entity_name', 'addresses')
                if(type === 'tenancy_doc') {
                    payload.append('type', 'tenancy_doc')
                    payload.append('tenancy_start_date', data?.tenancy_start_date ?? '');
                    payload.append('tenancy_end_date', data?.tenancy_end_date ?? '');
                }
                break;
            default:
                console.error('Unknown form section:', formSection);
        }

        // Log the payload contents for debugging
        for (let [key, value] of payload.entries()) {
            console.log(`${key}: ${value}`);
        }

        await updateExpiredDocument({dispatch, data: payload }).then((res) => {
            setResponse(res);
            if (!res.error) {
                dispatch(setModal({ component: null, open: false }));
            }
        });

    }

    const UploadButton = styled(Button)({
        width: '400px',
        position: 'relative',
        display: 'flex!important',
        flexDirection: 'column',
        border: '1px dashed #aaa',
        borderRadius: '4px',
        height: '55px',
        '&:hover': {
            backgroundColor: '#EFEFEF',
        },
    });

    const renderFormSection = () => {
        switch (formSection) {
            case 'partners':
                return <Partners />;
            case 'business':
                return <Business />;
            case 'addresses':
                return <Addresses />;
            default:
                return null;
        }
    };

    const Partners = () => {
        return (
            <>
                {type === 'license_doc' && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="License No"
                                name="license_no"
                                inputProps={{
                                    autoComplete: 'none'
                                }}
                                defaultValue={data?.license_no}
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleChange}
                                helperText={response?.error?.data?.errors?.license_no}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.license_issue_date} label="License Issue Date" name="license_issue_date" past={true} data={data} state={setData} />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.license_expiry_date} label="License Expiry Date" name="license_expiry_date" past={false} data={data} state={setData} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectCountry error={response?.error?.data?.errors?.license_issue_country} label="License Issue Country" name="license_issue_country" data={data} state={setData} />
                        </Grid>
                    </>
                )}
                {type === 'passport_doc' && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Passport No"
                                name="passport_no"
                                inputProps={{
                                    autoComplete: 'none'
                                }}
                                defaultValue={data?.passport_no}
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.passport_expiry_date} label="Passport Expiry Date" name="passport_expiry_date" past={false} data={data} state={setData} />
                        </Grid>
                    </>
                )}
                {type === 'visa_doc' && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Visa No"
                                name="visa_no"
                                inputProps={{
                                    autoComplete: 'none'
                                }}
                                defaultValue={data?.visa_no}
                                InputLabelProps={{ shrink: true }}
                                onBlur={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={ "Visa Sponsor Name" }
                                name="visa_issued_by"
                                inputProps={{
                                    autoComplete: 'none'
                                }}
                                defaultValue={data?.visa_issued_by}
                                inputlabelprops={{ shrink: true }}
                                helperText={response?.error?.data?.errors?.visa_issued_by}
                                onBlur={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiDatepicker error={response?.error?.data?.errors?.visa_expiry_date} past={false} label={"Visa Expiry Date"} name="visa_expiry_date" data={data} state={setData} />
                        </Grid>
                    </>
                )}
                {type === 'eid_doc' && (
                    <>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Emirates ID Number"
                                inputProps={{
                                    autoComplete: 'none',
                                    readOnly: true
                                }}
                                defaultValue={item?.meta?.requested?.eid_no}
                                inputlabelprops={{ shrink: true }}
                                onBlur={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiDatepicker error={response?.error?.data?.errors?.eid_expiry_date} label={"Emirates ID Expiry Date"} past={false} name="eid_expiry_date" data={data} state={setData} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    {renderAttachment()}
                </Grid>
            </>
        );
    };

    const Business = () => {
        return (
            <>
                {type === 'trade_license_doc' && (
                    <>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.trade_license_expiry_date} label={"Trade License Expiry Date"} name="trade_license_expiry_date" past={false} data={data} state={setData} />
                        </Grid>
                    </>
                )}
                {type === 'tenancy_doc' && (
                    <>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.tenancy_expiry_date} label={"Tenancy Expiry Date"} name="tenancy_expiry_date" past={false} data={data} state={setData} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    {renderAttachment()}
                </Grid>
            </>
        );
    }

    const Addresses = () => {
        return (
            <>
                {type === 'tenancy_doc' && (
                    <>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.tenancy_start_date} label={"Tenancy Start Date"} name="tenancy_start_date" past={true} data={data} state={setData} />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiDatepicker error={response?.error?.data?.errors?.tenancy_end_date} label={"Tenancy End Date"} name="tenancy_end_date" past={false} data={data} state={setData} />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    {renderAttachment()}
                </Grid>
            </>
        );
    }

    const renderAttachment = () => (
        <Stack spacing={1}>
            <FormControl error={Boolean(!file || response?.error?.data?.errors?.file)}>
                <input
                    ref={fileInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <UploadButton fullWidth sx={{width:'100%'}} size="large" component="span" onClick={openFilePicker}>
                    <CloudUploadIcon />
                    <Typography sx={{ fontSize: '11px', textTransform: 'Capitalize' }}>
                        Supported Files: {"PDF ONLY"}
                    </Typography>
                    {file && (
                        <Box onClick={toggleShowFile}>
                            <Typography sx={{ fontSize: '12px' }}>{file.name}</Typography>
                        </Box>
                    )}
                </UploadButton>
                <Typography sx={{ fontSize: '11px', color: 'red' }}>
                    {!file && "No file selected or file input is not available."}
                    {response?.error?.data?.errors?.file && ` ${response.error.data.errors.file}`}
                </Typography>
            </FormControl>
            {showFile && file && (
                <Box>
                    <FormControl sx={{ background: '#F7F7FF', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '65px', borderRadius: '4px', padding: '8px 15px', marginBottom: '8px', width: "400px" }}>
                        <Typography sx={{ fontSize: '13px' }}>{file.name}<span style={{ fontSize: '11px', display: 'block' }}>{file.size} bytes</span></Typography>
                        <Button onClick={handleRemoveFile} sx={{ padding: '0' }}>
                            <DeleteForeverIcon sx={{ fontSize: '24px', color: '#990000' }} />
                        </Button>
                    </FormControl>
                </Box>
            )}
        </Stack>
    );

    // Filter out business_id and create list items for the remaining keys

    const detailsList = () => {
        return Object.entries(submitted)
            .filter(([key]) => !['business_id', 'entity_id', 'document_id', 'doc_expiry', 'type', 'entity_name', 'owner_id'].includes(key))
            .map(([key, value]) => {
                const displayKey = key === 'doc_name' ? 'Attached Document' : key.replace(/_/g, ' ');
                const secondaryValue = key === 'doc_name' ? (
                    <Typography component={Link} to={process.env.REACT_APP_BASE_URL + 'document/' + submitted?.document_id} target="_blank" rel="noopener">
                        {value}
                    </Typography>
                ) : value;

                return (
                    <ListItem key={key}>
                        <ListItemText primary={displayKey} secondary={secondaryValue} />
                    </ListItem>
                );
            });
    };

    const RemarksListItem = () => {

        return item?.remarks && (
            <ListItem key={"ABC"}>
                <ListItemText primary={"Remarks"} secondary={item?.remarks} />
            </ListItem>
        )
    }

    const SubmittedInfoBox = styled(Box)(({ theme }) => ({
        padding: theme.spacing(0),

        '& .MuiDivider-root': {
            margin: theme.spacing(2, 0),
        },
        '& .MuiTypography-h6': {
            fontSize: '14px',
            fontWeight:'bold',
        },
        '& .MuiList-root': {
            padding: 0,
            '& .MuiListItem-root': {
                padding: theme.spacing(0, 0, 1, 0),
                borderBottom: `1px solid ${theme.palette.divider}`,
                '& .MuiListItemText-primary': {
                    textTransform: 'capitalize',
                    color: theme.palette.text.dark,
                },
                '& .MuiListItemText-secondary': {
                    color: theme.palette.text.dark,
                    marginBottom:'0!important',
                }
            }
        },
    }));

    const dialogTitle = () => {

        if(['eid_doc', 'passport_doc', 'visa_doc'].includes(type)) {
            return `Upload ${item.title} for ${item?.meta?.requested?.person_name ?? 'Unknown Person'}`;
        }

        return `Upload ${item.title}`;
    }

    return (
        <>
            <DialogTitle>{dialogTitle()}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>

                    {renderFormSection()}

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    { Object.keys(submitted).length > 0 && (
                    <Grid item xs={12}>
                        <SubmittedInfoBox>
                        <Typography variant={"h6"}>Previously Submitted:</Typography>
                        <List>
                            {detailsList()}
                            {RemarksListItem()}
                        </List>
                        </SubmittedInfoBox>
                    </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Close</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </>
    );
}
