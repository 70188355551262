import React from 'react'
import { Routes as Switch, Route } from 'react-router-dom'
import PrivateRoutes from './PrivateRoutes'

import { Dashboard } from "../Modules/Administration/Dashboard";
import { Profile } from "../Modules/Administration/Profile";
import { Settings } from "../Modules/Administration/Settings";
import { Users } from "../Modules/Administration/Users";
import { Monitor } from "../Modules/Administration/Monitor";
import { Notifications } from "../Modules/Common/Notifications";
import { Permissions } from "../Modules/Administration/Permissions";
import { Roles } from "../Modules/Administration/Roles";


import { Business, BusinessView, BusinessByStatus } from "../Modules/Corporate/Business";
import { Invoices, InvoiceView } from "../Modules/Corporate/Invoices";
import { Customers, CustomerView } from "../Modules/Corporate/Customers";
import { Accounts, CurrentOutstanding, Overdues } from "../Modules/Corporate/Accounts";

import { Loan, LoanView } from "../Modules/Corporate/Loan";
import { ServiceInvoiceUpload } from "../Modules/Corporate/Services/Components/ServiceInvoiceUpload";
import PublicRoutes from "./PublicRoutes";
import {
    EmailVerification,
    ForgotPassword,
    ResetPassword,
    Login,
    Logout,
    Register,
    RegisterVerify
} from "../Modules/Common/Auth";
import {Service, ServiceView} from "../Modules/Corporate/Services";
import {Listings, ListingView} from "../Modules/Investor/Listings";
import {Wallets, WalletView} from "../Modules/Investor/Wallets";
import {Requests, RequestView} from "../Modules/Investor/Requests";
import {Transactions, TransactionView} from "../Modules/Investor/Transactions";
import {Dashboard as DashboardClient} from "../Modules/Corporate/Dashboard/";
import {CurrentFunding} from "../Modules/Corporate/Loan/Components/CurrentFunding";
import {TotalFunded} from "../Modules/Corporate/Loan/Components/TotalFunded";
import {
    Main as FundStatement,
    FundedStatement,
    EarningStatement,
    OutstandingStatement,
    OverdueStatement
} from "../Modules/Corporate/FundStatement";
import {DefaultContracts} from "../Modules/Corporate/Loan/Components/DefaultContracts";

export const AdministrationRoutes = () => {

    return (
        <Switch>
            <Route element={<PublicRoutes layout={'Auth'} />} >
                <Route exact path='/register' element={<Register/>}/>
                <Route exact path='/forgot-password' element={<ForgotPassword/>}/>
                <Route exact path='/reset-password/:token' element={<ResetPassword/>}/>
                <Route exact path='/verify/:token' element={<RegisterVerify/>}/>
                <Route exact path='/login' element={<Login/>}/>
                <Route exact path='/logout' element={<Logout/>}/>
                <Route exact path='/email-verification/:token' element={<EmailVerification/>}/>
            </Route>

            <Route element={<PrivateRoutes layout={'Admin'} allowedRoles={['ADMIN']}/>} >
                <Route exact path='/notifications' element={<Notifications />}/>
                <Route exact path='/settings' element={<Settings />}/>
                <Route exact path='/permissions' element={<Permissions />}/>
                <Route exact path='/roles' element={<Roles />}/>
                <Route exact path='/users' element={<Users />}/>
                <Route exact path='/monitor' element={<Monitor />}/>
            </Route>

            <Route element={<PrivateRoutes layout={'Admin'} allowedRoles={['ADMIN','USER']} />} >
                <Route exact path='/' element={<Dashboard />}/>
                <Route exact path='/dashboard/:business_id' element={<DashboardClient />}/>
                <Route exact path='/dashboard' element={<Dashboard />}/>
                <Route exact path='/profile' element={<Profile />}/>
                <Route exact path='/business' element={<Business />}/>
                <Route exact path='/business/by/:status' element={<BusinessByStatus />}/>
                <Route exact path='/business/:business_id' element={<BusinessView />}/>
                <Route exact path='/my-business/' element={<BusinessView />}/>

                <Route exact path='/invoices' element={<Invoices />}/>
                <Route exact path='/invoices/:invoice_id' element={<InvoiceView />}/>
                <Route exact path='/customers' element={<Customers />}/>
                <Route exact path='/customers/:customer_id' element={<CustomerView />}/>

                <Route exact path='/accounts' element={<Accounts />}/>
                <Route exact path='/current-outstanding' element={<CurrentOutstanding />}/>
                <Route exact path='/overdue' element={<Overdues />}/>

                <Route exact path='/business-finance' element={<Loan />}/>
                <Route exact path='/business-finance/total-funded' element={<TotalFunded />}/>
                <Route exact path='/business-finance/current-funding' element={<CurrentFunding />}/>
                <Route exact path='/business-finance/:loan_id' element={<LoanView />}/>

                <Route exact path={'/funding-requests'} element={<Service />}/>
                <Route exact path={'/funding-requests/:service_id'} element={<ServiceView />}/>
                <Route exact path={'/services/invoice-upload'} element={<ServiceInvoiceUpload />}/>
                <Route exact path={'/services/:service_id'} element={<ServiceView />}/>

                <Route exact path={'/wallets'} element={<Wallets />}/>
                <Route exact path={'/wallets/:id'} element={<WalletView />}/>

                <Route exact path={'/listings'} element={<Listings />}/>
                <Route exact path={'/listings/:id'} element={<ListingView />}/>

                <Route exact path={'/requests'} element={<Requests />}/>
                <Route exact path={'/requests/:id'} element={<RequestView />}/>

                <Route exact path={'/transactions'} element={<Transactions />}/>
                <Route exact path={'/transactions/:id'} element={<TransactionView />}/>

                <Route exact path='/fund-statement' element={<FundStatement />}/>
                <Route exact path='/fund-statement/funded' element={<FundedStatement />}/>
                <Route exact path='/fund-statement/earnings' element={<EarningStatement />}/>
                <Route exact path='/fund-statement/outstanding' element={<OutstandingStatement />}/>
                <Route exact path='/fund-statement/overdue' element={<OverdueStatement />}/>
                <Route exact path='/fund-statement/default' element={<DefaultContracts />}/>
            </Route>
        </Switch>
    )
}