import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setModal} from "../../../../Common/App";
import {FinancialForm} from "../Forms/FinancialForm";
import {Card, CardContent, CardHeader} from "@mui/material";
import {
    addFinancial,
    clearErrors,
    proceed,
    setSelectedFinancial,
    FinancialDeleteModal
} from "../../index";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {useEffect} from "react";
import {currencyFormatter} from "../../../../../Setup/Config";
import {Link} from "react-router-dom";

export const FinancialCard = (props) => {

    const business = useSelector((store) => store.business);
    const documents = useSelector((store) => store.business.selected?.documents);
    let items = Object.keys(business.financials).length > 0 ? business.financials.list.filter(i => i.is_draft !== 1) : [];

    items = items.sort((a, b) => {
        // Assuming financial_year is in the format "YYYY-MM-DD"
        const yearA = new Date(a.financial_year).getTime();
        const yearB = new Date(b.financial_year).getTime();

        // Compare the timestamps
        return yearB - yearA;
    });

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const documentLink = (document_type, value, id) => {

        const type = {
            'financial_year': 'financial_report_doc',
        };

        if (documents && documents.length > 0) {
            let doc = documents.filter((doc) => doc.type === type[document_type] && doc.entity_id === id);
            if (doc.length === 1) {
                return (
                    <Link style={{ textDecoration: 'none' }} to={process.env.REACT_APP_BASE_URL + 'document/' + doc[0].document_id} target="_blank" rel="noopener noreferrer">
                        {moment(value).format("DD/MM/YYYY")}
                    </Link>
                );
            }
        }

        return moment(value).format("DD/MM/YYYY");
    };

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addFinancial({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedFinancial(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<FinancialForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<FinancialDeleteModal />, open:true}));
        dispatch(setSelectedFinancial(e.currentTarget.dataset.id));
    }

    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status?.name) && auth.user.role_type === 'CLIENT') {
                return ['add-business-financials'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-financial') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
            return false;
        },[business.status?.name, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'auditor_name', headerAlign:'center', headerName:'Auditor Name', type: 'string', flex:1, minWidth:100},
            { field: 'financial_year', align:'center', headerAlign:'center', headerName:'Financial Year', type: 'string', flex:1, minWidth:120,
                renderCell: (params) => documentLink('financial_year', params.row.financial_year, params.row.id),
            },
            { field: 'total_revenue', headerAlign:'center', headerName:'Total Revenue', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'gross_profit', headerAlign:'center', headerName:'Gross Profit', type: 'number', flex:1, minWidth:110, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'net_profit', headerAlign:'center', headerName:'Net Profit', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'equity', headerAlign:'center', headerName:'Equity', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'loan_liability', headerAlign:'center', headerName:'Loan Liability', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'trade_payable', headerAlign:'center', headerName:'Trade Payable', type: 'number', flex:1, minWidth:120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'other_payable', headerAlign:'center', headerName:'Other Payable', type: 'number', flex:1, minWidth:110, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'trade_receivable', headerAlign:'center', headerName:'Trade Receivable', type: 'number', flex:1, minWidth:140, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'other_receivable', headerAlign:'center', headerName:'Other Receivable', type: 'number', flex:1, minWidth:130, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'stock', headerAlign:'center', headerName:'Stock', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'fixed_assets', headerAlign:'center', headerName:'Fixed Assets', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'bank_balance', headerAlign:'center', headerName:'Bank Balance', type: 'number', flex:1, minWidth:110, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'cash_balance', headerAlign:'center', headerName:'Cash Balance', type: 'number', flex:1, minWidth:110, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'staff_salary', headerAlign:'center', headerName:'Staff Salary', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Financial Numbers'}
                    action={ editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button> }
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items || []}
                            sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { left: ['auditor_name','financial_year'], right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}
