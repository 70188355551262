import * as React from 'react';
import {Card, CardContent, CardHeader, Button, Grid, Stack} from "@mui/material";

import Typography from "@mui/material/Typography";
import {CompanyDetailForm} from "../Forms/CompanyDetailForm";
import {useDispatch, useSelector} from "react-redux";
import {useRef, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import {countries, checkExpiryAndDisplayDate} from "../../../../../Setup/Config/index";
import {CardList, LinkMailto, LinkTel} from "../../../../../Components";
import {Link} from "react-router-dom";
import {ShowAttachmentsModal} from "../Modals/ShowAttachmentsModal";
import {setModal} from "../../../../Common/App";

export const CompanyDetailCard = (props) => {

    const dispatch = useDispatch();
    const childRef = useRef();
    const business = useSelector((store) => store.business.selected);
    const documents = useSelector((store) => store.business.selected?.documents);
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [action, setAction] = useState();
    const [response, setResponse] = useState(false);

    const handleActionBtn = async (e) => {
        setAction(e.target.dataset.action)
    }

    const handleSaveBtn = async (e) => {
        if(e.target.dataset.action === 'Save'){
            let r = {};
            await childRef.current.handleSubmit().then((res) => {
                r = res
                setResponse(res)
                if(res['success']){
                    setAction(e.target.dataset.action)
                }
            });
        }
    }

    const documentLink = (document_type, value) => {

        const type = {
            'name': 'business_profile',
            'aecb_score': 'aecb_report_doc',
            'last_moa_date': 'moa_doc',
            'trade_license': 'trade_license_doc',
            'tenancy_expiry_date': 'tenancy_doc',
        };

        if (documents && documents.length > 0) {
            let doc = documents.filter((doc) => doc.type === type[document_type]);
            if (doc.length === 1) {
                return (
                    <Link style={{ textDecoration: 'none' }} to={process.env.REACT_APP_BASE_URL + 'document/' + doc[0].document_id} target="_blank" rel="noopener noreferrer">
                        {value}
                    </Link>
                );
            } else if (doc.length > 1) {

                return (
                    <Typography
                        sx={{ cursor:'pointer', textDecoration:'underline', color:'#0d6efd' }}
                        onClick={() => dispatch(setModal({component: <ShowAttachmentsModal doc={doc} />, open:true}))}
                    >
                        {value}
                    </Typography>
                );
            }
        }

        return value;
    };

    const editable = React.useCallback(
        () => {
            if(['DRAFT','RETURNED'].includes(business.status?.name) && auth.user.role_type === 'CLIENT'){
                return permissions.includes('edit-business-details');
            }else if(permissions.includes('su-edit-business-details') && auth.user.role_type !== 'CLIENT'){
                return true;
            }
            return false;
        },
        [business.status?.name, auth.user.role_type, permissions]
    );

    return (
        <>
            { props.business &&
                <Card>
                    <CardHeader
                        titleTypographyProps={{variant:'h6' }}
                        title={'Company Details'}
                        action={editable() && <Button variant="outlined" size={"small"} data-id={props.business.business_id} data-action={action === "Edit" ? "Save" : "Edit"} onClick={action === 'Edit' ? handleSaveBtn : handleActionBtn}>{action === "Edit" ? "Save" : "Edit"}</Button>}
                    />
                    <CardContent sx={{borderBottom:'1px solid #ddd'}}>
                        { action !== 'Edit'?
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={4}>
                                    <Typography mb={1}>Company Registration Details</Typography>
                                    <Stack spacing={2}>
                                        <CardList>
                                            <ListItem>
                                                <ListItemText primary={"Company Name"} secondary={documentLink("name", business.name || "-")}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"Trade License Number"} secondary={documentLink("trade_license", business.trade_license_number || "-")}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"Trade License Authority"} secondary={business.trade_license_authority || "-"}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"Trade License Issue Date"} secondary={business.trade_license_issue_date ? moment(business.trade_license_issue_date).format("DD/MM/YYYY") : "-"}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"Trade License Expiry Date"} secondary={ checkExpiryAndDisplayDate(business.trade_license_expiry_date) } />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"AECB Score"} secondary={documentLink("aecb_score", business.aecb_score)} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"AECB Date"} secondary={business.aecb_date ? moment(business.aecb_date).format("DD/MM/YYYY") : "-"}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"Last MOA Date"} secondary={documentLink("last_moa_date", business.last_moa_date ? moment(business.last_moa_date).format("DD/MM/YYYY") : "-")}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"Tenancy Expiry Date"} secondary={ documentLink("tenancy_expiry_date", checkExpiryAndDisplayDate(business.tenancy_expiry_date)) }/>
                                            </ListItem>
                                        </CardList>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Typography mb={1}>Contact & Communication Details</Typography>
                                    <Stack spacing={2}>
                                        <CardList>
                                            <ListItem>
                                                <ListItemText primary={"Contact Person"} secondary={business.contact_person || "-"}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={"Contact Mobile"}
                                                    secondary={business.mobile ? <LinkTel call={business.mobile} label={business.mobile} /> : "-"}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={"Office Phone"}
                                                    secondary={business.phone ? <LinkTel call={business.phone} label={business.phone} /> : "-"}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={"Email"}
                                                    secondary={business.email ? <LinkMailto mailto={business.email} label={business.email} /> : "-"}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary="Website"
                                                    secondary={business.website ? <a style={{ textDecoration: 'none' }} href={business.website} target="_blank" rel="noopener noreferrer">{business.website}</a> : "-"}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary="LinkedIn"
                                                    secondary={business.linkedin ? <a style={{ textDecoration: 'none' }} href={business.linkedin} target="_blank" rel="noopener noreferrer">{business.linkedin}</a> : "-"}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary="Twitter/Instagram"
                                                    secondary={business.twitter ? <a style={{ textDecoration: 'none' }} href={business.twitter} target="_blank" rel="noopener noreferrer">{business.twitter}</a> : "-"}
                                                />
                                            </ListItem>
                                        </CardList>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Typography mb={1}>Company Address</Typography>
                                    <Stack spacing={2}>
                                        <CardList>
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={"Unit No / Floor Number"} secondary={business.ca_address_1 || "-"}/>*/}
                                            {/*</ListItem>*/}
                                            <ListItem>
                                                <ListItemText primary={"Office Address"} secondary={business.ca_address_2 || "-"}/>
                                            </ListItem>
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={"Landmark"} secondary={business.ca_address_3 || "-"}/>*/}
                                            {/*</ListItem>*/}
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={"Area"} secondary={business.ca_address_4 || "-"}/>*/}
                                            {/*</ListItem>*/}
                                            <ListItem>
                                                <ListItemText primary={"PO Box"} secondary={business.ca_po_box || "-"}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={"City/Emirate"} secondary={business.ca_city || "-"}/>
                                            </ListItem>
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={"State"} secondary={business.ca_state || "-"}/>*/}
                                            {/*</ListItem>*/}
                                            <ListItem>
                                                <ListItemText primary={"Country"} secondary={(countries.filter((obj) => obj.iso3 === business.ca_country))[0]?.name || "-"}/>
                                            </ListItem>
                                        </CardList>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <CardList >
                                        <ListItem>
                                            <ListItemText primary={"Details"} secondary={business.business_details || "-"}/>
                                        </ListItem>
                                        { (business.status?.name === 'RETURNED' && auth.user.role_type !== 'CLIENT') &&
                                            <ListItem>
                                                <ListItemText primary={"Return Reason"} secondary={business.reason || "-"}/>
                                            </ListItem>}
                                    </CardList>
                                </Grid>
                            </Grid>
                            :
                            <CompanyDetailForm ref={childRef} setResponse={setResponse} />
                        }
                    </CardContent>
                </Card>
            }
        </>
    );
}
