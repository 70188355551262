import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Chip, FormControl, FormHelperText, InputLabel, Select, Stack, TextField} from "@mui/material";
import {fetchCustomers, setSelectedBusiness, setSelectedCustomer} from "../../../Business";
import {addService} from "../../Redux/Action";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {fetchCustomer} from "../../../Business";
import { setModal } from "../../../../Common/App";

export const NewServiceRequestModal = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {business_id} = useParams();
    const business = useSelector((store) => store.business.list);
    const customers = useSelector((store) => store.business.customers?.list);
    const services = useSelector((store) => store.app.services);
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(true);
    const [data, setData] = useState({});
    const [showCustomerOption, setShowCustomerOption] = useState(false);
    const [showInvoiceOption, setShowInvoiceOption] = useState(false);

    useEffect(() => {
        setResponse(false);
        setData({});
        setShowCustomerOption(false);
        setShowInvoiceOption(false);
    },[])

    const handleChange = (e) => {

        setDisableFields(e.target.name === "business_id" && e.target.value === "")

        if(["service_type"].includes(e.target.name)){
            setShowCustomerOption(false);
        }

        if(["business_id"].includes(e.target.name)){
            dispatch(setSelectedBusiness(e.target.value));
        }

        if(e.target.name === "service_type"){
            const selectedService = services.find(o => o.id === e.target.value);
            if(selectedService.name === "Invoice Discounting") {
                loadCustomers();
            }
        }
        if(e.target.name === "customer_id"){
            if(e.target.value === "add-new"){
                navigate('/customers')
            }else{
                setShowInvoiceOption(true);
            }
        }

        setDisabled(false);
        setData(data => ({
            ...data,
            business_id: business_id ?? business[0].business_id,
            [e.target.name] :e.target.value,
        }));
    }

    const loadCustomers = async () => {
        let payload = {
            business_id : data['business_id'],
            for_invoice_discounting : 1,
        }
        await fetchCustomers({dispatch, data:payload}).then((res) => {
            setShowCustomerOption(true);
        })
    }

    const uploadInvoice = async () => {
        dispatch(setSelectedCustomer(data['customer_id']));
        dispatch(setSelectedBusiness(data['business_id']));
        await fetchCustomer({dispatch, id:data['customer_id']}).then((res) => {
            setResponse(res);
            if (res['success']) {
                dispatch(setModal({component: null, open: false}));
                navigate('/services/invoice-upload/', {state: {data}, replace: true})
            }
        });
    }

    const handleSubmit = async () => {

        setDisabled(true);

        await addService({dispatch, data}).then((res) => {
            setResponse(res);
            if(res['success']){
                setData({});
                setDisabled(false);
                dispatch(setModal({component:null, open:false}))
            }
        });
    };

    return (
        <>
                <DialogTitle>New Request</DialogTitle>
                <DialogContent sx={{justifyContent:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
                    <Stack spacing={2} sx={{mt:2}}>
                        <FormControl sx={{ width:'100%' }}>
                            <InputLabel id="card-type">Service Type</InputLabel>
                            <Select
                                name="service_type"
                                MenuProps={{
                                    style: {zIndex: 35001}
                                }}
                                fullWidth
                                label="Service Type"
                                value={data?.service_type ?? ""}
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem key="" value="">Select Service</MenuItem>
                                { services.map((i) => <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>) }
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.service_type}</FormHelperText>
                        </FormControl>
                        {showCustomerOption &&
                            <Stack direction={"column"} spacing={2}>
                                <FormControl sx={{ width:'100%' }}>
                                    <InputLabel id="businesses">Select Approved Customer</InputLabel>
                                    <Select
                                        name="customer_id"
                                        MenuProps={{
                                            style: {zIndex: 35001}
                                        }}
                                        label="Select Approved Customer"
                                        value={data?.customer_id ?? ""}
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <MenuItem value="">Select Customer</MenuItem>
                                        {
                                            (customers.filter(o => o.status?.name !== 'DRAFT')).map((i) => <MenuItem key={i.customer_id} value={i.customer_id} disabled={i.status?.name !== 'APPROVED'} sx={{justifyContent:'space-between'}}>{i.company_name} <Chip color={"secondary"} label={i.status?.name} /></MenuItem>)
                                        }
                                        <MenuItem value="add-new">Add New Customer</MenuItem>
                                    </Select>
                                    <FormHelperText>{response?.error?.data?.errors?.customer_id}</FormHelperText>
                                </FormControl>

                            </Stack>
                        }

                        {!showCustomerOption &&
                            <>
                                <TextField
                                    disabled={disableFields}
                                    fullWidth
                                    label="Amount Required"
                                    name="amount"
                                    defaultValue={data?.amount || ""}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={response?.error?.data?.errors?.amount}
                                    onBlur={handleChange} />
                                <TextField
                                    disabled={disableFields}
                                    fullWidth
                                    multiline
                                    defaultValue={data?.purpose || ""}
                                    rows={2}
                                    label="Purpose"
                                    name="purpose"
                                    inputlabelprops={{ shrink: true }}
                                    helperText={response?.error?.data?.errors?.purpose}
                                    onBlur={handleChange} />
                            </>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions sx={{p:3, pt:0}}>

                    <Stack spacing={2} direction={"row"}>
                        <Button variant={"outlined"} onClick={() => dispatch(setModal({ component:null, open: false}))}>Cancel</Button>
                        {showInvoiceOption ?
                            <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ uploadInvoice }>Upload Invoice</Button>
                        :
                            <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Request</Button>
                        }
                    </Stack>
                </DialogActions>
            <Toastr response={response} />
        </>
    );
}
