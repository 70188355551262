import * as React from 'react';
import { Grid, Paper, Stack, Tab, Tabs, Button } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {
    PartnerCard,
    FinancialCard,
    BankBorrowingCard,
    BankDetailCard,
    ChequeCard,
    CustomerSupplierCard,
    OtherBorrowingCard,
    VatReturnCard,
    WpsDetailCard,
    CompanyDetailCard,
    CreditCard,
    CustomerCard,
    CompanyLocationCard,
    fetchAddresses,
    fetchBankBorrowings,
    fetchBankDetails,
    fetchCheques,
    fetchCreditCards,
    fetchCustomers,
    fetchCustomerSuppliers,
    fetchFinancials,
    fetchPartner,
    fetchOtherBorrowings,
    fetchVatReturns,
    fetchWpsDetails,
    ActionButtons,
    BusinessConfigPanel,
    BusinessPropertiesPanel,
} from "../index";
import DocumentsList from "../../../Common/Documents/Components/DocumentsList";
import {Breadcrumbs} from "../../../../Components";
import ReportsList from "../../../Common/Documents/Components/ReportsList";
import {CommentCard} from "../../Comments/Components/Cards/CommentCard";
import {setModal} from "../../../Common/App";
import {BusinessAssignmentModal} from "./Modals/BusinessAssignmentModal";
import {SummaryCard} from "../../Summary/Components/Cards/SummaryCard";
import {RelatedBusinessPanel} from "./Panels/RelatedBusinessPanel";

export default function TabView() {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [tab, setTab] = useState("business");
    const [entityId, setEntityId] = useState(null);
    const [response, setResponse] = useState({});

    const handleChange = async (e, newValue) => {

        setTab(newValue);
        const business_id = business.selected.business_id;

        switch (newValue) {
            case 'partners':
                await fetchPartner({dispatch, business_id});
                break;
            case 'financials':
                await fetchFinancials({dispatch, business_id});
                break;
            case 'bankDetails':
                await fetchBankDetails({dispatch, business_id});
                break;
            case 'bankBorrowings':
                await fetchBankBorrowings({dispatch, business_id});
                break;
            case 'creditCards':
                await fetchCreditCards({dispatch, business_id});
                break;
            case 'otherBorrowings':
                await fetchOtherBorrowings({dispatch, business_id});
                break;
            case 'cheques':
                await fetchCheques({dispatch, business_id});
                break;
            case 'vatReturns':
                await fetchVatReturns({dispatch, business_id});
                break;
            case 'wpsDetails':
                await fetchWpsDetails({dispatch, business_id});
                break;
            case 'addresses':
                await fetchAddresses({dispatch, business_id});
                break;
            case 'customersSuppliers':
                await fetchCustomerSuppliers({dispatch, data:{business_id, for_invoice_discounting:0}});
                break;
            case 'customers':
                await fetchCustomers({dispatch, data:{business_id, for_invoice_discounting:1}});
                break;
            default:
                console.log("-");
        }
    }

    const handleAssignBtn = () => {
        dispatch(setModal({component:<BusinessAssignmentModal />, open:true}));
    }


    return (
        <>
            { business !== undefined  &&
                <Grid container spacing={2} elevated={3}>
                    <Grid item xs={12}>
                        <Stack justifyContent={'space-between'} direction={'row'}>
                            <Breadcrumbs items={'DASHBOARD/BUSINESS'} />
                            <Stack spacing={2} direction={"row"}>
                                { ((permissions).includes('show-business-summary-panel') && !['DRAFT','APPROVED'].includes(business.selected?.status?.name)) &&
                                    <Button variant={"contained"} color={"secondary"} onClick={ handleAssignBtn }>Assign</Button>
                                }
                                <ActionButtons />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Stack spacing={2}>
                            <Paper>
                                <Tabs
                                    value={tab}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="Company Details" value="business"/>
                                    <Tab label="Partners" value="partners"/>
                                    <Tab label="Financial Numbers" value="financials"/>
                                    <Tab label="Customers & Suppliers" value="customersSuppliers"/>
                                    <Tab label="Bank Details" value="bankDetails"/>
                                    <Tab label="Bank Borrowings" value="bankBorrowings"/>
                                    <Tab label="Credit Cards" value="creditCards"/>
                                    { auth.user.role_type !== 'CLIENT' && <Tab label="Other Borrowings" value="otherBorrowings"/> }
                                    <Tab label="Returned Cheques" value="cheques"/>
                                    <Tab label="VAT Returns" value="vatReturns"/>
                                    <Tab label="WPS Details" value="wpsDetails"/>
                                    { auth.user.role_type !== 'CLIENT' && <Tab label="Company Locations" value="addresses"/> }
                                    <Tab label="Customers" value="customers"/>
                                </Tabs>
                            </Paper>
                            {
                                (() => {
                                    switch (tab) {
                                        case 'business':
                                            return <CompanyDetailCard business={business}/>
                                        case 'partners':
                                            return <PartnerCard setEntityId={setEntityId} />
                                        case 'financials':
                                            return <FinancialCard setEntityId={setEntityId}/>
                                        case 'bankDetails':
                                            return <BankDetailCard setEntityId={setEntityId}/>
                                        case 'bankBorrowings':
                                            return <BankBorrowingCard setEntityId={setEntityId}/>
                                        case 'creditCards':
                                            return <CreditCard setEntityId={setEntityId}/>
                                        case 'otherBorrowings':
                                            return <OtherBorrowingCard setEntityId={setEntityId}/>
                                        case 'cheques':
                                            return <ChequeCard setEntityId={setEntityId}/>
                                        case 'vatReturns':
                                            return <VatReturnCard setEntityId={setEntityId}/>
                                        case 'wpsDetails':
                                            return <WpsDetailCard setEntityId={setEntityId}/>
                                        case 'addresses':
                                            return <CompanyLocationCard setEntityId={setEntityId}/>
                                        case 'customersSuppliers':
                                            return <CustomerSupplierCard setEntityId={setEntityId}/>
                                        case 'customers':
                                            return <CustomerCard setEntityId={setEntityId}/>
                                        default:
                                    }
                                })()
                            }

                            { (permissions).includes('show-business-summary-panel') && business.selected?.status?.name !== 'DRAFT' && auth.user.role_type !== 'CLIENT' && tab === 'business' && <SummaryCard /> }
                            { (permissions).includes('show-business-comment-panel') && business.selected?.status?.name !== 'DRAFT' && auth.user.role_type !== 'CLIENT' && <CommentCard section={tab} /> }

                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={2}>
                            { (permissions).includes('show-business-documents') && <DocumentsList setResponse={setResponse} entityId={tab === 'business' ? business.selected.business_id : entityId } tab={tab}/> }
                            { (permissions).includes('show-related-businesses') && <RelatedBusinessPanel /> }
                            { (permissions).includes('show-business-reports') && <ReportsList /> }
                            { (permissions).includes('show-business-properties') && <BusinessPropertiesPanel /> }
                            { (permissions).includes('show-business-configurations') && <BusinessConfigPanel /> }
                        </Stack>
                    </Grid>
                </Grid>
            }
        </>
    );
}
