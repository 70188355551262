import { createTheme } from '@mui/material/styles';

// Core theme properties
const coreTheme = {
    palette: {
        background: {
            default: "#eaf2fc",
            paper: "#ffffff",
        },
        primary: {
            main: "#01a3ff",
            light: "#1eaeff",
            dark: "#0b86cc",
            contrastText: "#fff",
        },
        secondary: {
            main: "#7a7c98",
            light: "#a0a3b9",
            dark: "#565870",
            contrastText: "#fff",
        },
        info: {
            main: "#3da7e3",
            light: "#66c7f0",
            dark: "#2471a0",
            contrastText: "#fff",
        },
        success: {
            main: "#9c0",
            light: "#c6d969",
            dark: "#667f07",
            contrastText: "#fff",
        },
        error: {
            main: "#f00",
            light: "#ff6659",
            dark: "#aa0000",
            contrastText: "#fff",
        },
        warning: {
            main: "#ff9800",
            light: "#ffbf33",
            dark: "#cc7e00",
            contrastText: "#fff",
        },
        action:{
            "active": "rgba(0, 0, 0, 0.54)",
            "hover": "rgba(0, 0, 0, 0.04)",
            "hoverOpacity": 0.04,
            "selected": "rgba(0, 0, 0, 0.08)",
            "selectedOpacity": 0.08,
            "disabled": "rgba(0, 0, 0, 0.26)",
            "disabledBackground": "rgba(0, 0, 0, 0.12)",
            "disabledOpacity": 0.38,
            "focus": "rgba(0, 0, 0, 0.12)",
            "focusOpacity": 0.12,
            "activatedOpacity": 0.12
        }
    },
    text: {
        light: '#ffffff',
        dark: '#363537',
    },
    typography: {
        fontFamily: 'Raleway, Arial',
        //fontFamily: 'Poppins, Arial',
        //fontFamily: 'Roboto, Arial',
        //fontFamily: 'Open Sans, Arial',
        fontWeight: 100,
        fontSize: 14,
        h1: {
            fontSize: '1.8rem',
            fontFamily: 'Open Sans, Arial',
        },
        h2: {
            fontSize: '1.5rem',
            fontFamily: 'Open Sans, Arial',
        },
        h3: {
            fontSize: '1.3rem',
            lineHeight:'1.7',
            fontFamily: 'Open Sans, Arial',
        },
        h4: {
            fontSize: '1.2rem',
            fontFamily: 'Open Sans, Arial',
        },
        h5: {
            fontSize: '1.1rem',
            fontFamily: 'Open Sans, Arial',
        },
        h6: {
            fontSize: '1rem',
            fontFamily: 'Open Sans, Arial',
        },
    },
    container:{
        bgColor: '#ffffff',
        Shadow: '0 0 5px 0 rgba(0,0,0,.1)',
    },
    body: '#efeeee',
    appBar: {
        backgroundColor: '#01a3ff',
        color: '#2f3239',
        borderBottom: '1px solid #ddd',
        zIndex: 1201,
    },
    appBarPublic: {
        backgroundColor: '#fbfbfc',
        color: '#2f3239',
        borderBottom: '1px solid #ddd',
        zIndex: 1201,
    },
    verticalNavbar: {
        bgColorLight: '#2f323a',
        bgColorDark: '#25282e',
        color: '#5c9df5',
    },
    authBackground : 'radial-gradient(at 50% 30%, #D7E1EC 10%, #FFFFFF 100%)',

};

const darkTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        type: 'dark',
        background: {
            default: "#121212",
            paper: "#1e1e1e",
        },
    },
    text: {
        primary: '#FAFAFA',
    }
};

const lightTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        type: 'light',
        background: {
            default: "#efeeee",
            paper: "#ffffff",
        },
    },
    text: {
        color: '#363537',
    },
    components: {
        MuiPickersPopper:{
            styleOverrides: {
                root: {
                    zIndex: '9999!important',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    backgroundColor: '#01a3ff',
                    color: '#ffffff',
                },
            },
        },
        MuiBreadcrumbs:{
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    },
                    "& .MuiCardActions-root":{
                        borderTop: '1px solid #eff2f5',
                        background: 'transparent',
                        '& a': {
                            textDecoration: 'none'
                        }
                    }
                },
            },
        },
        MuiButton:{
            variants: [
                {
                    props: { variant: 'accountButton' },
                    style: ({ theme }) => ({
                        border:'1px solid',
                        textTransform: 'none',
                        borderColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            borderColor: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                            '& .MuiTypography-root':{
                                color: theme.palette.primary.dark,
                            }
                        },
                        '& .MuiTypography-root':{
                            color: theme.palette.primary.contrastText,
                        }
                    }),
                },
            ],
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    '& p': {
                        marginBottom: '0!important',
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    },
                    '& .MuiCardContent-root':{
                        padding:'1rem',
                    }
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                root:{
                    '& .MuiPaper-root':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiList-root':{
                        padding: '0',
                        '& a':{
                            color: '#000',
                            '& li':{
                                padding: '10px 20px',
                                '&:hover':{
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        },
                        '& svg':{
                            marginRight: '10px',
                            fontSize:'1rem'
                        },
                    },
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-pinnedColumns':{
                        '& .MuiDataGrid-cell':{
                            backgroundColor: '#ffffff',
                            '& .MuiDataGrid-actionsCell':{
                                zIndex: 12,
                            }
                        }
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#ffffff',
                    }
                }
            }
        }
    },
};

const administrationLightTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        info: {
            main: "#2171a6",
            light: "#3f9ad58f",
            dark: "#0c4469",
            contrastText: "#fff",
        },
        primary: {
            main: "#3e4855",
            light: "#54606e8f",
            dark: "#28303a",
            contrastText: "#fff",
        },
        success: {
            main: "#639645",
            light: "#88bd668f",
            dark: "#365922",
            contrastText: "#fff",
        },
        error: {
            main: "#a90c0c",
            light: "#f0d7d78f",
            dark: "#aa0000",
            contrastText: "#fff",
        },
        warning: {
            main: "#ff9800",
            light: "#ffbf338f",
            dark: "#cc7e00",
            contrastText: "#fff",
        },
        secondary: {
            main: "#757781",
            light: "#cdcdce",
            dark: "#262834",
            contrastText: "#fff",
        },
        type: 'light',
        background: {
            default: "#efeeee",
            paper: "#ffffff",
        },
    },
    appBar: {
        backgroundColor: {
            production:'#3F4A56',
            development: '#09243d',
        },
        color: '#2f3239',
        borderBottom: '1px solid #ddd',
        zIndex: 1201,
    },
    auth:{
        loginBgColor: '#3F4A56',
        registerBgColor: '#3F4A56',
        forgotBgColor: '#3F4A56',
    },
    text: {
        link: '#08476c',
        light: '#ffffff',
        dark: '#363537',
        error: '#ff0000',
        warning: '#e56305',
        success: '#8eb417',
    },
    link: {
        active: '#08476c',
        hover: '#0a5a7c',
    },
    components: {
        MuiPickersPopper:{
            styleOverrides: {
                root: {
                    zIndex: '9999!important',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    backgroundColor: '#01a3ff',
                    color: '#ffffff',
                },
            },
        },
        MuiBreadcrumbs:{
            styleOverrides: {
                root: {
                    color: '#ffffff',
                },
            },
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    },
                    '& .MuiCardContent-root, & .MuiCardContent-root:last-child':{
                        paddingBottom: 0,
                    },
                    "& .MuiCardActions-root":{
                        borderTop: '1px solid #eff2f5',
                        background: 'transparent',
                        '& a': {
                            textDecoration: 'none'
                        }
                    }
                },
            },
        },
        MuiButton:{
            variants: [
                {
                    props: { variant: 'accountButton' },
                    style: ({ theme }) => ({
                        border:'1px solid',
                        textTransform: 'none',
                        borderColor: theme.palette.secondary.dark,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            borderColor: theme.palette.secondary.light,
                            color: theme.palette.secondary.contrastText,
                            '& .MuiTypography-root':{
                                color: theme.palette.secondary.contrastText,
                            }
                        },
                        '& .MuiTypography-root':{
                            color: theme.palette.secondary.contrastText,
                        }
                    }),
                },
            ],
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    '& p': {
                        marginBottom: '0!important',
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    },
                    '& .MuiCardContent-root':{
                        padding:'1rem',
                    }
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                root:{
                    '& .MuiPaper-root':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiList-root':{
                        padding: '0',
                        '& a':{
                            color: '#000',
                            '& li':{
                                padding: '10px 20px',
                                '&:hover':{
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        },
                        '& svg':{
                            marginRight: '10px',
                            fontSize:'1rem'
                        },
                    },
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#f8f8f8',
                        '& .MuiDataGrid-columnHeaderTitle':{
                            color: '#444444',
                            fontWeight: '300',
                        }
                    },
                    '& .MuiDataGrid-pinnedColumns':{
                        backgroundColor:'#ffffff',
                        '& .MuiDataGrid-cell':{
                            backgroundColor: '#ffffff',
                            '& .MuiDataGrid-actionsCell':{
                                zIndex: 12,
                            }
                        }
                    },
                }
            }
        },
        MuiDialog:{
            styleOverrides: {
                root: {
                    '& .MuiDialog-paper':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiDialogContent-root':{
                        padding: '1rem',
                        paddingTop: '1rem!important',
                    },
                    '& .MuiDialogTitle-root':{
                        background: '#f8f8f8',
                        borderBottom: '1px solid #f2f2f2',
                    },
                    '& .MuiDialogActions-root':{
                        padding:'0 24px 24px'
                    }
                }
            }
        }
    },
}

const administrationDarkTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
    }
}

const businessLightTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        primary: {
            main: "#143c73",
            light: "#4970f3",
            dark: "#00254b",
            contrastText: "#fff",
        },
        info: {
            main: "#218cb6",
            light: "#5ebbe1",
            dark: "#326d86",
            contrastText: "#fff",
        },
        success: {
            main: "#8eb417",
            light: "#b7d07d",
            dark: "#6b890f",
            contrastText: "#fff",
        },
        error: {
            main: "#ff0000",
            light: "#ff6c6c",
            dark: "#cc0000",
            contrastText: "#fff",
        },
        warning: {
            main: "#e56305",
            light: "#ffb74d",
            dark: "#b24800",
            contrastText: "#fff",
        },
        type: 'light',
        background: {
            default: "#efeeee",
            paper: "#ffffff",
        },
    },
    text: {
        light: '#ffffff',
        dark: '#363537',
        error: '#ff0000',
        warning: '#e56305',
        success: '#8eb417',
    },
    link: {
        active: '#08476c',
        hover: '#0a5a7c',
    },
    auth:{
        loginBgColor: '#143c73',
        registerBgColor: '#143c73',
        forgotBgColor: '#143c73',
    },
    components: {
        MuiPickersPopper:{
            styleOverrides: {
                root: {
                    zIndex: '9999!important',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    backgroundColor: '#01a3ff',
                    color: '#ffffff',
                },
            },
        },
        MuiGrid:{
            styleOverrides: {
                root: {
                    color: 'inherit',
                }
            }
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    },
                    '& .MuiCardContent-root, & .MuiCardContent-root:last-child':{
                        paddingBottom: '0!important',
                    },
                    "& .MuiCardActions-root":{
                        borderTop: '1px solid #eff2f5',
                        background: 'transparent',
                        '& a': {
                            textDecoration: 'none'
                        }
                    }
                },
            },
        },
        MuiButton:{
            variants: [
                {
                    props: { variant: 'accountButton' },
                    style: ({ theme }) => ({
                        border:'1px solid',
                        textTransform: 'none',
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.primary.main,
                        '&:hover': {
                            borderColor: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                        },
                    }),
                },
            ],
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    '& p': {
                        marginBottom: '0!important',
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    },
                    '& .MuiCardContent-root':{
                        padding:'1rem',
                    }
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                root:{
                    '& .MuiPaper-root':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiList-root':{
                        padding: '0',
                        '& a':{
                            color: '#000',
                            '& li':{
                                padding: '10px 20px',
                                '&:hover':{
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        },
                        '& svg':{
                            marginRight: '10px',
                            fontSize:'1rem'
                        },
                    },
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-pinnedColumns':{
                        backgroundColor:'#ffffff',
                        '& .MuiDataGrid-cell':{
                            backgroundColor: '#ffffff',
                            '& .MuiDataGrid-actionsCell':{
                                zIndex: 12,
                            }
                        }
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#ffffff',
                    }
                }
            }
        },
        MuiDialog:{
            styleOverrides: {
                root: {
                    '& .MuiDialogContent-root':{
                        padding: '1rem',
                        paddingTop: '1rem!important',
                    },
                    '& .MuiDialog-paper':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiDialogTitle-root':{
                        background: '#f8f8f8',
                        borderBottom: '1px solid #f2f2f2',
                    },
                    '& .MuiDialogActions-root':{
                        padding:'0 24px 24px'
                    }
                }
            }
        }
    },
};

const businessDarkTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        type: 'light',
        background: {
            default: "#efeeee",
            paper: "#ffffff",
        },
    },
    text: {
        color: '#363537',
    },
    components: {
        MuiPickersPopper:{
            styleOverrides: {
                root: {
                    zIndex: '9999!important',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    backgroundColor: '#01a3ff',
                    color: '#ffffff',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    // Some CSS
                    background: 'transparent',
                    borderTop: '1px solid #eff2f5',
                    '& a':{
                        textDecoration:'none'
                    }
                },
            },
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    },

                },
            },
        },
        MuiButton:{
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    '&.MuiButton-outlined': {
                        color: coreTheme.text.dark,
                        backgroundColor: coreTheme.palette.primary.light,
                    }
                }
            }
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    '& p': {
                        marginBottom: '0!important',
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    },
                    '& .MuiCardContent-root':{
                        padding:'1rem',
                    }
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                root:{
                    '& .MuiPaper-root':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiList-root':{
                        padding: '0',
                        '& a':{
                            color: '#000',
                            '& li':{
                                padding: '10px 20px',
                                '&:hover':{
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        },
                        '& svg':{
                            marginRight: '10px',
                            fontSize:'1rem'
                        },
                    },
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-pinnedColumns':{
                        '& .MuiDataGrid-cell':{
                            backgroundColor: '#ffffff',
                        }
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#ffffff',
                    }
                }
            }
        },
    },
};

const investorLightTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        primary: {
            main: "#00b7fb",
            light: "#25c2ff",
            dark: "#0193c9",
            contrastText: "#fff",
        },
        info: {
            main: "#2273e0",
            light: "#5b8dcb",
            dark: "#2471a0",
            contrastText: "#fff",
        },
        success: {
            main: "#8eb417",
            light: "#80912c",
            dark: "#667f07",
            contrastText: "#fff",
        },
        error: {
            main: "#ff0000",
            light: "#ff6659",
            dark: "#aa0000",
            contrastText: "#fff",
        },
        warning: {
            main: "#de4b1e",
            light: "#fc4202",
            dark: "#ad3001",
            contrastText: "#fff",
        },
        secondary: {
            main: "#7a7c98",
            light: "#a0a3b9",
            dark: "#565870",
            contrastText: "#fff",
        },
        type: 'light',
        background: {
            default: "#eaf2fc",
            paper: "#ffffff",
        },
    },
    text: {
        light: '#ffffff',
        dark: '#363537',
        color: '#363537',
    },
    auth:{
        loginBgColor: '#00b7fb',
        registerBgColor: '#3da7e3',
        forgotBgColor: '#9c0',
    },
    container:{
        vvv: '#ffffff',
        Shadow: '0 0 5px 0 rgba(0,0,0,.1)',
    },
    components: {
        MuiPickersPopper:{
            styleOverrides: {
                root: {
                    zIndex: '9999!important',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    boxShadow: '0 0 5px 0 rgb(0 0 0 / 5%)',
                    backgroundColor: '#01a3ff',
                    color: '#ffffff',
                },
            },
        },
        MuiGrid:{
            styleOverrides: {
                root: {
                    color: 'inherit',
                }
            }
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    boxShadow:'none',
                    border:'1px solid #eaeaea',
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    },
                },
                MuiCardActions: {
                    styleOverrides: {
                        root: {
                            // Some CSS
                            background: 'transparent',
                            borderTop: '1px solid #eff2f5',
                            '& a':{
                                textDecoration:'none'
                            }
                        },
                    },
                },
            },
        },
        MuiButton:{
            variants: [
                {
                    props: { variant: 'accountButton' },
                    style: ({ theme }) => ({
                        border:'1px solid',
                        textTransform: 'none',
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.primary.main,
                        '&:hover': {
                            borderColor: theme.palette.primary.light,
                            color: theme.palette.primary.dark,
                        },
                    }),
                },
            ],
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    '& p': {
                        marginBottom: '0!important',
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    },
                    '& .MuiCardContent-root':{
                        padding:'1rem',
                    }
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                root:{
                    '& .MuiPaper-root':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiList-root':{
                        padding: '0',
                        '& a':{
                            color: '#000',
                            '& li':{
                                padding: '10px 20px',
                                '&:hover':{
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        },
                        '& svg':{
                            marginRight: '10px',
                            fontSize:'1rem'
                        },
                    },
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-pinnedColumns':{
                        '& .MuiDataGrid-cell':{
                            backgroundColor: '#ffffff',
                            '& .MuiDataGrid-actionsCell':{
                                zIndex: 12,
                            }
                        }
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#ffffff',
                    }
                }
            }
        },
        MuiDialog:{
            styleOverrides: {
                root: {
                    '& .MuiDialog-paper':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiDialogTitle-root':{
                        background: '#f8f8f8',
                        borderBottom: '1px solid #f2f2f2',
                    }
                }
            }
        }
    },
};

const investorDarkTheme = {
    ...coreTheme,
    palette: {
        ...coreTheme.palette,
        type: 'light',
        background: {
            default: "#efeeee",
            paper: "#ffffff",
        },
    },
    text: {
        color: '#363537',
    },
    components: {
        MuiPickersPopper:{
            styleOverrides: {
                root: {
                    zIndex: '9999!important',
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // Some CSS
                    backgroundColor: '#01a3ff',
                    color: '#ffffff',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    // Some CSS
                    background: 'transparent',
                    borderTop: '1px solid #eff2f5',
                    '& a':{
                        textDecoration:'none'
                    }
                },
            },
        },
        MuiFormHelperText:{
            styleOverrides: {
                root: {
                    color: '#ff0000',
                },
            },
        },
        MuiCard:{
            styleOverrides: {
                root: {
                    color: '#333333',
                    "& .MuiCardHeader-root":{
                        borderBottom:'1px solid #ddd'
                    },

                },
            },
        },
        MuiButton:{
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    '&.MuiButton-outlined': {
                        color: coreTheme.text.dark,
                        backgroundColor: coreTheme.palette.primary.light,
                    }
                }
            }
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    '& p': {
                        marginBottom: '0!important',
                    }
                }
            }
        },
        MuiDrawer:{
            styleOverrides: {
                root: {
                    '& .MuiDrawer-paper':{
                        position:'relative'
                    },
                    '& .MuiCardContent-root':{
                        padding:'1rem',
                    }
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                root:{
                    '& .MuiPaper-root':{
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgb(0 0 0 / 10%)',
                    },
                    '& .MuiList-root':{
                        padding: '0',
                        '& a':{
                            color: '#000',
                            '& li':{
                                padding: '10px 20px',
                                '&:hover':{
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        },
                        '& svg':{
                            marginRight: '10px',
                            fontSize:'1rem'
                        },
                    },
                }
            }
        },
        MuiDataGrid:{
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-pinnedColumns':{
                        '& .MuiDataGrid-cell':{
                            backgroundColor: '#ffffff',
                        }
                    },
                    '& .MuiDataGrid-columnHeader':{
                        backgroundColor: '#ffffff',
                    }
                }
            }
        },
    },
};

export const theme = {
    darkTheme: createTheme(darkTheme), // to be removed
    lightTheme: createTheme(lightTheme), // to be removed

    administrationLightTheme: createTheme(administrationLightTheme),
    administrationDarkTheme: createTheme(administrationDarkTheme),

    businessLightTheme: createTheme(businessLightTheme),
    businessDarkTheme: createTheme(businessDarkTheme),

    investorLightTheme: createTheme(investorLightTheme),
    investorDarkTheme: createTheme(investorDarkTheme),
};
