import React, { useEffect, useRef, useState } from 'react';
import {
    Stack, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, IconButton
} from "@mui/material";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../../../../Setup/Config";
import { fetchLoans } from "../../Redux/Action";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PageviewIcon from '@mui/icons-material/Pageview';
import styled from "@mui/material/styles/styled";

export const DefaultContractDetails = ({ data, setPanelHeight }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const panelRef = useRef(null);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [pagination, setPagination] = useState({
        current_page: 0,
        pageSize: 10,
        total: 0,
        from: 0,
        to: 0,
        last_page: 0,
        data: []
    });

    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const fetchData = async (page, rowsPerPage) => {
        setIsLoading(true);
        const status = ['DEFAULT'];
        await fetchLoans({ dispatch, data: { business_id: data['business_id'], with:['default_amount'], sort_by:'loan_id', sort_dir:'desc', status: status, page, pageSize: rowsPerPage } })
            .then((res) => {
                if (res['success']) {
                    setPagination(res.data.data);
                    setItems(res.data.data.data);
                    setIsDataLoaded(true); // Set flag to true after data is loaded
                }
                setIsLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (isDataLoaded && panelRef.current) { // Only set height if data is fully loaded
            const newHeight = panelRef.current.scrollHeight;
            if (newHeight > 0) {
                setPanelHeight(newHeight);
            }
        }
    }, [isDataLoaded]); // Depend on isDataLoaded to ensure this runs only after data is ready

    const StyledTable = styled(Table)(({ theme }) => ({
        '& .MuiTableBody-root .MuiTableCell-root': {
            padding: theme.spacing(0.5,3),
        },
        '& thead': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '120px',
                textAlign: 'left',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(4)': {
                width: '180px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(5)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
        },
        // Grouped styles for the body
        '& tbody': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '120px',
                textAlign: 'left',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(4)': {
                width: '180',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(5)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
        }
    }));

    return (
        <Stack ref={panelRef}
               sx={{ height: 'auto', boxSizing: 'border-box' }}
               direction="column"
        >
            <Paper variant="outlined" square sx={{ flex: 1, mx: 'auto', width: '100%', p: 1, backgroundColor: '#efefef' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isLoading && <Typography>Loading...</Typography>}
                        {!isLoading && items.length === 0 && <Typography>No data found</Typography>}
                        {!isLoading && items.length > 0 &&
                            <TableContainer component={Paper}>
                                <StyledTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Contract No</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Default Amount</TableCell>
                                            <TableCell>Service Type</TableCell>
                                            <TableCell>Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row) => (
                                            <TableRow key={row.account_id}>
                                                <TableCell><Typography
                                                    onClick={() => navigate("/business-finance/" + row.loan_id)}
                                                    sx={{
                                                        fontSize: '0.86rem',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none',
                                                        color: theme.text.link
                                                    }}>{row.loan_no}</Typography>
                                                </TableCell>
                                                <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                                                <TableCell>{currencyFormatter.format(row.default_amount?.total_defaulted_amount ?? 0)}</TableCell>
                                                <TableCell>{row.service?.service_type?.name}</TableCell>
                                                <TableCell><IconButton onClick={() => navigate(`/business-finance/${row.loan_id}`) }><PageviewIcon /></IconButton></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </StyledTable>
                                <TablePagination
                                    component="div"
                                    count={pagination.total}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                                />
                            </TableContainer>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};
