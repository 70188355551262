import * as React from 'react';
import { CardContent, CardHeader} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useSelector} from "react-redux";
import {Panel, PanelList} from "../../../../../Components";

export const ServiceRequestOverviewPanel = () => {

    const business = useSelector((store) => store.business?.selected);
    const service = useSelector((store) => store.services.selected);

    return (
        <>
            <Panel>
                <CardHeader title={'Request Details'} />
                <CardContent>
                    <PanelList>
                        <ListItem>
                            <ListItemText primary={'Business'} secondary={business?.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Service'} secondary={service.service_no} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Service'} secondary={service?.service_type?.name} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Amount Requested'} secondary={"AED " + service.amount} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Tenor'} secondary={service.tenor + " months"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Profit Rate'} secondary={service.profit_rate + " %"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Profit'} secondary={service.profit_amount + " %"} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'Purpose'} secondary={service.purpose} />
                        </ListItem>
                    </PanelList>
                </CardContent>
            </Panel>
        </>
    );
}
