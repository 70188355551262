import * as React from 'react';
import {GridActionsCellItem} from '@mui/x-data-grid';
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {Card, CardContent, CardHeader} from "@mui/material";
import Button from "@mui/material/Button";
import { addAddress, clearErrors, proceed, setSelectedAddress, CompanyLocationDeleteModal} from "../../index";
import {setModal} from "../../../../Common/App";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {CompanyLocationForm} from "../Forms/CompanyLocationForm";
import moment from "moment/moment";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useEffect} from "react";
import {currencyFormatter} from "../../../../../Setup/Config";
import {Link} from "react-router-dom";

export const CompanyLocationCard = (props) => {

    const business = useSelector((store) => store.business);
    const documents = useSelector((store) => store.business.selected?.documents);
    let items = Object.keys(business.addresses).length > 0 ? business.addresses.list.filter(i => i.is_draft !== 1) : [];

    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const [filters, setFilters] = React.useState({
        pageSize: 10,
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        dispatch(setModal({component:null, open:false}));
    },[]);

    const documentLink = (document_type, value, id) => {

        const type = {'address_type': 'tenancy_doc',};

        if (documents && documents.length > 0) {
            let doc = documents.filter((doc) => doc.type === type[document_type] && doc.entity_id === id);

            if (doc.length === 1) {
                return (
                    <Link style={{ textDecoration: 'none' }} to={process.env.REACT_APP_BASE_URL + 'document/' + doc[0].document_id} target="_blank" rel="noopener noreferrer">
                        {value}
                    </Link>
                );
            }
        }

        return value;
    };

    const handleActionBtn = async (e) =>{
        if(e.target.dataset.action === 'AddItem'){
            await addAddress({dispatch, data:{business_id:business.selected.business_id}});
        }else{
            dispatch(clearErrors(false));
            dispatch(setSelectedAddress(e.currentTarget.dataset.id));
        }
        dispatch(setModal({component:<CompanyLocationForm business={business} />, open:true}));
        dispatch(proceed(false));
    }

    const handleDeleteBtn = async (e) => {
        dispatch(setModal({component:<CompanyLocationDeleteModal />, open:true}));
        dispatch(setSelectedAddress(e.currentTarget.dataset.id));
    }

    const handleRowClick = (param, event) => {
        props.setEntityId(param.row.id);
    }

    const editable = React.useCallback(
        () => {
            if (['DRAFT','RETURNED'].includes(business.status?.name) && auth.user.role_type === 'CLIENT') {
                return ['add-business-address'].some(i => permissions.includes(i));
            } else if (permissions.includes('su-edit-business-addresses') && auth.user.role_type !== 'CLIENT') {
                return true;
            }
            return false;
        },[business.status?.name, auth.user.role_type, permissions]
    );

    const actions = React.useCallback(
        (params) => {
            if (editable()) {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        data-id={params.id}
                        data-action={"DeleteItem"}
                        onClick={handleDeleteBtn}
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon sx={{zIndex:-1}} />}
                        label="Edit"
                        data-id={params.id}
                        data-action={"EditItem"}
                        onClick={handleActionBtn}
                    />,
                ];
            }

            return [];

        },[editable]
    );

    const columns = React.useMemo(
        () => [
            { field: 'address_type', headerName:'Address Type', type: 'string', flex:1, minWidth: 120,
                renderCell: (params) => documentLink('address_type', params.row.address_type, params.id)
            },
            { field: 'address', headerName:'Address', type: 'string', flex:1, minWidth: 300 },
            { field: 'landlord', headerName:'Landlord Name', type: 'string', flex:1, minWidth: 120},
            { field: 'annual_rent_amount', headerName:'Annual Rent', type: 'number', flex:1, minWidth: 120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'last_rent_paid_amount', headerName:'Last Paid Amt', type: 'number', flex:1, minWidth: 120, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'last_rent_date', headerName:'Last Rent Date', type: 'date', flex:1, minWidth: 140, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'tenancy_start_date', headerName:'Tenancy Start Date', type: 'date', flex:1, minWidth: 150, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'tenancy_end_date', headerName:'Tenancy End Date', type: 'date', flex:1, minWidth: 150, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'remarks', headerName:'Remarks', type: 'string', minWidth: 250 },
            { field: 'actions', flex:1, minWidth: 120, type: 'actions', headerName:'Action', getActions: (params) => actions(params)},
        ],
        [],
    );

    return (
        <>
            <Card>
                <CardHeader
                    titleTypographyProps={{variant:'h6' }}
                    title={'Company Locations'}
                    action={editable() && <Button variant="outlined" size={"small"} data-id={"draft"} data-action={"AddItem"} onClick={handleActionBtn}>Add New</Button>}
                />
                <CardContent sx={{borderBottom:'1px solid #ddd', padding:'0px'}}>
                    { items !== undefined &&  Object.keys(items).length > 0 ?
                        <DataGridPro
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={filters.pageSize}
                            onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                            onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                            columns={columns}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            rows={items || []} sx={{ border:'none'}}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'created_at', sort: 'desc' }],
                                },
                                pinnedColumns: { right: ['actions'] },
                                columns: {
                                    columnVisibilityModel: {
                                        actions: editable(),
                                    },
                                },
                            }}
                        />:<Typography align={"center"} p={2}>No Records Found</Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}