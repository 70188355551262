import React, {useEffect} from 'react'
import { Tooltip,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {useNavigate} from "react-router-dom";
import {currencyFormatter} from "../../../../../Setup/Config";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import {fetchBusiness} from "../../../Business";
import moment from "moment/moment";
import {fetchEarningBusiness, setEarningConfig} from "../../Redux/Action";
import {EarningExpandDetails} from "../Cards/EarningExpandDetails";

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    '& .MuiDataGrid-virtualScrollerRenderZone .defaulted': {
        boxShadow: `inset 3px 0 0 0 ${theme.palette.error.main}`,
        background: `${theme.palette.error.light}`,
    },
}));

export const EarningStatementTable = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const fundedStatement = useSelector((store) => store.fundStatement?.earningStatement);
    const filters = useSelector((store) => store.fundStatement?.earningStatement?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.fundStatement?.earningStatement?.config?.pagination);
    const [isLoading, setIsLoading] = React.useState(true);
    const [panelHeights, setPanelHeights] = React.useState({});

    useEffect(() => {
        fetchData();
    },[pagination, filters])

    const fetchData = async () => {
        setIsLoading(true);
        await fetchEarningBusiness({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
        })
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/business/' + id);
        },
        [],
    );

    const viewLoan = React.useCallback(
        (id) => async () => {
            navigate('/business-finance/' + id);
        },
        [],
    );

    const viewDashboard = React.useCallback(
        (id) => async () => {
            await fetchBusiness({dispatch, id});
            navigate(`/dashboard/${id}`)
        },
        [],
    );


    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            {
                field: 'business_no',
                headerName: 'B No',
                align:'center',
                headerAlign:'center',
                width: 70,
            },
            { field: 'name', headerName:'Business Name', type: 'string', flex: 1, minWidth: 350,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.id)}>{params.value}</Typography>
                }
            },
            { field:'registration_date', headerName:'Registration', align:'center', headerAlign:'center', type: 'string', minWidth: 100, flex:1,
                valueFormatter: ({ value }) =>  value && moment(value).format("DD/MM/YYYY")
            },
            { field:'latest_loan_date', headerName:'Last Funding', align:'center', headerAlign:'center', type: 'string', minWidth: 100, flex:1,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewLoan(params.row.latest_loan.loan_id)}>{params.row.latest_loan.loan_id && moment(params.row.latest_loan.start_date).format("DD/MM/YYYY")}</Typography>
                }
            },
            { field:'earning', headerName:'Earnings', type: 'number', minWidth: 120, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.earning ?? 0);
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width:70,
                getActions: (params) => {
                    if(['APPROVED','DEFAULT'].includes(params.row.status?.name)){
                        return [
                            <Tooltip title={"Show Details"}>
                                <GridActionsCellItem
                                    icon={<PageviewIcon sx={{zIndex:-1}} />}
                                    label={"View"}
                                    color={"primary"}
                                    onClick={ viewDashboard(params.id) }
                                />
                            </Tooltip>
                        ];
                    }
                    return [];
                },
            },

        ],
        [],
    );

    const setPanelHeight = React.useCallback((id, height) => {
        setPanelHeights((prev) => ({ ...prev, [id]: height }));
    }, []);

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <EarningExpandDetails data={row}  setPanelHeight={(height) => setPanelHeight(row.business_id, height)} />,
        [setPanelHeight],
    );

    const getDetailPanelHeight = React.useCallback(
        (params) => panelHeights[params.id] || 100, // Default height if not calculated yet
        [panelHeights]
    );

    return (
        <>
            <StyledDataGridPro
                autoHeight
                getRowId={(row) => row.business_id}
                columns={columns}
                rows={fundedStatement?.list || []}
                sx={{ border:'none'}}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                pagination
                paginationMode="server"
                paginationModel={pagination}
                rowCount={fundedStatement?.config?.rowCount ?? 0}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                loading={isLoading}
                onPaginationModelChange={(params) => !isLoading && (setIsLoading(true) || dispatch(setEarningConfig({ pagination: { ...pagination, page: params.page, pageSize: params.pageSize } })))}
                initialState={{
                    pinnedColumns: { right: ['actions'] },
                    sorting: {
                        sortModel: [{ field: 'earning', sort: 'desc' }],
                    },
                    pagination: { paginationModel: { ...pagination } },
                }}
                getRowClassName={(params) =>
                    params.row.status?.name === 'DEFAULT' ? 'defaulted' : ''
                }
            />
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const shouldShowToggle = true;//!['PENDING','VERIFIED'].includes(props.row.status);

    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
