import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid} from 'recharts';
import {Panel} from "../../../../../Components";
import {isBrowser} from "react-device-detect";
import {Typography, Card as CardUI, Box, Card, CardHeader, CardContent} from "@mui/material";
import {styled} from "@mui/material/styles";
import {currencyFormatter} from "../../../../../Setup/Config";
import {fetchAccountStats} from "../../Redux/Action";
import {DateRangeSelectorDirect} from "../Popover/DateRangeSelectorDirect";

export const DisbursementCollectionChart = ({dates, setDates}) => {

    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);

    useEffect(() => {
        fetchData(dates);
    },[dates]);

    let fetchData = async (dates = []) => {

        const dateFilter = dates.length === 0 ? {} : { from_date: dates[0], to_date: dates[1] };

        await fetchAccountStats({dispatch, filters:{...dateFilter}}).then((res) => {
            if (res['success']) {
                setData(res?.data?.data)
            }
        });
    }

    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle " fontSize={11} fill="#666" transform="rotate(-0)">
                    {payload.value}
                </text>
            </g>
        );
    };

    const calculateBarSize = (dataLength) => {

        if (dataLength > 12) {
            return 5; // Return 5 if dataLength is greater than 12
        }

        const barSizes = {
            1: 10,
            2: 10,
            3: 10,
            4: 10,
            5: 10,
            6: 10,
            7: 10,
            8: 10,
            9: 10,
            10: 5,
            11: 5,
            12: 5
        };

        return barSizes[dataLength] || 15; // Return size based on dataLength or default 15
    };

    const barSize = calculateBarSize(data.length);

    const CustomTooltip = ({ active, payload, label }) => {

        const months = {
            Dec: "December",
            Jan: "January",
            Feb: "February",
            Mar: "March",
            Apr: "April",
            May: "May",
            Jun: "June",
            Jul: "July",
            Aug: "August",
            Sep: "September",
            Oct: "October",
            Nov: "November"
        };

        const Card = styled(CardUI)({
            boxShadow:'0px 0px 6px 0px rgba(0,0,0,0.2)',
            '& .MuiCardHeader-root':{
                padding:8
            },
            '& .MuiCardContent-root':{
                padding:8
            }
        })

        if (active && payload && payload.length) {
            const payload0 = currencyFormatter.format(payload[0].value);
            const payload1 = currencyFormatter.format(payload[1]?.value);
            const payload2 = currencyFormatter.format(payload[2]?.value);
            return (
                <Card sx={{background:'rgba(255,255,255,0.8)'}}>
                    <CardHeader title={months[label]} />
                    <CardContent>
                        <Typography>{`${payload[0].name} : ${payload0}`}</Typography>
                        <Typography>{`${payload[1].name} : ${payload1}`}</Typography>
                        <Typography>{`${payload[2].name} : ${payload2}`}</Typography>
                    </CardContent>
                </Card>
            );
        }

        return null;
    };

    const CustomizedYAxisTick = ({ x, y, payload }) => {
        // Function to format number to millions
        const formatToMillion = (value) => {
            return (value / 1000000).toFixed(2) + 'M';
        };

        return (
            <g transform={`translate(${x},${y})`}>
                {/*<line x2={-10} stroke="#666" strokeDasharray="3 3" />*/}
                <text x={-16} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
                    {formatToMillion(payload.value)}
                </text>
            </g>
        );
    };

    return (
        <>
            <Panel>
                <CardHeader
                    title={"Funding Overview"}
                    action={
                        <DateRangeSelectorDirect dates={dates} setDates={setDates} />
                    }
                />
                <CardContent sx={{ padding: '15px!important', display:'flex', justifyContent:'center' }}>
                    <ResponsiveContainer width="100%" height={ isBrowser ? 260 : 400} >
                        { data.length === 0 ?
                            <Box display="flex"
                                 alignItems="center"
                                 justifyContent="center"
                                 height="100vh">
                                <Typography>No Data to show</Typography>
                            </Box>
                            :
                            <BarChart
                                height={300}
                                data={data}
                                sx={{ width: '100%'}} // Set the width to 100%
                            >
                                <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
                                <XAxis dataKey="month" tick={<CustomizedAxisTick />} axisLine={false} tickLine={false} />
                                <YAxis tick={<CustomizedYAxisTick />} tickLine={false} axisLine={false}  />
                                <Tooltip content={<CustomTooltip />}  />
                                <Legend wrapperStyle={{ marginTop: '10px', fontSize: '11px' }} />
                                <Bar dataKey="Funded" label={"Disbursement"} formatter={(value) => `AED ${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`} fill="#3597BD" barSize={barSize} radius={[10, 10, 0, 0]} />
                                <Bar dataKey="Receipts" label={"Realized Receivable"} formatter={(value) => `AED ${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`} fill="#33aa00" barSize={barSize} radius={[10, 10, 0, 0]} />
                                <Bar dataKey="Outstanding" label={"Outstanding Receivable"} formatter={(value) => `AED ${value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`} fill="#e56305" barSize={barSize} radius={[10, 10, 0, 0]} />
                            </BarChart>}
                    </ResponsiveContainer>
                </CardContent>
            </Panel>
        </>
    );
}
