import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    FormControlLabel,
    FormGroup,
    Grid,
    ListItemText as ListItemTextUI,
    Stack, TableHead, TableRow, TableCell, Table, TableBody, TableContainer,
    TextField,
    Tooltip, Typography
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Toastr from "../../../../../Components/Elements/Toastr";
import {listService} from "../../../Services";
import {currencyFormatter} from "../../../../../Setup/Config";
import {setModal} from "../../../../Common/App";
import {addLoan, fetchExistingLoans} from "../../../Loan";
import {styled} from "@mui/material/styles";

export const ApproveServiceRequestModal = () => {

    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const permissions = useSelector((store) => store.app.permissions);
    const loans = useSelector(state => state.loans);
    const service = useSelector(state => state.services?.selected);
    const business = useSelector(state => state.business);
    const [showFees, setShowFees] = useState(false);
    const [showLoans, setShowLoans] = useState(false);
    const business_config = business.selected?.config;
    const [data, setData] = useState({});
    const [selectedLoans, setSelectedLoans] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const [response, setResponse] = useState({});
    const [tenorError, setTenorError] = useState('');

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target; // Destructure to get the checked property

        // Handle checkbox changes correctly
        const fieldValue = type === "checkbox" ? (checked ? 1 : 0) : value; // Use 'checked' for checkboxes

        if (name === "max_allowed_tenor") {
            const tenorValue = parseFloat(value);
            if (tenorValue % 0.5 !== 0) {
                setDisabled(true);
                setTenorError('Tenor must be in increments of 0.5');
                return;
            } else if (tenorValue > 120) {
                setDisabled(true);
                setTenorError('Tenor cannot exceed 120');
                return;
            } else {
                setTenorError('');
            }
        }

        setShowFees(false);
        setDisabled(false);
        setData(data => ({
            ...data,
            [name]: fieldValue, // Use fieldValue to handle checkboxes properly
        }));
    };

    useEffect(() => {
        setSelectedLoans([])
        if(data.funding_merged === 1) {
            fetchData();
        }
        setShowLoans(false);
    },[data.funding_merged]);

    const fetchData = async () => {
        await fetchExistingLoans({dispatch, data:{business_id:service.business_id, status:['ACTIVE']}}).then((res) => {
            if(res['success']){
                setShowLoans(true);
            }
        });
    }

    useEffect(() => {
        const setInitialData = () => {
            const defaultConfigValue = (type) => business_config?.find((config) => config.config_type === type)?.config_value ?? 0;

            setData(data => ({
                ...data,
                'start_date': moment().startOf('day').format('YYYY-MM-DD'),
                'service_id': service.service_id,
                'amount': service.amount ?? 0,
                'overdue_amount': service.overdue_amount ?? 0,
                'load_amount': 0,
                'overdue_discount_rate': 0,
                'remarks': service.remarks ?? '',
                'profit_rate_config': defaultConfigValue('profit_rate'),
                'processing_fee_config': defaultConfigValue('processing_fee'),
                'document_fee_config': defaultConfigValue('document_fee'),
                'due_diligence_fee_config': defaultConfigValue('due_diligence_fee'),
                'account_setup_fee_config': defaultConfigValue('account_setup_fee'),
                'no_of_repayments': service.service_type !== 'INVOICE_DISCOUNTING' ? defaultConfigValue('no_of_repayments') : 1,
                'max_allowed_tenor': defaultConfigValue('max_allowed_tenor'),
                'late_payment_fee_config': defaultConfigValue('late_payment_fee'),
                'late_payment_fee_rate_config': defaultConfigValue('late_payment_fee_rate'),
                'late_payment_fee_calculation': 0,
                'for_restructure': service.for_restructure,
                'funding_merged': 0,
            }));
        };

        setDisabled(business.selected.status !== 'APPROVED');
        setInitialData();
    }, []);

    const handleReviewBtn = () => {

        const isEmpty = (value) => value === '' || isNaN(parseFloat(value));

        const calculateFeeWithVat = (feeFromConfig) => {
            const fee = parseFloat(feeFromConfig);
            const vat = 0.05;
            const totalFee = fee * (1 + vat);
            return Math.round(totalFee);
        };

        const load_amount = parseFloat(data?.load_amount);

        const overdue_discount_rate = parseFloat(data?.overdue_discount_rate);

        const overdue_amount = parseFloat(data?.overdue_amount) - ((overdue_discount_rate / 100) * parseFloat(data?.overdue_amount));

        const amount = parseFloat(data.amount) + load_amount + overdue_amount;

        //const profit_fee = isEmpty(data?.amount) || isEmpty(data?.profit_rate_config) || isEmpty(data?.max_allowed_tenor)
        const profit_fee = isEmpty(amount) || isEmpty(data?.profit_rate_config) || isEmpty(data?.max_allowed_tenor)
            ? ''
            : calculateFeeWithVat(amount * (data?.profit_rate_config / 100) * data?.max_allowed_tenor);

        const processing_fee = isEmpty(amount) || isEmpty(data?.processing_fee_config)
            ? ''
            : calculateFeeWithVat(amount * (data?.processing_fee_config / 100));

        const document_fee = isEmpty(data?.document_fee_config)
            ? ''
            : calculateFeeWithVat(data?.document_fee_config);

        const due_diligence_fee = isEmpty(data?.due_diligence_fee_config)
            ? ''
            : calculateFeeWithVat(data?.due_diligence_fee_config);

        const account_setup_fee = isEmpty(data?.account_setup_fee_config)
            ? ''
            : calculateFeeWithVat(data?.account_setup_fee_config);

        const late_payment_fee = isEmpty(data?.late_payment_fee_config)
            ? ''
            : calculateFeeWithVat(data?.late_payment_fee_config);

        setData(data => ({
            ...data,
            'amount' : amount,
            'load_amount': load_amount,
            'overdue_discount_rate': overdue_discount_rate,
            'profit_fee': profit_fee,
            'processing_fee': processing_fee,
            'document_fee': document_fee,
            'due_diligence_fee': due_diligence_fee,
            'account_setup_fee': account_setup_fee,
            'late_payment_fee': late_payment_fee,
        }));

        //setShowFees(!isEmpty(profit_fee) && !isEmpty(processing_fee) && !isEmpty(document_fee) && !isEmpty(due_diligence_fee) && !isEmpty(account_setup_fee));
        setShowFees(true);
    };

    const handleLoansCheckboxChange = (e, loan) => {
        const { checked } = e.target;

        // Update selected loans state
        if (checked) {
            setSelectedLoans((prevSelectedLoans) => [...prevSelectedLoans, loan.loan_id]); // Add loan_no to selected list
        } else {
            setSelectedLoans((prevSelectedLoans) =>
                prevSelectedLoans.filter((loanId) => loanId !== loan.loan_id) // Remove loan_no from selected list
            );
        }
    };

    const selectedLoansSum = loans.list
        .filter((loan) => selectedLoans.includes(loan?.loan_id))
        .reduce((total, loan) => total + loan.balance, 0);

    const handleIssueLoan = async () => {

        setDisabled(true);
        let payload = {};

        payload['service_id'] = service.service_id;
        payload['load_amount'] = data.load_amount ?? 0;
        payload['overdue_discount'] = data.overdue_discount_rate ?? 0;
        payload['amount'] = data.amount;
        payload['business_id'] = business.selected.business_id;
        payload['end_date'] = moment(data.start_date).add(parseInt(data?.max_allowed_tenor * 30), 'days').format('YYYY-MM-DD');
        payload['profit_rate'] = data?.profit_rate_config;
        payload['repayment_period'] = data?.max_allowed_tenor;
        payload['repayment_frequency'] = data?.no_of_repayments;
        payload['fee_included'] = data['fee_included'] ?? 0;
        payload['fee_clubbed'] = data['fee_clubbed'] ?? 0;
        payload['late_payment_fee_rate'] = data['late_payment_fee_rate'] ?? 0;
        payload['late_payment_fee'] = data['late_payment_fee'] ?? 0;
        payload['late_payment_fee_calculation'] = data['late_payment_fee_calculation'] ?? 0;
        payload['for_restructure'] = data['for_restructure'] ?? 0;
        payload['funding_merged'] = data['funding_merged'] ?? 0;
        if(data['funding_merged'] === 1) {
            payload['merged_loan_ids'] = selectedLoans;
        }
        payload['parent_service_id'] = data['for_restructure'] === 1 ? service.parent_id : null;
        payload['start_date'] = data['start_date'];
        payload['fees'] = {
            'profit_fee': data['profit_fee'],
            'processing_fee': data['processing_fee'],
            'document_fee': data['document_fee'],
            'due_diligence_fee': data['due_diligence_fee'],
            'account_setup_fee': data['account_setup_fee'],
        }

        //Remove the below data points as these will be treated under data['fees']
        await addLoan({dispatch, data:payload}).then((res)=>{
            setResponse(res);
            //After the loan is created attach the loan with the invoice.
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
                navigate('/business-finance/' + res.data.data.loan_id);
            }
        });
    };

    const handleListService = async () => {

        await listService({dispatch, data}).then((res)=> {
            setResponse(res);
            if (res['success']) {
                dispatch(setModal({component: null, open: false}));
            }
        });
    }

    const ActionButton = () => {

        let action;
        let label;

        if (permissions.includes('list-service-request') && showFees && service?.status?.name === 'VERIFIED' && app.config.investor_portal_activated === 'enabled') {
            action = () => handleListService();
            label = "List on Investor Portal";
        }
        else if (permissions.includes('issue-loan') && showFees && service?.status?.name === 'VERIFIED' && app.config?.investor_portal_activated === 'disabled') {
            action = () => handleIssueLoan();
            label = "Issue Loan";
        }
        else {
            action = handleReviewBtn;
            label = "Review";
        }

        return ( <Button disabled={disabled} variant="contained" color="info" onClick={action}> {label} </Button> );
    };

    const ListItemText = styled(ListItemTextUI)({
        background:'#f9f9f9',
        maxWidth: 360,
        padding:'0.8rem',
        border:'1px solid rgba(0,0,0,0.2)',
        margin:0,
        width:'100%',
        '& .MuiListItemText-primary': {
            fontWeight: 300,
            fontSize: 12,
            color: '#888888'
        },
        '& .MuiListItemText-secondary': {
            fontSize: 16,
            fontWeight: 600,
            color: '#333333'
        }
    })

    return (
        <>
            <DialogTitle>Approve Request { service.for_restructure === 1 ? ' - Restructure' : ' - General' }</DialogTitle>
            <DialogContent>
                { (Object.keys(data).length > 0) &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={service.for_restructure === 1 ? 6 : 12}>
                            <TextField
                                fullWidth
                                label={ service.for_restructure === 1 ? "Principal Amount" : "Request Amount" }
                                name="amount"
                                defaultValue={data?.amount}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.amount}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        {service.for_restructure === 1 &&
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Overdue Amount"
                                    name="overdue_amount"
                                    defaultValue={data?.overdue_amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={loans?.error?.overdue_amount}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        }

                        { service.for_restructure === 1 &&
                            <Grid item xs={12} md={ service.for_restructure ? 6 : 12}>
                                <TextField
                                    fullWidth
                                    label="Load Amount"
                                    name="load_amount"
                                    defaultValue={data?.load_amount}
                                    inputlabelprops={{ shrink: true }}
                                    helperText={loans?.error?.load_amount}
                                    onChange={(e) => handleChange(e)}
                                />
                            </Grid>
                        }

                        { service.for_restructure === 1 &&
                            <Grid item xs={12} md={6}>
                                <Tooltip title={"test"}>
                                    <TextField
                                        fullWidth
                                        label="Overdue Discount Rate"
                                        name="overdue_discount_rate"
                                        defaultValue={data?.overdue_discount_rate}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={loans?.error?.overdue_discount_rate}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Tooltip>
                            </Grid>
                        }

                        <Grid item xs={12} md={6}>
                            <MuiDatepicker setDisabled={setDisabled}  label={"Start Date"} name="start_date" data={data} state={setData} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Tenor (months)"
                                name="max_allowed_tenor"
                                defaultValue={data?.max_allowed_tenor}
                                inputlabelprops={{ shrink: true }}
                                helperText={tenorError || loans?.error?.max_allowed_tenor}
                                onChange={(e) => handleChange(e)}
                                error={!!tenorError}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Forfaiting/ Discounting Fee % (profit %)"
                                name="profit_rate_config"
                                defaultValue={data?.profit_rate_config}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.profit_rate}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Processing Fee"
                                name="processing_fee_config"
                                defaultValue={data?.processing_fee_config}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.processing_fee_config}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="No of Repayment"
                                name="no_of_repayments"
                                defaultValue={data?.no_of_repayments}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.no_of_repayments}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Documentation Fee"
                                name="document_fee_config"
                                defaultValue={data?.document_fee_config}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.document_fee}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Due Diligence Fee"
                                name="due_diligence_fee_config"
                                defaultValue={data?.due_diligence_fee_config}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.due_diligence_fee}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Account Setup Fee"
                                name="account_setup_fee_config"
                                defaultValue={data?.account_setup_fee_config}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.account_setup_fee}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider>Late Fee Configuration</Divider>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Late Payment Fee Rate"
                                name="late_payment_fee_rate"
                                defaultValue={data?.late_payment_fee_rate}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.late_payment_fee_rate}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Late Payment Fee"
                                name="late_payment_fee_config"
                                defaultValue={data?.late_payment_fee_config}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.late_payment_fee}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="late_payment_fee_calculation" onChange={(e) => handleChange(e)} />} label="Apply delay fee only on the remaining balance" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Notes"
                                name="remarks"
                                multiline
                                rows={3}
                                defaultValue={data?.remarks}
                                inputlabelprops={{ shrink: true }}
                                helperText={loans?.error?.remarks}
                                onChange={(e) => handleChange(e)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="fee_included" onChange={(e) => handleChange(e)} />} label="Include fee in Request" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="fee_clubbed" onChange={(e) => handleChange(e)} />} label="Service Charge Single Payment" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="funding_merged" onChange={(e) => handleChange(e)} />} label="Merge Funding" />
                            </FormGroup>
                        </Grid>
                        {showLoans && (
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Select</TableCell>
                                                <TableCell>Contract #</TableCell>
                                                <TableCell sx={{textAlign:'right'}}>Amount</TableCell>
                                                <TableCell sx={{textAlign:'right'}}>Balance</TableCell>
                                                <TableCell>Start Date</TableCell>
                                                <TableCell>End Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loans.list.map((loan) => (
                                                <TableRow key={loan.loan_id}> {/* Ensure each row has a unique key */}
                                                    <TableCell>
                                                        <Checkbox
                                                            name={`select-loan-${loan.loan_id}`} // Unique name for each checkbox
                                                            onChange={(e) => handleLoansCheckboxChange(e, loan)} // Custom function to handle change
                                                            checked={selectedLoans.includes(loan.loan_id)}
                                                            //disabled={loan?.balance > data.amount}
                                                            disabled={selectedLoansSum + loan.balance > data.amount && !selectedLoans.includes(loan.loan_id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell><Typography onClick={() => navigate(`/business-finance/${loan.loan_id}`)} component={Link}>{loan.loan_no}</Typography></TableCell>
                                                    <TableCell sx={{textAlign:'right'}}>{currencyFormatter.format(loan.amount)}</TableCell>
                                                    <TableCell sx={{textAlign:'right'}}>{currencyFormatter.format(loan.balance)}</TableCell>
                                                    <TableCell>{moment(loan.start_date).format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell>{moment(loan.end_date).format('DD/MM/YYYY')}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                        {showFees &&
                            <>
                                <Grid item xs={12}>
                                    <Divider>Fees</Divider>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Amount + Load + Overdue = Total"}
                                        secondary={currencyFormatter.format(data?.amount) + ' + ' + currencyFormatter.format(data?.load_amount) + ' - ' + currencyFormatter.format(data?.overdue_amount) + ' = ' + currencyFormatter.format(data?.amount)}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Forfaiting / Discounting Fee"}
                                        secondary={currencyFormatter.format(data?.profit_fee)}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Processing Fees"}
                                        secondary={currencyFormatter.format(data?.processing_fee)}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Documentation Fee"}
                                        secondary={currencyFormatter.format(data?.document_fee)}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Due Diligence Fee"}
                                        secondary={currencyFormatter.format(data?.due_diligence_fee)}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Account Setup Fee"}
                                        secondary={currencyFormatter.format(data?.account_setup_fee)}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <ListItemText
                                        primary={"Late Payment Fee"}
                                        secondary={currencyFormatter.format(data?.late_payment_fee)}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                }
            </DialogContent>
            <DialogActions sx={{p:2, justifyContent:"space-between"}}>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    target={"_blank"}
                    component={Link}
                    to={
                        data['service_type'] !== 'INVOICE_DISCOUNTING'
                            ? `/business/${business.selected.business_id}`
                            : `/invoices/${service?.invoice_id}`
                    }
                >
                    {data['service_type'] !== 'INVOICE_DISCOUNTING' ? 'Show Business' : 'Show Invoice'}
                </Button>
                <Stack direction={"row"} spacing={2}>
                    <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                    <ActionButton />
                </Stack>
            </DialogActions>
            { response && <Toastr response={response} /> }
        </>
    );
}