import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { fetchLoanDocuments } from "../../../Common/Documents";
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload,
});

export const setSelectedLoan = (payload) => ({
    type: CONST.SET_SELECTED_LOAN,
    payload: payload
})

/****************************************************
 * Fetch Loan
 * *************************************************/
export const fetchLoanSuccess = (payload) => ({
    type: CONST.FETCH_LOAN_SUCCESS,
    payload: payload
})

export const fetchLoanFailed = (payload) => ({
    type: CONST.FETCH_LOAN_FAILED,
    payload: payload
})

export const fetchLoan = async ({ dispatch, loan_id }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/loan/' + loan_id),
        [fetchLoanSuccess, fetchLoanDocuments],
        [fetchLoanFailed]
    );
}

/****************************************************
 * Fetch Loans
 * *************************************************/
export const fetchLoansSuccess = (payload) => ({
    type: CONST.FETCH_LOANS_SUCCESS,
    payload: payload
})

export const fetchLoansFailed = (payload) => ({
    type: CONST.FETCH_LOANS_FAILED,
    payload: payload
})

export const fetchLoans = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/loan', { params: data }),
        [fetchLoansSuccess],
        [fetchLoansFailed]
    );
}

/****************************************************
 * Fetch Existing Loans
 * *************************************************/
export const fetchExistingLoans = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/loan/available-for-merging', { params: data }),
        [fetchLoansSuccess],
        [fetchLoansFailed]
    );
}

/****************************************************
 * Add Loan
 * *************************************************/
export const addLoanSuccess = (payload) => ({
    type: CONST.ADD_LOAN_SUCCESS,
    payload: payload
})

export const addLoanFailed = (payload) => ({
    type: CONST.ADD_LOAN_FAILED,
    payload: payload
})

export const addLoan = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/loan', data),
        [addLoanSuccess],
        [addLoanFailed]
    );
}

/****************************************************
 * Verify Loan
****************************************************/
export const verifyLoanRequestSuccess = (payload) => ({
    type: CONST.VERIFY_LOAN_SUCCESS,
    payload: payload
})
export const verifyLoanRequestFailed = (payload) => ({
    type: CONST.VERIFY_LOAN_FAILED,
    payload: payload
})
export const verifyLoanRequest = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/loan/' + data['loan_id'] + '/verify', data),
        [verifyLoanRequestSuccess],
        [verifyLoanRequestFailed]
    );
}

/****************************************************
 * Return Loan
 ***************************************************/
export const returnLoanRequestSuccess = (payload) => ({
    type: CONST.RETURN_LOAN_SUCCESS,
    payload: payload
})

export const returnLoanRequestFailed = (payload) => ({
    type: CONST.RETURN_LOAN_FAILED,
    payload: payload
})

export const returnLoanRequest = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/loan/' + data['loan_id'] + '/return', data),
        [returnLoanRequestSuccess],
        [returnLoanRequestFailed]
    );
}

/****************************************************
 * Document Loan
 ***************************************************/
export const documentLoanSuccess = (payload) => ({
    type: CONST.DOCUMENT_LOAN_SUCCESS,
    payload: payload
})

export const documentLoanFailed = (payload) => ({
    type: CONST.DOCUMENT_LOAN_FAILED,
    payload: payload
})

export const documentLoan = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/loan/' + data['loan_id'] + '/document', data),
        [documentLoanSuccess],
        [documentLoanFailed]
    );
}

/****************************************************
 * Disburse Loan
 ***************************************************/
export const disbursedSuccess = (payload) => ({
    type: CONST.DISBURSEMENT_SUCCESS,
    payload: payload
})

export const disbursedFailed = (payload) => ({
    type: CONST.DISBURSEMENT_FAILED,
    payload: payload
})

export const disbursed = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/loan/' + data['loan_id'] + '/disburse', data),
        [disbursedSuccess],
        [disbursedFailed]
    );
}

export const updateLoanStatusSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SUCCESS,
    payload: payload
})

export const updateLoanStatusFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_FAILED,
    payload: payload
})

export const updateLoanStatus = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('loan/' + data['loan_id'], data),
        [updateLoanStatusSuccess],
        [updateLoanStatusFailed]
    );
}

/****************************************************
 * Restructure Loan
 ***************************************************/
export const restructureSuccess = (payload) => ({
    type: CONST.RESTRUCTURE_LOAN_SUCCESS,
    payload: payload
})

export const restructureFailed = (payload) => ({
    type: CONST.RESTRUCTURE_LOAN_FAILED,
    payload: payload
})

export const restructure = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/loan/' + data['loan_id'] + '/restructure', data),
        [restructureSuccess],
        [restructureFailed]
    );
}


/****************************************************
 * Early Settlement Loan Statement
***************************************************/

export const loanStatement = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/loan/' + data['loan_id'] + '/statement', { params: data }),
        [],
        []
    );
}

/****************************************************
 *  Early Settlement Loan
***************************************************/
export const earlySettlementSuccess = (payload) => ({
    type: CONST.UPDATE_LOAN_SUCCESS,
    payload: payload
})

export const earlySettlementFailed = (payload) => ({
    type: CONST.UPDATE_LOAN_FAILED,
    payload: payload
})

export const earlySettlement = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/loan/' + data['loan_id'] + '/early-settlement', data),
        [earlySettlementSuccess],
        [earlySettlementFailed]
    );
}

/****************************************************
 * Send Email
 ***************************************************/
export const sendCustomEmail = ({dispatch, data}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('send-email', data),
        [],
        []
    );
}