import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate } from "react-router-dom";
import {fetchServices, setConfig} from "../../Redux/Action";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import {isBrowser} from "react-device-detect";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {setModal} from "../../../../Common/App";
import {DeleteServiceModal} from "../Modals/DeleteServiceModal";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";

export const ServiceTable = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector((store) => store.services);
    const filters = useSelector((store) => store.services?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.services?.config?.pagination);
    const [isLoading, setIsLoading] = React.useState(true);
    const [response, setResponse] = React.useState(null);

    useEffect(() => {
        fetchData();
    },[pagination]);

    const fetchData = async() => {

        await fetchServices({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
        });
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/funding-requests/' + id);
        },
        [],);

    const viewLoan = React.useCallback(
        (id) => async () => {
            navigate('/business-finance/' + id);
        },
    [],);

    const handleDelete = React.useCallback(
        (id) => async () => {
            dispatch(setModal({component: <DeleteServiceModal service_id={id} setResponse={setResponse} />, open: true, fullScreen: false}));
        },
    [],);

    const columns = React.useMemo(
        () => [

            { field: 'service_no', align:'center', headerAlign:"center", headerName:'S No', type: 'string', width: 70 },
            { field: 'loan_no', align:'center', headerAlign:"center", headerName:'C No', type: 'string', width: 70,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewLoan(params.row?.loan?.loan_id)}>{params.row?.loan?.loan_no}</Typography>
                }
            },
            { field: 'service_type', headerName:'Service Type', type: 'string', minWidth: 220, flex:1,
                valueGetter: (params) => {
                    return params.row?.service_type?.name;
                }
            },
            { field: 'business_name', headerName:'Business', type: 'string', minWidth:250, flex: 1,
                valueGetter: (params) => {
                    return params.row?.business?.name;
                }
            },
            { field: 'amount', headerName:'Amount', type: 'number', flex:1, minWidth:100, valueFormatter: ({ value }) => currencyFormatter.format(value) },
            { field: 'repayment_period', align:'center', headerAlign:"center", headerName:'Period', type: 'string', flex:1, minWidth: 80,
                valueGetter: (params) => {
                    return params.row?.loan?.repayment_period;
                }
            },
            { field: 'repayment_frequency', align:'center', headerAlign:"center", headerName:'Frequency', type: 'string', flex:1, minWidth: 90,
                valueGetter: (params) => {
                    return params.row?.loan?.repayment_frequency;
                }
            },
            { field: 'Assigned To', headerName:'Assigned To', type: 'string', flex:1, minWidth: 200,
                valueGetter: (params) => {
                    return params.row?.assigned_to?.email;
                }
            },
            { field: 'status', align:'center', headerAlign:"center", headerName:'Status', type: 'string', flex:1, minWidth:120,
                renderCell: (params) => {

                    const status = {
                        'DRAFT': 'warning',
                        'ACTIVE':'primary',
                        'PAID':'success',
                        'DOCUMENTED':'info',
                        'OVERDUE':'error',
                        'DEFAULT':'error',
                        'CANCELLED':'error',
                        'DELETED':'secondary',
                        'VERIFIED':'success',
                        'RETURNED':'warning',
                        'REJECTED':'error',
                        'CLOSED':'secondary',
                    }
                    return(<StatusBall
                                variant="contained"
                                sx={{fontWeight:'normal'}}
                                label={params.value?.name}
                                color={status[params.value?.name]}
                            />)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Details',
                width: 100,
                getActions: (params) => [

                        <GridActionsCellItem
                            icon={<DeleteIcon sx={{zIndex:-1}} />}
                            label="Delete"
                            onClick={handleDelete(params.id)}
                        />
                    ,
                        <GridActionsCellItem
                            icon={<FindInPageIcon />}
                            label="View"
                            onClick={ viewItem(params.id) }
                        />
                    ,
                ],
            },
        ],
        [],
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.service_id}
                    columns={columns}
                    rows={items.list}
                    sx={{ border:'none'}}
                    pagination
                    paginationMode="server"
                    paginationModel={pagination}
                    rowCount={items?.config?.rowCount ?? 0}
                    pageSizeOptions={[5, 10, 25, 100]}
                    loading={isLoading}
                    onPaginationModelChange={(params) => !isLoading && (setIsLoading(true) || dispatch(setConfig({ pagination: { ...pagination, page: params.page, pageSize: params.pageSize } })))}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'start_date', sort: 'desc' }],
                        },
                        pinnedColumns: { right: isBrowser ?  ['actions'] : [] },
                        columns: {
                            columnVisibilityModel: {
                            }
                        },
                        pagination: { paginationModel: { ...pagination } },
                    }}
                />
            </>
        );
}
