import React, {useEffect} from 'react';
import {IconButton, Stack, Button, Typography} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {styled} from "@mui/material/styles";
import dayjs from "dayjs";
import moment from "moment";

export const DateRangeSelectorDirect = ({dates, setDates}) => {

    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState([null, null]);
    const [goDisabled, setGoDisabled] = React.useState(true);
    const [formattedDates, setFormattedDates] = React.useState([null, null]);

    useEffect(() => {
        if (dates.length === 2) {
            setSelectedDate([dayjs(dates[0]), dayjs(dates[1])]); // Set preselected dates from props
        }
    }, [dates]);

    const handleDateChange = (newDates) => {
        setSelectedDate(newDates);
        if (newDates[0] && newDates[1]) {
            const formattedDates = newDates.map(date => date.format('YYYY-MM-DD'));
            setFormattedDates(formattedDates);
            //setDates(formattedDates);
        }
        setGoDisabled(!(newDates[0] && newDates[1]));
    };

    const handleGoBtnClick = () => {
        setDates(formattedDates);
        setShowDatePicker(false)
    }

    const StyledPicker = styled(DateRangePicker)(({ theme }) => ({
        '& .MuiTextField-root': {
            '& .MuiOutlinedInput-root': {
                padding:'3px',
                '&.Mui-focused': {
                    borderBottomColor: theme.palette.primary.main,
                },
            },
        },
        '& .MuiMultiInputDateRangeField-separator': {
            display: 'none',
        }
    }));

    const StyledButton = styled(Button)(({ theme, disabled }) => ({
        backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.main,
        color: disabled ? theme.palette.action.disabled : theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: disabled ? theme.palette.action.disabledBackground : theme.palette.primary.dark,
        },
    }));

    return (
         showDatePicker ? (
             <Stack spacing={2} direction={"row"}>
                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <StyledPicker
                        size="small"
                        value={selectedDate}
                        variant="static"
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"
                        localeText={{ start: 'From Date', end: 'To Date' }}
                        InputProps={{
                        startAdornment: <IconButton size="small"><CalendarMonthIcon /></IconButton>,
                        }}
                     />
                 </LocalizationProvider>
                 <StyledButton disabled={goDisabled} size={"small"} variant={"outlined"} onClick={handleGoBtnClick}>Go</StyledButton>
             </Stack>
        ) : (
             <Stack spacing={2} direction={"row"} alignItems={"center"}>
                 <Typography>{moment(dates[0]).format('DD/MM/YYYY')} - {moment(dates[1]).format('DD/MM/YYYY')}</Typography>
                <IconButton onClick={() => setShowDatePicker(true)} size={"small"}><CalendarMonthIcon /></IconButton>
             </Stack>
        )
    );
};
