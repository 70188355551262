import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import {
    Stack, Typography, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, Tab, Tabs, IconButton, Collapse, Box
} from "@mui/material";
import { useDispatch } from "react-redux";
import { currencyFormatter } from "../../../../../Setup/Config";
import moment from "moment/moment";
import { fetchFundDetails } from "../../Redux/Action";
import {useNavigate} from "react-router-dom";
import {styled, useTheme} from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import PageviewIcon from "@mui/icons-material/Pageview";

export const FundExpandDetails = ({ view, data, setPanelHeight }) => {

    const dispatch = useDispatch();
    const panelRef = useRef(null);
    const [month, year] = [data.month, data.year];
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [transaction_type, setTransactionType] = useState(['DEBIT']);
    const [tab, setTab] = useState(0);
    const [realized, setRealized] = useState(1);
    const [expandedRows, setExpandedRows] = useState({});
    const [pagination, setPagination] = useState({
        current_page: 0,
        pageSize: 10,
        total: 0,
        from: 0,
        to: 0,
        last_page: 0,
        data: []
    });

    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage, tab]);

    const fetchData = async (page, rowsPerPage) => {
        setIsLoading(true);
        const res = await fetchFundDetails({ dispatch, filters: { transaction_type, realized, view, month: month + '-' + year, page, pageSize: rowsPerPage } });
        if (res['success']) {
            setPagination(res.data.data);
            setItems(res.data.data.data);
        }
        setIsLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleTabChange = (event, newTab) => {
        setIsLoading(true);
        setTab(newTab);
        setRealized(newTab === 2 ? 0 : 1);
        setTransactionType(newTab === 0 ? ['DEBIT'] : ['CREDIT']);
        setPage(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useLayoutEffect(() => {
        if (panelRef.current) {
            setPanelHeight(panelRef.current.scrollHeight);
        }
    }, [items, expandedRows]);

    // Toggle the expand/collapse for a row
    const handleToggleRow = (businessId) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [businessId]: !prevExpandedRows[businessId],
        }));
    };

    const FundTabs = styled(Tabs)(({ theme }) => ({
        '& .MuiButtonBase-root': {
            textTransform:'capitalize',
        }
    }));

    const renderView = () => {
        if (view === 'default') {
            return (
                <DefaultView
                    items={items}
                    pagination={pagination}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    tab={tab}
                />
            );
        } else {
            return (
                <BusinessView
                    items={items}
                    pagination={pagination}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    tab={tab}
                    month={month + '-' + year}
                    expandedRows={expandedRows}
                    onToggleRow={handleToggleRow}
                    transaction_type={transaction_type}
                    realized={realized}
                />
            );
        }
    };

    return (
        <Stack ref={panelRef}
               sx={{ height: 'auto', boxSizing: 'border-box' }}
               direction="column"
        >
            <Paper variant="outlined" square sx={{ flex: 1, mx: 'auto', width: '100%', p: 1, backgroundColor: '#efefef' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isLoading ? (
                            <Typography>Loading...</Typography>
                        ) : (
                            <>
                                <FundTabs value={tab} onChange={handleTabChange}>
                                    <Tab label="Funded" />
                                    <Tab label="Receipt" />
                                    <Tab label="Outstanding" />
                                </FundTabs>

                                {items.length === 0 ? (
                                    <Typography>No data found</Typography>
                                ) : (
                                    renderView()  // Call the view rendering function
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

export const DefaultView = ({ items, pagination, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, tab }) => {

    const theme = useTheme();
    const navigate = useNavigate();

    const paymentTypeFormatter = (type) => {
        return type.replace(/_/g, ' ').toLowerCase();
    }

    const StyledTable = styled(Table)(({ theme }) => ({
        // Grouped styles for the header
        '& thead': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '350px',
                textAlign: 'left',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(4)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(5)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(6)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(7)': {
                width: '80px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(8)': {
                width: '130px',
                textAlign: 'right',
                padding: '8px 16px !important',
            },
        },
        // Grouped styles for the body
        '& tbody': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '90px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '200px',
                textAlign: 'left',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(4)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(5)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(6)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(7)': {
                width: '80px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(8)': {
                width: '130px',
                textAlign: 'right',
                padding: '8px 16px !important',
            },
        }
    }));

    return (
        <TableContainer component={Paper}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell>{tab === 2 ? 'Due Date' : 'Payment Date'}</TableCell>
                        <TableCell>Business Name</TableCell>
                        <TableCell>Contract No</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>Tenor</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        tab === 0 ?
                            (
                                <TableRow key={row.account_id}>
                                    <TableCell>{moment(row.start_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>
                                        <Typography
                                            onClick={() => navigate("/dashboard/" + row.business_id)}
                                            sx={{
                                                fontSize: '0.86rem',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: theme.text.link
                                            }}>
                                            {row.business.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            onClick={() => navigate("/business-finance/" + row.loan_id)}
                                            sx={{
                                                fontSize: '0.86rem',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: theme.text.link
                                            }}>
                                            {row.loan_no}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{moment(row.start_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(row.end_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell sx={{textTransform: 'capitalize'}}>Principal</TableCell>
                                    <TableCell>{row.repayment_period + ' m'}</TableCell>
                                    <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                                </TableRow>
                            )
                            :
                            (
                                <TableRow key={row.account_id}>
                                    <TableCell>{moment(tab === 2 ? row.due_date : row.payment_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>
                                        <Typography
                                            onClick={() => navigate("/dashboard/" + row.business_id)}
                                            sx={{
                                                fontSize: '0.86rem',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: theme.text.link
                                            }}>
                                            {row.business.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            onClick={() => navigate("/business-finance/" + row.loan.loan_id)}
                                            sx={{
                                                fontSize: '0.86rem',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: theme.text.link
                                            }}>
                                            {row.loan.loan_no}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{moment(row.loan.start_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(row.loan.end_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell sx={{textTransform: 'capitalize'}}>
                                        {paymentTypeFormatter(row.account_type?.name)}
                                    </TableCell>
                                    <TableCell>{row.loan.repayment_period + ' m'}</TableCell>
                                    <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                                </TableRow>
                            )
                    ))}
                </TableBody>
            </StyledTable>

            <TablePagination
                component="div"
                count={pagination.total}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
            />
        </TableContainer>
    );
};

export const BusinessView = ({ items, pagination, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, tab, expandedRows, onToggleRow, month, transaction_type, realized }) => {

    const theme = useTheme();
    const navigate = useNavigate();

    const StyledTable = styled(Table)(({ theme }) => ({
        '& thead': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '80px',
                textAlign: 'center',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '400px',
                textAlign: 'left',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: 'auto',
                textAlign: 'right',
            },
        },
        '& tbody': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '80px',
                textAlign: 'center',
                padding:'0px 16px'
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '400px',
                textAlign: 'left',
                padding:'0px 16px !important'
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: 'auto',
                textAlign: 'right',
                padding:'0px 16px !important'
            },
        },
        '& .loan-details': {
            background:'#efefef',
            '& .MuiTablePagination-root': {
               justifyContent:'center',
            },
            '& .MuiTableCell-root': {
                padding: '0!important',
            },
        }
    }));

    return (
        <TableContainer component={Paper}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Business Name</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <React.Fragment key={row.business_id}>
                            <TableRow>
                                <TableCell >
                                    <IconButton onClick={() => onToggleRow(row.business_id)}>
                                        {expandedRows[row.business_id] ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        onClick={() => navigate("/dashboard/" + row.business_id)}
                                        sx={{
                                            fontSize: '0.86rem',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                            color: theme.text.link
                                        }}>
                                        {row.business.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                            </TableRow>
                            <TableRow className={"loan-details"}>
                                <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                    <Collapse in={expandedRows[row.business_id]} timeout="auto" unmountOnExit>
                                        <DetailsTable business_id={row.business_id} month={month} tab={tab} transaction_type={transaction_type} realized={realized} />
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </StyledTable>

            <TablePagination
                component="div"
                count={pagination.total}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
            />
        </TableContainer>
    );
};

const DetailsTable = ({ business_id, month, tab, transaction_type, realized }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [pagination, setPagination] = useState({
        current_page: 0,
        pageSize: 10,
        total: 0,
        from: 0,
        to: 0,
        last_page: 0,
        data: []
    });

    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const fetchData = async (page, rowsPerPage) => {
        setIsLoading(true);
        const res = await fetchFundDetails({ dispatch, filters: { view:'default', business_id, transaction_type, realized, month: month, page, pageSize: rowsPerPage } });
        if (res['success']) {
            setPagination(res.data.data);
            setItems(res.data.data.data);
        }
        setIsLoading(false);
    };

    const paymentTypeFormatter = (type) => {
        return type.replace(/_/g, ' ').toLowerCase();
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledTable = styled(Table)(({ theme }) => ({
        // Grouped styles for the header
        '& thead': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '80px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '120px',
                textAlign: 'left',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(4)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(5)': {
                width: '120px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(6)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(7)': {
                width: '80px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(8)': {
                width: '130px',
                textAlign: 'right',
                padding: '8px 16px !important',
            },
        },
        // Grouped styles for the body
        '& tbody': {
            '& .MuiTableCell-root:nth-child(1)': {
                width: '80px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(2)': {
                width: '120px',
                textAlign: 'left',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(3)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(4)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(5)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(6)': {
                width: '150px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(7)': {
                width: '80px',
                textAlign: 'center',
                padding: '8px 16px !important',
            },
            '& .MuiTableCell-root:nth-child(8)': {
                width: '130px',
                textAlign: 'right',
                padding: '8px 16px !important',
            },
        },
        '& .MuiTablePagination-root': {
            overflow:'inherit'
        }
    }));

    return (
        <TableContainer>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell>Contract No</TableCell>
                        <TableCell>{tab === 2 ? 'Due Date' : 'Payment Date'}</TableCell> {/* Set width for column */}
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>Tenor</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {items.map((row) => (
                    tab === 0 ? (
                        <TableRow key={row.account_id}>
                            <TableCell>
                                <Typography
                                    onClick={() => navigate("/business-finance/" + row.loan_id)}
                                    sx={{
                                        fontSize: '0.86rem',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        color: theme.text.link
                                    }}>
                                    {row.loan_no}
                                </Typography>
                            </TableCell>
                            <TableCell>{moment(row.start_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{moment(row.start_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{moment(row.end_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell sx={{textTransform: 'capitalize'}}>Principal</TableCell>
                            <TableCell>{row.repayment_period + ' m'}</TableCell>
                            <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => navigate("/business-finance/" + row.loan_id)}>
                                    <PageviewIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ):(
                        <TableRow key={row.account_id}>
                            <TableCell>
                                <Typography
                                    onClick={() => navigate("/business-finance/" + row.loan.loan_id)}
                                    sx={{
                                        fontSize: '0.86rem',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        color: theme.text.link
                                    }}>
                                    {row?.loan?.loan_no}
                                </Typography>
                            </TableCell>
                            <TableCell>{moment(tab === 2 ? row.due_date : row.payment_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{moment(row.loan.start_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{moment(row.loan.end_date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell sx={{textTransform: 'capitalize'}}>
                                {paymentTypeFormatter(row.account_type?.name)}
                            </TableCell>
                            <TableCell>{row.loan.repayment_period + ' m'}</TableCell>
                            <TableCell>{currencyFormatter.format(row.amount)}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => navigate("/business-finance/" + row.loan_id)}>
                                    <PageviewIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                ))}
                </TableBody>
                <TablePagination
                    component="div"
                    count={pagination.total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                />
            </StyledTable>
        </TableContainer>
    )
}