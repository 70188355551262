const CONST = {
    'CLEAR_DATA': 'STATEMENTS/CLEAR_DATA',
    'SET_FUNDED_CONFIG': 'STATEMENTS/SET_FUNDED_CONFIG',
    'SET_OVERDUE_CONFIG': 'STATEMENTS/SET_OVERDUE_CONFIG',
    'SET_OUTSTANDING_CONFIG': 'STATEMENTS/SET_OUTSTANDING_CONFIG',
    'SET_EARNING_CONFIG': 'STATEMENTS/SET_EARNING_CONFIG',

    'FETCH_FUND_STATEMENT_SUCCESS': 'STATEMENTS/FETCH_FUND_STATEMENT_SUCCESS',
    'FETCH_FUND_STATEMENT_FAILED': 'STATEMENTS/FETCH_FUND_STATEMENT_FAILED',

    'FETCH_FUNDED_STATEMENT_SUCCESS': 'STATEMENTS/FETCH_FUNDED_STATEMENT_SUCCESS',
    'FETCH_FUNDED_STATEMENT_FAILED': 'STATEMENTS/FETCH_FUNDED_STATEMENT_FAILED',
    'FETCH_OVERDUE_STATEMENT_SUCCESS': 'STATEMENTS/FETCH_OVERDUE_STATEMENT_SUCCESS',
    'FETCH_OVERDUE_STATEMENT_FAILED': 'STATEMENTS/FETCH_OVERDUE_STATEMENT_FAILED',
    'FETCH_OUTSTANDING_STATEMENT_SUCCESS': 'STATEMENTS/FETCH_OUTSTANDING_STATEMENT_SUCCESS',
    'FETCH_OUTSTANDING_STATEMENT_FAILED': 'STATEMENTS/FETCH_OUTSTANDING_STATEMENT_FAILED',
    'FETCH_EARNING_STATEMENT_SUCCESS': 'STATEMENTS/FETCH_EARNING_STATEMENT_SUCCESS',
    'FETCH_EARNING_STATEMENT_FAILED': 'STATEMENTS/FETCH_EARNING_STATEMENT_FAILED',
}

export default CONST;