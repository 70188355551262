import * as React from 'react';
import { useDispatch } from "react-redux";
import { loanStatement, earlySettlement } from "../../Redux/Action";
import {
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    TextField, FormControl, InputLabel, Select, FormHelperText
} from "@mui/material";
import {useEffect, useState} from "react";
import { setModal } from "../../../../Common/App";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import { styled } from "@mui/material/styles";
import {currencyFormatter} from "../../../../../Setup/Config";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

export const EarlySettlementLoanModal = ({ loan_id }) => {

    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({
        settlement_date: null,
        remaining_tenor: 0,
        settlement_fee: 0,
        txn_no: "",
        cheque_no: "",
        company_bank_id: "",
        payment_method: "",
        loan_id
    });
    const [response, setResponse] = useState({});
    const [isReview, setIsReview] = useState(true); // Track whether we're in "Review" state

    useEffect(() => {
        setIsReview(true); // Reset to "Review" when date changes
        setDisabled(false);
    },[data['settlement_date'], data['remaining_tenor'], data['settlement_fee']]);

    const handleLoanStatement = async () => {
        setDisabled(true);
        await loanStatement({ dispatch, data }).then(res => {
            if (res['success']) {
                setIsReview(false); // Move to "Settle" state
                setDisabled(false); // Enable the button again
            }
            setResponse(res);
        });
    };

    const handleEarlySettlement = async () => {

        setDisabled(true);

        await earlySettlement({ dispatch, data }).then(res => {
            if (res['success']) {
                dispatch(setModal({ component: null, open: false }));
            }
        });
    };

    const handleChange = (e) => {
        setDisabled(false);
        setData({...data, [e.target.name]: e.target.value});
    }

    const StyledList = styled(List)(({ theme }) => ({
        '& .MuiListItem-root': {
            background: '#f9f9f9',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1),
            display: 'flex', // Use Flexbox to align items in one row
            justifyContent: 'space-between', // Space between primary and secondary text
            alignItems: 'center', // Center items vertically
            padding: theme.spacing(1, 2), // Adjust padding as needed
            '&:last-child': {
                marginBottom: 0,
            },
            '&.highlight': {
                border: `1px solid ${theme.palette.success.main}`,
            },
            '& .MuiListItemText-root': {
                display: 'flex', // Flexbox for the text container
                flexDirection: 'row', // Ensure primary and secondary text are in a row
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%', // Full width for flexbox to work
                '& .MuiListItemText-primary': {
                    fontWeight: 'normal',
                    fontSize: '.85rem',
                    color: '#666',
                    marginRight: theme.spacing(2), // Space between primary and secondary text
                },
                '& .MuiListItemText-secondary': {
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    color: '#000',
                    marginBottom: 0,
                }
            }
        }
    }));


    return (
        <>
            <DialogTitle>Early Settlement</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MuiDatepicker
                            error={response?.error?.data?.errors?.settlement_date}
                            setDisabled={setDisabled}
                            label={"Settlement Date"}
                            name="settlement_date"
                            data={data}
                            state={setData}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Remaining Tenor"}
                            name="remaining_tenor"
                            defaultValue={data?.remaining_tenor}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.remaining_tenor}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Settlement Fees"}
                            name="settlement_fee"
                            defaultValue={data?.settlement_fee}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.settlement_fee}
                            onChange={handleChange}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        { !isReview && (
                            <StyledList>
                                <ListItem>
                                    <ListItemText primary={"Contract Amount"} secondary={ currencyFormatter.format(response?.data?.data?.loan_amount)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Paid Amount"} secondary={currencyFormatter.format(response?.data?.data?.paid)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Balance Amount"} secondary={currencyFormatter.format(response?.data?.data?.balance)} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={"Profit Paid"} secondary={currencyFormatter.format(response?.data?.data?.profit_fee)} />
                                </ListItem>
                                <ListItem className={"highlight"}>
                                    <ListItemText primary={"Refund Profit"} secondary={currencyFormatter.format(response?.data?.data?.refund)} />
                                </ListItem>
                            </StyledList>
                        )}
                    </Grid>
                    { !isReview && (
                        <Grid item xs={12}>
                            <Divider>Settlement Details</Divider>
                        </Grid>
                    )}
                    { !isReview && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Cheque No"}
                            name="cheque_no"
                            defaultValue={data?.cheque_no}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.cheque_no}
                            onChange={handleChange}
                        />
                    </Grid>
                    )}
                    { !isReview && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={"Transaction/Receipt No"}
                            name="txn_no"
                            defaultValue={data?.txn_no}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.txn_no}
                            onChange={handleChange}
                        />
                    </Grid>
                    )}
                    { !isReview && (
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="label-payment-type">Payment Method</InputLabel>
                            <Select
                                name="payment_method"
                                label="Payment Method"
                                value={data?.payment_method ?? ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem key={"BANK_TRANSFER"} value={"BANK_TRANSFER"}>Bank Transfer</MenuItem>
                                <MenuItem key={"CASH"} value={"CASH"}>Cash</MenuItem>
                                <MenuItem key={"CHEQUE"} value={"CHEQUE"}>Cheque</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.payment_method}</FormHelperText>
                        </FormControl>
                    </Grid>
                    )}
                    { !isReview && (
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="label-payment-type">Received In</InputLabel>
                            <Select
                                className={['BANK_TRANSFER','CHEQUE'].includes(data?.payment_method) ? 'highlight' : ''}
                                name="company_bank_id"
                                label="Received In"
                                value={data?.company_bank_id ?? ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem key={"01had8m0d1vd0ah3s52yv3w61q"} value={"01had8m0d1vd0ah3s52yv3w61q"}>CBD</MenuItem>
                                <MenuItem key={"01had8m0d1vd0ah3s52yv3w61w"} value={"01had8m0d1vd0ah3s52yv3w61w"}>ENBD</MenuItem>
                                <MenuItem key={"01had8m0d1vd0ah3s52yv3w62y"} value={"01had8m0d1vd0ah3s52yv3w62y"}>GIB</MenuItem>
                            </Select>
                            <FormHelperText>{response?.error?.data?.errors?.company_bank_id}</FormHelperText>
                        </FormControl>
                    </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(setModal({ component: null, open: false }))}>Cancel</Button>
                <Button
                    disabled={disabled}
                    variant={"contained"}
                    color={'primary'}
                    onClick={isReview ? handleLoanStatement : handleEarlySettlement}
                >
                    {isReview ? "Review" : "Settle"}
                </Button>
            </DialogActions>
        </>
    );
};
