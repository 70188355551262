import React from 'react'
import {
    CardContent, CardHeader, Grid, Stack,
    ToggleButton, ToggleButtonGroup
} from "@mui/material";
import {Breadcrumbs, Panel} from "../../../../Components";
import {DisbursementCollectionChart} from "./Charts/DisbursementCollectionChart";
import {CombinedStatementTable} from "./Tables/CombinedStatementTable";
import {DateRangeSelectorDirect} from "./Popover/DateRangeSelectorDirect";
import moment from "moment";
import styled from "@mui/material/styles/styled";

export const Main = () => {

    const [dates, setDates] = React.useState([
        moment().subtract(6, 'months').format('YYYY-MM-DD'),  // 6 months ago
        moment().add(6, 'months').format('YYYY-MM-DD')        // 6 months in the future
    ]);

    const [view, setView] = React.useState('default');

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '& .MuiToggleButtonGroup-grouped': {
            border: '1px solid transparent',
            '&.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                },
            },
            '&:not(.Mui-selected)': {
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.text.primary,
                '&:hover': {
                    backgroundColor: theme.palette.action.selected,
                },
            },
            '&:first-of-type': {
                borderTopLeftRadius: '20px',  // Adjust the border-radius as needed
                borderBottomLeftRadius: '20px',  // Adjust the border-radius as needed
            },
            '&:last-of-type': {
                borderTopRightRadius: '20px',  // Adjust the border-radius as needed
                borderBottomRightRadius: '20px',  // Adjust the border-radius as needed
            },
        },
    }));

    return (
        <>
            <Grid container spacing={2} elevated={3}>
                <Grid item xs={12}>
                    <Stack justifyContent={"space-between"} spacing={2} direction={"row"}>
                        <Breadcrumbs items={'DASHBOARD/FUND_STATEMENT'} />
                        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                            {/*<DateRangeSelectorDirect setDates={setDates} />*/}
                            <StyledToggleButtonGroup
                                value={view}
                                exclusive
                                onChange={(_, newView) => setView(newView || 'default')}
                                aria-label="view toggle"
                            >
                                <ToggleButton value="default" aria-label="Fixed Slots">
                                    Default
                                </ToggleButton>
                                <ToggleButton value="business" aria-label="Custom Slots">
                                    Business-wise
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <DisbursementCollectionChart dates={dates} setDates={setDates} />
                </Grid>

                <Grid item xs={12}>
                    <Panel>
                        <CardHeader
                            title={"Disbursements & Collections"}
                        />
                        <CardContent>
                            <CombinedStatementTable dates={dates} view={view} />
                        </CardContent>
                    </Panel>
                </Grid>
            </Grid>
        </>
    );
}
