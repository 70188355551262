import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { fetchBusinessDocuments } from "../../../Common/Documents/index";
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";


/****************************************
 SET BUSINESS CONFIG
 ****************************************/
export const setConfig = (payload) => ({
    type: CONST.SET_CONFIG,
    payload: payload
});

export const resetFilter = (payload) => ({
    type: CONST.RESET_FILTER,
    payload: payload
});

export const setSelectedBusiness = (payload) => ({
    type: CONST.SET_SELECTED_BUSINESS,
    payload: payload
})

export const clearData = () => ({
    type: CONST.CLEAR_DATA,
})

/****************************************
 FETCH BUSINESS
 ****************************************/
export const fetchBusinessSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_SUCCESS,
    payload: payload
})

export const fetchBusinessFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_FAILED,
    payload: payload
})

export const fetchBusiness = async ({dispatch, id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/' + id),
        [fetchBusinessSuccess, fetchBusinessDocuments],
        [fetchBusinessFailed]
    );
}


/****************************************
 FETCH BUSINESSES
 ****************************************/
export const fetchBusinessesSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESSES_SUCCESS,
    payload: payload
})

export const fetchBusinessesFailed = (payload) => ({
    type: CONST.FETCH_BUSINESSES_FAILED,
    payload: payload
})

export const fetchBusinesses = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business', {params: data}),
        [fetchBusinessesSuccess],
        [fetchBusinessesFailed]
    );
}

/****************************************
 * Fetch DEFAULT Businesses
 ****************************************/
export const fetchDefaultBusinesses = ({dispatch}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/default'),
        [fetchBusinessesSuccess],
        [fetchBusinessesFailed]
    )
};


/****************************************
 FETCH BUSINESSES
 ****************************************/
export const fetchQuickListSuccess = (payload) => ({
    type: CONST.FETCH_BUSINESS_QUICKLIST_SUCCESS,
    payload: payload
})

export const fetchQuickListFailed = (payload) => ({
    type: CONST.FETCH_BUSINESS_QUICKLIST_FAILED,
    payload: payload
})

export const fetchQuickList = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/quickList', {params: data}),
        [fetchQuickListSuccess],
        [fetchQuickListFailed]
    );
}


/****************************************
 ADD Business
 ****************************************/
export const addBusinessSuccess = (payload) => ({
    type: CONST.ADD_BUSINESS_SUCCESS,
    payload: payload
})

export const addBusinessFailed = (payload) => ({
    type: CONST.ADD_BUSINESS_FAILED,
    payload: payload
})

export const addBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/business', data),
        [addBusinessSuccess],
        [addBusinessFailed]
    );
}


/****************************************
 Update Business - Details
 ****************************************/
export const updateBusinessSuccess = (payload) => ({
    type: CONST.UPDATE_BUSINESS_SUCCESS,
    payload: payload
})

export const updateBusinessFailed = (payload) => ({
    type: CONST.UPDATE_BUSINESS_FAILED,
    payload: payload
})

export const updateBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'], data),
        [updateBusinessSuccess],
        [updateBusinessFailed]
    );
}

/****************************************
 Return a Business
 ****************************************/
export const returnBusinessSuccess = (payload) => ({
    type: CONST.RETURN_BUSINESS_SUCCESS,
    payload: payload
})

export const returnBusinessFailed = (payload) => ({
    type: CONST.RETURN_BUSINESS_FAILED,
    payload: payload
})

export const returnBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/return', data),
        [returnBusinessSuccess],
        [returnBusinessFailed]
    );
}



/****************************************
 Verify a Business
 ****************************************/
export const verifyBusinessSuccess = (payload) => ({
    type: CONST.VERIFY_BUSINESS_SUCCESS,
    payload: payload
})

export const verifyBusinessFailed = (payload) => ({
    type: CONST.VERIFY_BUSINESS_FAILED,
    payload: payload
})

export const verifyBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/verify', data),
        [verifyBusinessSuccess],
        [verifyBusinessFailed]
    );
}

/****************************************
 Business Head Approval
 ****************************************/
export const businessHeadApprovalSuccess = (payload) => ({
    type: CONST.BH_APPROVE_BUSINESS_SUCCESS,
    payload: payload
})

export const businessHeadApprovalFailed = (payload) => ({
    type: CONST.BH_APPROVE_BUSINESS_FAILED,
    payload: payload
})

export const businessHeadApproval = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/bh_approve', data),
        [businessHeadApprovalSuccess],
        [businessHeadApprovalFailed]
    );
}

/****************************************
 Credit Review of a Business
 ****************************************/
export const creditReviewSuccess = (payload) => ({
    type: CONST.CREDIT_REVIEW_BUSINESS_SUCCESS,
    payload: payload
})

export const creditReviewFailed = (payload) => ({
    type: CONST.CREDIT_REVIEW_BUSINESS_FAILED,
    payload: payload
})

export const creditReview = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/credit_review', data),
        [creditReviewSuccess],
        [creditReviewFailed]
    );
}

/****************************************
 Credit Approve of a Business
 ****************************************/
export const creditApproveSuccess = (payload) => ({
    type: CONST.CREDIT_APPROVE_BUSINESS_SUCCESS,
    payload: payload
})

export const creditApproveFailed = (payload) => ({
    type: CONST.CREDIT_APPROVE_BUSINESS_FAILED,
    payload: payload
})

export const creditApprove = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/credit_approve', data),
        [creditApproveSuccess],
        [creditApproveFailed]
    );
}

/****************************************
 CEO Approve of a Business
 ****************************************/
export const ceoApproveSuccess = (payload) => ({
    type: CONST.CEO_APPROVE_BUSINESS_SUCCESS,
    payload: payload
})

export const ceoApproveFailed = (payload) => ({
    type: CONST.CEO_APPROVE_BUSINESS_FAILED,
    payload: payload
})

export const ceoApprove = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/ceo_approve', data),
        [ceoApproveSuccess],
        [ceoApproveFailed]
    );
}

/****************************************
 CEO Approve of a Business
 ****************************************/
export const bodApproveSuccess = (payload) => ({
    type: CONST.BOD_APPROVE_BUSINESS_SUCCESS,
    payload: payload
})

export const bodApproveFailed = (payload) => ({
    type: CONST.BOD_APPROVE_BUSINESS_FAILED,
    payload: payload
})

export const bodApprove = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/bod_approve', data),
        [bodApproveSuccess],
        [bodApproveFailed]
    );
}

/****************************************
 Reject a Business
 ****************************************/
export const rejectBusinessSuccess = (payload) => ({
    type: CONST.REJECT_BUSINESS_SUCCESS,
    payload: payload
})

export const rejectBusinessFailed = (payload) => ({
    type: CONST.REJECT_BUSINESS_FAILED,
    payload: payload
})

export const rejectBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/decline', data),
        [rejectBusinessSuccess],
        [rejectBusinessFailed]
    );
}



/****************************************
 Reopen a Business
 ****************************************/
export const reopenBusinessSuccess = (payload) => ({
    type: CONST.REJECT_BUSINESS_SUCCESS,
    payload: payload
})

export const reopenBusinessFailed = (payload) => ({
    type: CONST.REJECT_BUSINESS_FAILED,
    payload: payload
})

export const reopenBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/reopen', data),
        [reopenBusinessSuccess],
        [reopenBusinessFailed]
    );
}

/****************************************
 Chairman Approval of a Business
 ****************************************/
export const approveBusinessSuccess = (payload) => ({
    type: CONST.APPROVE_BUSINESS_SUCCESS,
    payload: payload
})

export const approveBusinessFailed = (payload) => ({
    type: CONST.APPROVE_BUSINESS_FAILED,
    payload: payload
})

export const approveBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/approve', data),
        [approveBusinessSuccess],
        [approveBusinessFailed]
    );
}

/****************************************
 DELETE Business
 ****************************************/
export const deleteBusinessSuccess = (payload) => ({
    type: CONST.DELETE_BUSINESS_SUCCESS,
    payload: payload
})

export const deleteBusinessFailed = (payload) => ({
    type: CONST.DELETE_BUSINESS_FAILED,
    payload: payload
})

export const deleteBusiness = async ({ dispatch, data }) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.delete('corporate/business/' + data['business_id']),
        [deleteBusinessSuccess],
        [deleteBusinessFailed]
    );
}


/****************************************
 Submit Business - For Approval Request
 ****************************************/
export const updateBusinessStatusSuccess = (payload) => ({
    type: CONST.UPDATE_BUSINESS_SUCCESS,
    payload: payload
})

export const updateBusinessStatusFailed = (payload) => ({
    type: CONST.UPDATE_BUSINESS_FAILED,
    payload: payload
})

export const updateBusinessStatus = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.patch('corporate/business/' + data['business_id'] + '/submit', data),
        [updateBusinessStatusSuccess],
        [updateBusinessStatusFailed]
    );
}




/****************************************
 Export a Business
 ****************************************/
export const exportBusinessSuccess = (payload) => ({
    type: CONST.EXPORT_BUSINESS_SUCCESS,
    payload: payload
})

export const exportBusinessFailed = (payload) => ({
    type: CONST.EXPORT_BUSINESS_FAILED,
    payload: payload
})

export const exportBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/business/export', data),
        [exportBusinessSuccess, fetchBusinessDocuments],
        [exportBusinessFailed]
    );
}


/****************************************
 Assign BUSINESS
 ****************************************/
export const assignBusinessSuccess = (payload) => ({
    type: CONST.ASSIGN_BUSINESS_SUCCESS,
    payload: payload
})

export const assignBusinessFailed = (payload) => ({
    type: CONST.ASSIGN_BUSINESS_FAILED,
    payload: payload
})

export const assignBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/business/assign', data),
        [assignBusinessSuccess],
        [assignBusinessFailed]
    );
}


/****************************************
 Assign BUSINESS
 ****************************************/
export const relateBusinessSuccess = (payload) => ({
    type: CONST.RELATE_BUSINESS_SUCCESS,
    payload: payload
})

export const relateBusinessFailed = (payload) => ({
    type: CONST.RELATE_BUSINESS_FAILED,
    payload: payload
})

export const relateBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/business/relate', data),
        [relateBusinessSuccess],
        [relateBusinessFailed]
    );
}

/****************************************
 * Remove Related Business
****************************************/
export const unRelateBusinessSuccess = (payload) => ({
    type: CONST.UNRELATE_BUSINESS_SUCCESS,
    payload: payload
})

export const unRelateBusinessFailed = (payload) => ({
    type: CONST.UNRELATE_BUSINESS_FAILED,
    payload: payload
})

export const unRelateBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post('corporate/business/un-relate', data),
        [unRelateBusinessSuccess],
        [unRelateBusinessFailed]
    );
}



/****************************************
 * Remove Related Business
 ****************************************/
export const undoDefaultBusinessSuccess = (payload) => ({
    type: CONST.UPDATE_BUSINESS_SUCCESS,
    payload: payload
})

export const undoDefaultBusinessFailed = (payload) => ({
    type: CONST.UPDATE_BUSINESS_FAILED,
    payload: payload
})

export const undoDefaultBusiness = async ({dispatch, business_id}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.post(`corporate/business/${business_id}/undo-default`),
        [undoDefaultBusinessSuccess],
        [undoDefaultBusinessFailed]
    );
}
