import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    List,
} from "@mui/material";
import Button from "@mui/material/Button";
import {LoanDocumentForm} from "../Forms/LoanDocumentForm";
import { setModal } from "../../../../Common/App";
import {useDispatch, useSelector} from "react-redux";
import ListItem from "@mui/material/ListItem";
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import CsvIcon from '@mui/icons-material/GridOn';
import ImageIcon from '@mui/icons-material/Image';
import ExcelIcon from '@mui/icons-material/TableChart';
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import {removeDocument} from "../../../../Common/Documents";
import Typography from "@mui/material/Typography";

export default function LoanDocumentPanel(props) {

    const dispatch = useDispatch();
    //const documents = (useSelector((store) => store.documents.files)).filter(i => i.entity_name === 'loan' && i.entity_id === props.loan_id) || [];
    const documents = useSelector((store) => (store.documents.files && store.documents.files.filter(i => i.entity_name === 'loan' && i.entity_id === props.loan_id)) || []);

    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);
    const loan = useSelector((store) => store.loans.selected);

    const fileTypes = {
        "text/csv": <CsvIcon />,
        "application/pdf": <PdfIcon />,
        "image/png": <ImageIcon />,
        "image/jpeg": <ImageIcon />,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <ExcelIcon />,
        "application/vnd.ms-excel": <ExcelIcon />,
    };

    const DocumentList = styled(List)(({ theme }) => ({
        display:'flex',
        flexDirection:'column',
        '& .MuiListItem-root:lastChild': {
            borderBottom:'none',
        },
        '& .MuiListItem-root': {
            justifyContent:'space-between',
            padding: '8px!important',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',  // Aligns items vertically
            maxWidth: '100%',
            overflow: 'hidden',
            marginBottom: '1px',
            backgroundColor:'#f8f8f8',
            borderBottom:'1px solid #ddd',
            '& .MuiSvgIcon-root': {
                width: '20px',
            },
            '& a': {
                flex:1,
                fontSize: '12px',
                paddingLeft: '8px',
                paddingTop: '8px',
                paddingBottom: '8px',
                marginLeft: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
            },
            '& .MuiIconButton-root': {
                padding: '0px!important',
                marginLeft: '0',
            }
        }
    }));


    const handleRemoveImage = async (e) => {
        const document_id = e.currentTarget.dataset.id;
        await removeDocument({dispatch, props, document_id}).then((res) => {
            props.setResponse(res);
        });
    };

    return (
        <>
            <Card>
                <CardHeader
                    title="Documents"
                    titleTypographyProps={{ variant: 'headline' }}
                    action={
                        (['add-loan-documents'].some(i => permissions.includes(i) && loan.status?.name === 'VERIFIED') )
                        && <Button onClick={() => dispatch(setModal({component:<LoanDocumentForm />, open:true}))}>Add New</Button>}
                />
                <CardContent sx={{ padding: '0px!important' }}>
                    {documents.length > 0 ?
                    <DocumentList dense={false} sx={{ padding: 0 }}>
                        {documents.map((item, index) => (
                            <ListItem key={index}>
                                {fileTypes[item.doc_type] || <DescriptionIcon />}
                                <Link to={process.env.REACT_APP_BASE_URL + 'document/' + item.document_id} target="_blank" rel="noopener noreferrer">{item.doc_name}</Link>
                                {(['delete-loan-documents'].some(i => permissions.includes(i)) || auth.user.role_type === 'ADMIN') &&
                                    <IconButton onClick={handleRemoveImage} data-id={ item?.document_id } >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            </ListItem>
                        ))}
                    </DocumentList>
                        :
                        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', padding: '20px' }}>
                            No Documents Found
                        </Typography>
                    }
                </CardContent>
            </Card>
        </>
    );
}
