import React, {useEffect, useState} from 'react'
import {Breadcrumbs} from "../../../../Components";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {LoanTable} from "./Tables/LoanTable";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedBusiness} from "../../Business";
import {NewServiceRequestModal} from "../../Services/Components/Modals/NewServiceRequestModal";
import Button from "@mui/material/Button";
import { setModal } from "../../../Common/App";
import {setConfig} from "../Redux/Action";
import {TableActions} from "./Menus/TableActions";

export const Loan = () => {

    const dispatch = useDispatch();
    const business = useSelector(store => store.business.list);
    const auth = useSelector((store) => store.auth);
    const filters = useSelector((store) => store.loans?.config?.filters);
    const [configLoaded, setConfigLoaded] = useState(false);

    useEffect(() => {
        dispatch(setModal({ component:null, open: false}))
        setFilters(filters);
    }, []);

    const setFilters = async (filters) => {

        dispatch(setConfig({filters: {...filters, status: []} }))
        setConfigLoaded(true);
    }

    const handleClick = () => {
        dispatch(setSelectedBusiness(business[0].business_id));
        dispatch(setModal({component:<NewServiceRequestModal />, open:true})).then(() => {
            setConfigLoaded(true);
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={8} xs={6}>
                    <Breadcrumbs items={'DASHBOARD/BUSINESS_FINANCE'} />
                </Grid>
                <Grid item sm={4} xs={6} sx={{display:'grid'}}>
                    {(business.filter((value) => value.status === 'APPROVED').length > 0 && auth.user.role_type === "CLIENT")  && <Button size={"large"} onClick={handleClick} variant={"contained"} color={"primary"} sx={{justifySelf:'right'}}>New Request</Button>}
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader
                            title={"Forfaiting / Discounting Details"}
                            action={<TableActions />}
                        />
                        <CardContent sx={{p:0, pb:0}}>
                            {configLoaded ? <LoanTable /> : <p>Loading...</p>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}