import React, {useEffect, useRef} from "react";
import {
    Box, FormControl,
    Grid, List, ListItem, ListItemText, Stack, TextField,
} from "@mui/material";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import Toastr from "../../../../../Components/Elements/Toastr";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {addVatReturnWithDocument} from "../../../Business";
import {styled} from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Divider from "@mui/material/Divider";
import moment from "moment";

export const VatReturnForm = ({taskId}) => {

    const dispatch = useDispatch();
    const item = useSelector((store) => store.dashboard?.tasks.selected);
    const submitted = item.meta.submitted || {};
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [file, setFile] = useState(null); // New state for file
    const [showFile, setShowFile] = useState(false); // New state to toggle file display
    const fileInputRef = useRef(null);

    useEffect(() => {
        setData({
            period_from: item?.meta?.requested?.period_from,
            period_to: item?.meta?.requested?.period_to,
        })
    }, [item]);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setShowFile(true);
        }
    };

    const toggleShowFile = (event) => {
        event.stopPropagation();
        setShowFile(!showFile);
    };

    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setShowFile(false);
    };

    const detailsList = () => {
        return Object.entries(submitted)
            .filter(([key]) => !['business_id', 'entity_id', 'file', 'task_id', 'extendedPeriodTo', 'document_id', 'doc_expiry', 'type', 'entity_name', 'owner_id'].includes(key))
            .map(([key, value]) => {
                const displayKey = key === 'doc_name' ? 'Attached Document' : key.replace(/_/g, ' ');
                const secondaryValue = key === 'doc_name' ? (
                    <Typography component={Link} to={process.env.REACT_APP_BASE_URL + 'document/' + submitted?.document_id} target="_blank" rel="noopener">
                        {value}
                    </Typography>
                ) : value;

                return (
                    <ListItem key={key}>
                        <ListItemText primary={displayKey} secondary={secondaryValue} />
                    </ListItem>
                );
            });
    };

    const RemarksListItem = () => {

        return item?.remarks && (
            <ListItem key={"ABC"}>
                <ListItemText primary={"Remarks"} secondary={item?.remarks} />
            </ListItem>
        )

    }

    const handleSubmit = async () => {
        setDisabled(true);

        const payload = new FormData();
        payload.append('file', file);
        payload.append('task_id', taskId);
        Object.keys(data).forEach(key => {
            payload.append(key, data[key]);
        });

        await addVatReturnWithDocument({dispatch, data: payload}).then((res) => {
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component: null, open: false}));
            }
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component: null, open: false}));
    };

    const UploadButton = styled(Button)({
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: '1px dashed #aaa',
        borderRadius: '4px',
        height: '55px',
        '&:hover': {
            backgroundColor: '#EFEFEF',
        },
    });

    const SubmittedInfoBox = styled(Box)(({ theme }) => ({
        padding: theme.spacing(0),

        '& .MuiDivider-root': {
            margin: theme.spacing(2, 0),
        },
        '& .MuiTypography-h6': {
            fontSize: '14px',
            fontWeight:'bold',
        },
        '& .MuiList-root': {
            padding: 0,
            '& .MuiListItem-root': {
                padding: theme.spacing(0, 0, 1, 0),
                borderBottom: `1px solid ${theme.palette.divider}`,
                '& .MuiListItemText-primary': {
                    textTransform: 'capitalize',
                    color: theme.palette.text.dark,
                },
                '& .MuiListItemText-secondary': {
                    color: theme.palette.text.dark,
                    marginBottom:'0!important',
                }
            }
        },
    }));

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                <Typography>VAT Returns for last quarter</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/*<Grid item xs={12}>*/}
                    {/*    <MuiDatepicker error={response?.error?.data?.errors?.period_from} setDisabled={setDisabled} label={"Period From"} past={true} name="period_from" data={data} state={setData} />*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12}>*/}
                    {/*    <MuiDatepicker error={response?.error?.data?.errors?.period_to} setDisabled={setDisabled} label={"Period To"} past={true} name="period_to" data={data} state={setData} />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Period from"
                            defaultValue={moment(item?.meta?.requested?.period_from).format('DD/MM/YYYY') || ""}
                            inputlabelprops={{ shrink: true }}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Period to"
                            defaultValue={moment(item?.meta?.requested?.period_to).format('DD/MM/YYYY') || ""}
                            inputlabelprops={{ shrink: true }}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Sales Amount"
                            name="turnover_amount"
                            defaultValue={data?.turnover_amount}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.turnover_amount}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Expenses Amount"
                            name="expenses_amount"
                            defaultValue={data?.expenses_amount}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.expenses_amount}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="VAT Payable / (Refund) Amount"
                            name="vat_payable"
                            defaultValue={data?.vat_payable}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.vat_payable}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <MuiDatepicker error={response?.error?.data?.errors?.payment_date} setDisabled={setDisabled} label={"VAT Payment Date"} past={true} name="payment_date" data={data} state={setData} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Penalty Amount"
                            name="penalty_amount"
                            defaultValue={data?.penalty_amount}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.penalty_amount}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline={true}
                            rows={3}
                            label="VAT Penalty Reason"
                            name="vat_penalty_reason"
                            defaultValue={data?.vat_penalty_reason}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.vat_penalty_reason}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        {/* File Upload Component */}
                        <Stack spacing={1}>
                            <FormControl error={Boolean(!file || response?.error?.data?.errors?.file)}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <UploadButton size="large" component="span" onClick={openFilePicker}>
                                    <CloudUploadIcon />
                                    <Typography sx={{ fontSize: '11px', textTransform: 'Capitalize' }}>
                                        Supported Files: {"PDF ONLY"}
                                    </Typography>
                                    {file && (
                                        <Box onClick={toggleShowFile}>
                                            <Typography sx={{ fontSize: '12px' }}>{file.name}</Typography>
                                        </Box>
                                    )}
                                </UploadButton>
                                <Typography sx={{ fontSize: '11px', color: 'red' }}>
                                    {!file && "No file selected or file input is not available."}
                                    {response?.error?.data?.errors?.file && ` ${response.error.data.errors.file}`}
                                </Typography>
                            </FormControl>
                            {showFile && file && (
                                <Box>
                                    <FormControl sx={{ background: '#F7F7FF', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '65px', borderRadius: '4px', padding: '8px 15px', marginBottom: '8px', width: "100%" }}>
                                        <Typography sx={{ fontSize: '13px' }}>{file.name}<span style={{ fontSize: '11px', display: 'block' }}>{file.size} bytes</span></Typography>
                                        <Button onClick={handleRemoveFile} sx={{ padding: '0' }}>
                                            <DeleteForeverIcon sx={{ fontSize: '24px', color: '#990000' }} />
                                        </Button>
                                    </FormControl>
                                </Box>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    { Object.keys(submitted).length > 0 && (
                    <Grid item xs={12}>
                        <SubmittedInfoBox>
                            <Typography variant={"h6"}>Previously Submitted:</Typography>
                            <List>
                                {detailsList()}
                                {RemarksListItem()}
                            </List>
                        </SubmittedInfoBox>
                    </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}

