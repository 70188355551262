import CONST from './Constant';
import axiosPrivate from '../../../../Setup/Axios/SetupAxios';
import { handleAsyncAction } from "../../../../Setup/Axios/ApiHandlers";

export const setFundedConfig = (payload) => ({
    type: CONST.SET_FUNDED_CONFIG,
    payload,
});

export const setOverdueConfig = (payload) => ({
    type: CONST.SET_OVERDUE_CONFIG,
    payload,
});

export const setOutstandingConfig = (payload) => ({
    type: CONST.SET_OUTSTANDING_CONFIG,
    payload,
});

export const setEarningConfig = (payload) => ({
    type: CONST.SET_EARNING_CONFIG,
    payload,
});

/************************************************************
 *  Fetch Fund Stats
 *************************************************************/
export const fetchAccountStats = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/account/fund-stats`, {params: filters}),
        [],
        []
    );
}

/************************************************************
 *  Fetch Fund Statement
 *************************************************************/
export const fetchFundStatementSuccess = (payload) => ({
    type: CONST.FETCH_FUND_STATEMENT_SUCCESS,
    payload: payload
});

export const fetchFundStatementFailed = (payload) => ({
    type: CONST.FETCH_FUND_STATEMENT_FAILED,
    payload: payload
});

export const fetchFundStatement = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/account/fund-statement`, {params: filters}),
        [fetchFundStatementSuccess],
        [fetchFundStatementFailed]
    );
}

/************************************************************
 *  Fetch Fund Details
 *************************************************************/
export const fetchFundDetails = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/account/fund-statement-by-month/`,{params: filters}),
        [],
        []
    );
}


/****************************************
 FETCH BUSINESS HAVING FUNDING
 ****************************************/
export const fetchFundedBusinessSuccess = (payload) => ({
    type: CONST.FETCH_FUNDED_STATEMENT_SUCCESS,
    payload: payload
})

export const fetchFundedBusinessFailed = (payload) => ({
    type: CONST.FETCH_FUNDED_STATEMENT_FAILED,
    payload: payload
})

export const fetchFundedBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/funded', { params: data }),
        [fetchFundedBusinessSuccess],
        [fetchFundedBusinessFailed]
    );
}


/************************************************************
 *  Fetch Funded Details
 *************************************************************/
export const fetchFundedDetails = ({dispatch, filters}) => {

    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get(`corporate/loan`,{params: filters}),
        [],
        []
    );
}

/****************************************
 FETCH BUSINESS HAVING EARNING
 ****************************************/
export const fetchEarningBusinessSuccess = (payload) => ({
    type: CONST.FETCH_EARNING_STATEMENT_SUCCESS,
    payload: payload
})

export const fetchEarningBusinessFailed = (payload) => ({
    type: CONST.FETCH_EARNING_STATEMENT_FAILED,
    payload: payload
})

export const fetchEarningBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/earning', { params: data }),
        [fetchEarningBusinessSuccess],
        [fetchEarningBusinessFailed]
    );
}

/****************************************
 FETCH BUSINESS HAVING OVERDUE
 ****************************************/
export const fetchOverdueBusinessSuccess = (payload) => ({
    type: CONST.FETCH_OVERDUE_STATEMENT_SUCCESS,
    payload: payload
})

export const fetchOverdueBusinessFailed = (payload) => ({
    type: CONST.FETCH_OVERDUE_STATEMENT_FAILED,
    payload: payload
})

export const fetchOverdueBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/overdue', { params: data }),
        [fetchOverdueBusinessSuccess],
        [fetchOverdueBusinessFailed]
    );
}

/****************************************
 FETCH BUSINESS HAVING FUNDING
 ****************************************/
export const fetchOutstandingBusinessSuccess = (payload) => ({
    type: CONST.FETCH_OUTSTANDING_STATEMENT_SUCCESS,
    payload: payload
})

export const fetchOutstandingBusinessFailed = (payload) => ({
    type: CONST.FETCH_OUTSTANDING_STATEMENT_FAILED,
    payload: payload
})

export const fetchOutstandingBusiness = async ({dispatch, data}) => {
    return handleAsyncAction(
        dispatch,
        () => axiosPrivate.get('corporate/business/outstanding', { params: data }),
        [fetchOutstandingBusinessSuccess],
        [fetchOutstandingBusinessFailed]
    );
}