import React, {useEffect} from 'react'
import { Tooltip,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import {GridActionsCellItem} from "@mui/x-data-grid";
import PageviewIcon from "@mui/icons-material/Pageview";
import {fetchBusinessOverview, setBusinessOverviewConfig, setUpcomingConfig} from "../../index";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {useNavigate} from "react-router-dom";
import {currencyFormatter} from "../../../../../Setup/Config";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import {fetchBusiness} from "../../../../Corporate/Business";
import moment from "moment/moment";
import {BusinessExpandDetailsCard} from "../Cards/BusinessExpandDetailsCard";

export const BusinessOverviewTable = ({pageSizeOptions}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const auth = useSelector((store) => store.auth);
    const businessOverview = useSelector((store) => store.dashboard?.businessOverview);
    const filters = useSelector((store) => store.dashboard?.businessOverview?.config?.filters);
    const pagination = useDeepEqualSelector((store) => store.dashboard?.businessOverview?.config?.pagination);
    const [response, setResponse] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [panelHeight, setPanelHeight] = React.useState(0);

    useEffect(() => {
        fetchData();
    },[pagination])

    const fetchData = async () => {

        setIsLoading(true);
        await fetchBusinessOverview({dispatch, data:{...filters, ...pagination}}).then((res) => {
            setIsLoading(false);
            setResponse(res);
        })
    }

    const viewItem = React.useCallback(
        (id) => async () => {
            navigate('/business/' + id);
        },
        [],
    );

    const viewLoan = React.useCallback(
        (id) => async () => {
            navigate('/business-finance/' + id);
        },
        [],
    );

    const viewDashboard = React.useCallback(
        (id) => async () => {
            await fetchBusiness({dispatch, id});
            navigate(`/dashboard/${id}`)
        },
        [],
    );

    const getDetailPanelContent = React.useCallback(
        ({ row }) => (row.related && row.related.length > 0)
            ? <BusinessExpandDetailsCard data={row} setPanelHeight={setPanelHeight} />
            : null,
        [setPanelHeight],
    );

    const getDetailPanelHeight = React.useCallback(() => panelHeight, [panelHeight]);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} />
                ),
            },
            {
                field: 'rowNumber',
                headerName: 'S No',
                align:'center',
                headerAlign:'center',
                width: 70,
                renderCell: (params) => {
                    const apiRef = params.api;
                    const page = apiRef.state.pagination.paginationModel.page;
                    const pageSize = apiRef.state.pagination.paginationModel.pageSize;
                    return page * pageSize + (params.api.getAllRowIds().indexOf(params.id) + 1);
                },
            },
            { field: 'name', headerName:'Business Name', type: 'string', flex: 1, minWidth: 350,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewItem(params.id)}>{params.value}</Typography>
                }
            },
            { field:'registration_date', headerName:'Registration', align:'center', headerAlign:'center', type: 'string', minWidth: 100, flex:1,
                valueFormatter: ({ value }) =>  value && moment(value).format("DD/MM/YYYY")
            },
            { field:'latest_loan_date', headerName:'Last Funding', align:'center', headerAlign:'center', type: 'string', minWidth: 100, flex:1,
                renderCell: (params) => {
                    return <Typography sx={{fontSize:'0.86rem', cursor:'pointer', textDecoration:'none', color: theme.text.link}} onClick={viewLoan(params.row.loan_id)}>{params.value && moment(params.value).format("DD/MM/YYYY")}</Typography>
                }
            },
            { field:'total_funding', headerName:'Total Funding', type: 'number', minWidth: 120, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.total_funding ?? 0);
                },
            },
            { field:'current_funding', headerName:'Current Contracts', type: 'number', minWidth: 150, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.current_funding ?? 0);
                },
            },
            { field:'outstanding', headerName:'Outstanding', type: 'number', minWidth: 100, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.outstanding ?? 0);
                },
            },
            { field:'earning', headerName:'Earning', type: 'number', minWidth: 100, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.earning ?? 0);
                },
            },
            { field:'overdue', headerName:'Overdue', type: 'number', minWidth: 120, flex:1,
                valueGetter: (params) => {
                    return currencyFormatter.format(params.row?.stats?.overdue ?? 0);
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                width:70,
                getActions: (params) => {
                    if(['APPROVED','DEFAULT'].includes(params.row.status?.name)){
                        return [
                            <Tooltip title={"Show Details"}>
                                <GridActionsCellItem
                                    icon={<PageviewIcon sx={{zIndex:-1}} />}
                                    label={"View"}
                                    color={"primary"}
                                    onClick={ viewDashboard(params.id) }
                                />
                            </Tooltip>
                        ];
                    }
                    return [];
                },
            },

        ],
        [],
    );

    const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
        '& .MuiDataGrid-virtualScrollerRenderZone .defaulted': {
            boxShadow: `inset 3px 0 0 0 ${theme.palette.error.main}`,
            background: `${theme.palette.error.light}`,
        },
    }));

    return (
        <>
            <StyledDataGridPro
                autoHeight
                getRowId={(row) => row.business_id}
                columns={columns}
                rows={businessOverview?.list || []}
                sx={{ border:'none', height:'100%'}}
                rowThreshold={0}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                pagination
                paginationMode="server"
                paginationModel={pagination}
                rowCount={businessOverview?.config?.rowCount ?? 0}
                pageSizeOptions={pageSizeOptions}
                loading={isLoading}
                onPaginationModelChange={(params) => !isLoading && (setIsLoading(true) || dispatch(setBusinessOverviewConfig({ pagination: { ...pagination, page: params.page, pageSize: params.pageSize } })))}
                initialState={{
                    pinnedColumns: { right: ['actions'] },
                    sorting: {
                        sortModel: [{ field: 'overdue', sort: 'desc' }],
                    },
                    columns:{
                        columnVisibilityModel:{
                            business: auth.user.role_type !== 'CLIENT',
                        }
                    },
                    pagination: { paginationModel: { ...pagination } },
                }}
                getRowClassName={(params) =>
                    params.row.status?.name === 'DEFAULT' ? 'defaulted' : ''
                }
            />
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        hasDetail && (
            <IconButton
                size="small"
                tabIndex={-1}
                aria-label={isExpanded ? 'Close' : 'Open'}
                color={"primary"}
            >
                <ExpandMoreIcon
                    sx={{
                        transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                        transition: (theme) =>
                            theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest,
                            }),
                    }}
                    fontSize="inherit"
                />
            </IconButton>
        )
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
