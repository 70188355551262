import React from "react";
import {
    Grid, Stack, TextField,
} from "@mui/material";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { setModal } from "../../../../Common/App";
import {setSelectedFinancial, updateFinancial} from "../../index";
import MuiDatepicker from "../Datepicker";
import Toastr from "../../../../../Components/Elements/Toastr";
import AttachDoc from "../../../../Common/Documents/Components/AttachDoc";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from "@mui/material/styles/styled";

export const FinancialForm = () => {

    const dispatch = useDispatch();
    const business = useSelector((store) => store.business);
    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});

    useEffect(() => {
        setData(business.financials.selected);
    }, [])

    const handleChange = (e) => {
        setDisabled(false);
        const { name, value, type, checked } = e.target;
        let newValue = value;

        // Handle the switch case for is_audited
        if (name === 'is_audited') {
            newValue = checked ? 1 : 0;

            // If audited, set auditor_name to "Management Report", else clear it
            setData(data => ({
                ...data,
                auditor_name: checked ? '' : 'Management Report',
            }));
        }

        setData(data => ({
            ...data,
            [name]: type === 'checkbox' ? newValue : value,
        }));
    };

    const handleSubmit = async () => {
        setDisabled(true);
        await updateFinancial({dispatch, data}).then((res)=>{
            setResponse(res);
            if(res['success']) {
                closeAndClearForm();
            }
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component:null, open:false}));
        dispatch(setSelectedFinancial(null))
    };

    const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
        '& .income':{
            '& .MuiInputBase-root':{
                background: '#fbffed',
            }
        },
        '& .liablity':{
            '& .MuiInputBase-root':{
                background: '#fff4ed',
            }
        },
        '& .assets':{
            '& .MuiInputBase-root':{
                background: '#edfaff',
            }
        }
    }));

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Financial Performance
            </DialogTitle>
            <StyledDialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        { (data !== undefined && Object.keys(data).length > 0) &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <MuiDatepicker error={business?.error?.financial_year} past={true} setDisabled={setDisabled} label={"Financial Period Ending"} name="financial_year" data={data} state={setData} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <AttachDoc multiple={false} setResponse={setResponse} updateFunction={updateFinancial} allowedFileType={['pdf','xls','xlsx']} onBlur={handleChange} label={"Audited Financial/Management Report"} entity_name={"financials"} entity_id={data?.id} name="financial_report_doc" data={data} state={setData} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                checked={data.is_audited ? 1 : 0}
                                                onChange={handleChange}
                                                name="is_audited"
                                                color="primary"
                                            />
                                        }
                                        label="Audited"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Auditor Name"
                                        name="auditor_name"
                                        defaultValue={data?.auditor_name || "Management Report"}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.auditor_name}
                                        onChange={handleChange}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"income"}
                                        fullWidth
                                        label="Total Revenue"
                                        name="total_revenue"
                                        defaultValue={data?.total_revenue}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.total_revenue}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Fixed Assets"
                                        name="fixed_assets"
                                        defaultValue={data?.fixed_assets}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.fixed_assets}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"income"}
                                        fullWidth
                                        label="Gross Profit"
                                        name="gross_profit"
                                        defaultValue={data?.gross_profit}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.gross_profit}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Inventory"
                                        name="stock"
                                        defaultValue={data?.stock}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.stock}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"income"}
                                        fullWidth
                                        label="Net Profit"
                                        name="net_profit"
                                        defaultValue={data?.net_profit}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.net_profit}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Trade Receivable"
                                        name="trade_receivable"
                                        defaultValue={data?.trade_receivable}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.trade_receivable}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"liablity"}
                                        fullWidth
                                        label="Equity"
                                        name="equity"
                                        defaultValue={data?.equity}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.equity}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Other Receivable"
                                        name="other_receivable"
                                        defaultValue={data?.other_receivable}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.other_receivable}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"liablity"}
                                        fullWidth
                                        label="Loan Liability"
                                        name="loan_liability"
                                        defaultValue={data?.loan_liability}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.loan_liability}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Bank Balance"
                                        name="bank_balance"
                                        defaultValue={data?.bank_balance}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.bank_balance}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"liablity"}
                                        fullWidth
                                        label="Trade Payable"
                                        name="trade_payable"
                                        defaultValue={data?.trade_payable}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.trade_payable}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Cash Balance"
                                        name="cash_balance"
                                        defaultValue={data?.cash_balance}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.cash_balance}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"liablity"}
                                        fullWidth
                                        label="Other Payable"
                                        name="other_payable"
                                        defaultValue={data?.other_payable}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.other_payable}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={"assets"}
                                        fullWidth
                                        label="Staff Salary"
                                        name="staff_salary"
                                        defaultValue={data?.staff_salary}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.staff_salary}
                                        onBlur={handleChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Remarks"
                                        name="remarks"
                                        defaultValue={data?.remarks}
                                        inputlabelprops={{ shrink: true }}
                                        helperText={business?.error?.remarks}
                                        onBlur={handleChange} />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </StyledDialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}

