import React from 'react';
import {
    CardContent,
    CardHeader,
    List as ListUI,
    ListItem, ListItemText as ListItemTextUI,
    ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Panel } from "../../../../../Components";
import { useSelector } from "react-redux";
import {currencyFormatter} from "../../../../../Setup/Config";
import {useNavigate} from "react-router-dom";

export const BusinessStatsPanel = () => {

    const navigate = useNavigate();
    const stats = useSelector(state => state.dashboard.stats);

    const List = styled(ListUI)({
        '& .MuiListItem-root':{
            paddingTop:'6px!important',
            paddingBottom:'6px!important',
            borderBottom:'1px dashed #ddd',
            cursor:'pointer',
            '&:hover':{
                backgroundColor:'#f5f5f5',
            }
        },
        '& .MuiListItem-root:last-child':{
            borderBottom:'none',
        },
        '& .MuiListItemText-root':{
            display:'flex',
            marginTop:'6px!important',
            marginBottom:'6px!important',
            justifyContent:'space-between',
            '& span':{
                fontSize:14,
                fontWeight:'normal',
            },
            '& p':{
                fontSize:14,
                fontWeight:'normal',
                marginBottom:'2px!important',
            }
        }
    })

    const ListItemText = styled(ListItemTextUI)(({ theme, secondary }) => ({
        '& span': {
            color: '#444444',
        },
        '& p': {
            padding:'0.15rem 0.3rem',
            borderRadius:'0.15rem',
            minWidth:'25px',
            textAlign:'center',
            color: '#222222',
        },
    }));

    return (
        <Panel>
            <CardHeader
                title="Financial Summary"
            />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Funded"
                            secondary={ currencyFormatter.format(stats?.amount_funded ?? 0) }
                            onClick={() => navigate("/fund-statement/funded")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Earnings"
                            secondary={ currencyFormatter.format(stats?.income ?? 0) }
                            onClick={() => navigate("/fund-statement/earnings")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Outstanding"
                            secondary={ currencyFormatter.format(stats?.outstanding ?? 0) }
                            onClick={() => navigate("/fund-statement/outstanding")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="OverDue"
                            secondary={ currencyFormatter.format(stats?.overdue ?? 0) }
                            onClick={() => navigate("/fund-statement/overdue")}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Default"
                            secondary={ currencyFormatter.format(stats?.default ?? 0) }
                            onClick={() => navigate("/fund-statement/default")}
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Panel>
    )
}
