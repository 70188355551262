import React, {useEffect, useRef} from "react";
import {
    Grid, Stack, TextField, FormControl, Typography, Box, Button, List, ListItem, ListItemText
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { setModal } from "../../../../Common/App";
import Toastr from "../../../../../Components/Elements/Toastr";
import MuiDatepicker from "../../../Business/Components/Datepicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { styled } from "@mui/material/styles";
import { addFinancialWithDocument } from "../../../Business";
import {Link} from "react-router-dom";
import Divider from "@mui/material/Divider";
import moment from "moment";

export const FinancialForm = ({taskId}) => {

    const dispatch = useDispatch();
    const item = useSelector((store) => store.dashboard?.tasks.selected);
    const submitted = item.meta.submitted || {};
    const [data, setData] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [response, setResponse] = useState({});
    const [file, setFile] = useState(null); // New state for file
    const [showFile, setShowFile] = useState(false); // New state to toggle file display
    const fileInputRef = useRef(null);

    useEffect(() => {
        setData({
            financial_year: item?.meta?.requested?.lastDateOfPreviousYear || "",
        });
    }, [item]);

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setShowFile(true);
        }
    };

    const toggleShowFile = (event) => {
        event.stopPropagation();
        setShowFile(!showFile);
    };
    
    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setShowFile(false);
    };

    const detailsList = () => {
        return Object.entries(submitted)
            .filter(([key]) => !['business_id', 'entity_id', 'document_id', 'doc_expiry', 'type', 'entity_name', 'owner_id'].includes(key))
            .map(([key, value]) => {
                const displayKey = key === 'doc_name' ? 'Attached Document' : key.replace(/_/g, ' ');
                const secondaryValue = key === 'doc_name' ? (
                    <Typography component={Link} to={process.env.REACT_APP_BASE_URL + 'document/' + submitted?.document_id} target="_blank" rel="noopener">
                        {value}
                    </Typography>
                ) : value;

                return (
                    <ListItem key={key}>
                        <ListItemText primary={displayKey} secondary={secondaryValue} />
                    </ListItem>
                );
            });
    };

    const RemarksListItem = () => {

        return item?.remarks && (
            <ListItem key={"ABC"}>
                <ListItemText primary={"Remarks"} secondary={item?.remarks} />
            </ListItem>
        )
    }

    const handleSubmit = async () => {
        setDisabled(true);

        const payload = new FormData();
        payload.append('file', file);
        payload.append('task_id', taskId);
        Object.keys(data).forEach(key => {
            payload.append(key, data[key]);
        });

        await addFinancialWithDocument({dispatch, data: payload}).then((res) => {
            setResponse(res);
            if(res['success']){
                dispatch(setModal({component: null, open: false}));
            }
        });
    };

    const closeAndClearForm = async () => {
        dispatch(setModal({component: null, open: false}));
    };

    const UploadButton = styled(Button)({
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: '1px dashed #aaa',
        borderRadius: '4px',
        height: '55px',
        '&:hover': {
            backgroundColor: '#EFEFEF',
        },
    });

    const SubmittedInfoBox = styled(Box)(({ theme }) => ({
        padding: theme.spacing(0),

        '& .MuiDivider-root': {
            margin: theme.spacing(2, 0),
        },
        '& .MuiTypography-h6': {
            fontSize: '14px',
            fontWeight:'bold',
        },
        '& .MuiList-root': {
            padding: 0,
            '& .MuiListItem-root': {
                padding: theme.spacing(0, 0, 1, 0),
                borderBottom: `1px solid ${theme.palette.divider}`,
                '& .MuiListItemText-primary': {
                    textTransform: 'capitalize',
                    color: theme.palette.text.dark,
                },
                '& .MuiListItemText-secondary': {
                    color: theme.palette.text.dark,
                    marginBottom:'0!important',
                }
            }
        },
    }));

    return(
        <>
            <DialogTitle id="responsive-dialog-title">
                Financial Performance
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Auditor Name"
                            name="auditor_name"
                            defaultValue={data?.auditor_name}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.auditor_name}
                            onChange={handleChange}
                            onBlur={handleChange} />
                    </Grid>

                    {/*<Grid item xs={12}>*/}
                    {/*    <MuiDatepicker error={response?.error?.data?.errors?.financial_year} past={true} setDisabled={setDisabled} label={"Financial Year Ending"} name="financial_year" data={data} state={setData} />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Financial Year"
                            defaultValue={moment(item?.meta?.requested?.lastDateOfPreviousYear).format("YYYY") || ""}
                            inputlabelprops={{ shrink: true }}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {/* File Upload Component */}
                        <Stack spacing={1}>
                            <FormControl error={Boolean(!file || response?.error?.data?.errors?.file)}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <UploadButton size="large" component="span" onClick={openFilePicker}>
                                    <CloudUploadIcon />
                                    <Typography sx={{ fontSize: '11px', textTransform: 'Capitalize' }}>
                                        Supported Files: {"PDF ONLY"}
                                    </Typography>
                                    {file && (
                                        <Box onClick={toggleShowFile}>
                                            <Typography sx={{ fontSize: '12px' }}>{file.name}</Typography>
                                        </Box>
                                    )}
                                </UploadButton>
                                <Typography sx={{ fontSize: '11px', color: 'red' }}>
                                    {!file && "No file selected or file input is not available."}
                                    {response?.error?.data?.errors?.file && ` ${response.error.data.errors.file}`}
                                </Typography>
                            </FormControl>
                            {showFile && file && (
                                <Box>
                                    <FormControl sx={{ background: '#F7F7FF', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '65px', borderRadius: '4px', padding: '8px 15px', marginBottom: '8px', width: "100%" }}>
                                        <Typography sx={{ fontSize: '13px' }}>{file.name}<span style={{ fontSize: '11px', display: 'block' }}>{file.size} bytes</span></Typography>
                                        <Button onClick={handleRemoveFile} sx={{ padding: '0' }}>
                                            <DeleteForeverIcon sx={{ fontSize: '24px', color: '#990000' }} />
                                        </Button>
                                    </FormControl>
                                </Box>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Total Revenue"
                            name="total_revenue"
                            defaultValue={data?.total_revenue}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.total_revenue}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Gross Profit"
                            name="gross_profit"
                            defaultValue={data?.gross_profit}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.gross_profit}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Staff Salary"
                            name="staff_salary"
                            defaultValue={data?.staff_salary}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.staff_salary}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Net Profit"
                            name="net_profit"
                            defaultValue={data?.net_profit}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.net_profit}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Equity"
                            name="equity"
                            defaultValue={data?.equity}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.equity}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Loan Liability"
                            name="loan_liability"
                            defaultValue={data?.loan_liability}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.loan_liability}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Trade Receivable"
                            name="trade_receivable"
                            defaultValue={data?.trade_receivable}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.trade_receivable}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Other Receivable"
                            name="other_receivable"
                            defaultValue={data?.other_receivable}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.other_receivable}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Trade Payable"
                            name="trade_payable"
                            defaultValue={data?.trade_payable}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.trade_payable}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Other Payable"
                            name="other_payable"
                            defaultValue={data?.other_payable}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.other_payable}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Stock"
                            name="stock"
                            defaultValue={data?.stock}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.stock}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Fixed Assets"
                            name="fixed_assets"
                            defaultValue={data?.fixed_assets}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.fixed_assets}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Bank Balance"
                            name="bank_balance"
                            defaultValue={data?.bank_balance}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.bank_balance}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Cash Balance"
                            name="cash_balance"
                            defaultValue={data?.cash_balance}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.cash_balance}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Remarks"
                            name="remarks"
                            defaultValue={data?.remarks}
                            inputlabelprops={{ shrink: true }}
                            helperText={response?.error?.data?.errors?.remarks}
                            onBlur={handleChange} />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    { Object.keys(submitted).length > 0 && (
                    <Grid item xs={12}>
                        <SubmittedInfoBox>
                            <Typography variant={"h6"}>Previously Submitted:</Typography>
                            <List>
                                {detailsList()}
                                {RemarksListItem()}
                            </List>
                        </SubmittedInfoBox>
                    </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={'warning'}  onClick={closeAndClearForm}>Close</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Submit</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}

