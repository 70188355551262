import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import {setSelectedAccount, AccountDetailsModal, PaymentExtensionModal} from "../../index";
import moment from "moment";
import {currencyFormatter} from "../../../../../Setup/Config";
import {setConfig} from "../../../Accounts";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";
import Toastr from "../../../../../Components/Elements/Toastr";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import {setModal} from "../../../../Common/App";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import {AccountExpandDetails} from "../Cards/AccountExpandDetails";
import {IconButton} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

export const AccountsTable = ({ isLoading, selectedRows, setSelectedRows }) => {

    const dispatch = useDispatch();
    const items = useSelector(state => state.accounts);
    const [response, setResponse] = React.useState({});
    const pagination = useDeepEqualSelector((store) => store.accounts.config.pagination);
    const permissions = useSelector((store) => store.app.permissions);
    const [panelHeight, setPanelHeight] = React.useState(0);

    const viewItem = React.useCallback(
        (id) => async () => {
            dispatch(setSelectedAccount(id));
            dispatch(setModal({component: <AccountDetailsModal />, open: true}));
        }
    ,[]);

    const extendPayment = React.useCallback(
        (id) => async () => {
            dispatch(setSelectedAccount(id));
            dispatch(setModal({component: <PaymentExtensionModal />, open: true}));
        }
    ,[]);

    const columns = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
                <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
            ),
        },
        { field: 'business_name', headerName: 'Business Name', type: 'string', flex:1, minWidth:280,
            valueGetter: (params) => {
                return params.row?.business?.name;
            }
        },
        { field: 'account_type', headerName: 'For', type: 'string', flex: 1, maxWidth: 220,
            valueOptions: ['FEES', 'REPAYMENT', 'PAYMENT', 'PROFIT_FEE', 'PROCESSING_FEE', 'ADJUSTMENT', 'DOCUMENT_FEE', 'DUE_DILIGENCE_FEE', 'ACCOUNT_SETUP_FEE', 'OVERDUE_FEE', 'EXTENSION_FEE', 'CLUBBED_FEE'],
            renderCell: (params) => {
                const status = {
                    'PROFIT_FEE': { color: 'success', name: 'Profit' },
                    'PROCESSING_FEE': { color: 'success', name: 'Processing' },
                    'DOCUMENT_FEE': { color: 'success', name: 'Document' },
                    'DUE_DILIGENCE_FEE': { color: 'success', name: 'Due Diligence' },
                    'ACCOUNT_SETUP_FEE': { color: 'success', name: 'Account Setup' },
                    'REPAYMENT': { color: 'primary', name: 'Repayment' },
                    'PAYMENT': { color: 'info', name: 'Payment' },
                    'OVERDUE_FEE': { color: 'success', name: 'Overdue' },
                    'EXTENSION_FEE': { color: 'success', name: 'Extension' },
                    'CLUBBED_FEE': { color: 'success', name: 'Clubbed' },
                    'SETTLEMENT_FEE': { color: 'success', name: 'Settlement Fee' },
                    'ADJUSTMENT': { color: 'info', name: 'Adjustment' },
                    'REFUND': { color: 'info', name: 'Refund' },
                };
                return (
                    <StatusBall
                        variant="contained"
                        label={status[params.value?.name]?.name}
                        color={status[params.value?.name]?.color}
                    />
                );
            },
        },
        { field: 'due_date', headerName: 'Due Date', headerAlign:'center', align:'center', type: 'date', flex:1, maxWidth:120,
            valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY")
        },
        { field: 'payment_date', headerAlign:'center', align:'center', headerName: 'Payment Date', type: 'date', flex:1, maxWidth:120,
            valueFormatter: ({ value }) =>  value && moment(value).format("DD/MM/YYYY")
        },
        { field: 'amount', headerName: 'Amount', type: 'number', flex:1, maxWidth:120,
            valueFormatter: ({ value }) => currencyFormatter.format(value)
        },
        { field: 'balance', headerName: 'Balance', type: 'number', flex:1, maxWidth:120,
            valueFormatter: ({ value }) => currencyFormatter.format(value)
        },
        { field: 'status', headerName:'Status', headerAlign:'left', align:'left', type: 'string', flex:1, maxWidth:150,
            valueOptions: ['PLANNED','PAID','PARTIAL','OVERDUE'],
            renderCell: (params) => {
                const status = {
                    'PLANNED':'warning',
                    'PAID':'success',
                    'DEFAULT':'error',
                    'PARTIAL':'warning',
                    'OVERDUE':'error',
                    'EXTENDED': 'warning',
                    'EXTENDED_PAID': 'success',
                    'DELAYED': 'error',
                    'DELAYED_PAID': 'success',
                }
                return(<StatusBall
                    variant="contained"
                    label={params.value}
                    color={status[params.value]}
                />)
            },
        },
        { field: 'actions', type: 'actions', headerName:'Action',  width: 100,
            getActions: (params) => {
                const actions = [];

                if(
                    (!['PAID','DELAYED_PAID','EXTENDED_PAID'].includes(params.row.status) &&
                    ['edit-account-entries'].some(i => permissions.includes(i))) ||
                    ['edit-paid-account'].some(i => permissions.includes(i))
                ){
                    actions.push(
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label="Edit"
                            onClick={viewItem(params.id)}
                        />
                    );
                }
                if (!['PAID','OVERDUE'].includes(params.row.status) && ['extend-account-payment'].some(i => permissions.includes(i))){
                    actions.push(
                        <GridActionsCellItem
                            icon={<CalendarMonthIcon />}
                            label="Extend"
                            onClick={extendPayment(params.id)}
                        />
                    );
                }

                return actions;
            },
        },
    ];

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <AccountExpandDetails data={row} setPanelHeight={setPanelHeight} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => panelHeight, [panelHeight]);

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
    };

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.account_id}
                    rows={items.list || []}
                    columns={columns}
                    sx={{ border:'none' }}
                    pagination
                    paginationMode="server"
                    paginationModel={pagination}
                    rowCount={items?.config?.rowCount ?? 0}
                    pageSizeOptions={[5, 10, 15, 25, 100]}
                    loading={isLoading}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={getDetailPanelHeight}
                    onRowSelectionModelChange={handleRowSelection}
                    onPaginationModelChange={(params) => dispatch(setConfig({pagination: {...pagination, page: params.page, pageSize: params.pageSize}}))}
                    rowSelectionModel={selectedRows}
                    checkboxSelection
                    initialState={{
                        columns:{
                            columnVisibilityModel:{
                                actions: ['edit-account-entries'].some(i => permissions.includes(i)),
                            }
                        },
                        sorting: {
                            sortModel: [{ field: 'created_at', sort: 'desc' }],
                        },
                        pinnedColumns: { right: ['actions'] },
                        pagination: { paginationModel: { ...pagination } }
                    }}
                />
                <Toastr response={response} />
            </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const shouldShowToggle = true;//!['PENDING','VERIFIED'].includes(props.row.status);

    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};
