import React from 'react'
import Typography from "@mui/material/Typography";
import {Grid, Card, CardActions, CardContent, List as ListUI, ListItem, ListItemText} from "@mui/material";
import Button from "@mui/material/Button";
import {RegisterBusinessModal} from "../../../Business/Components/Modals/RegisterBusinessModal";
import { setModal } from "../../../../Common/App";
import {useDispatch, useSelector} from "react-redux";
import {proceed} from "../../../Business";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";

export const RegisterBusinessCard = () => {

    const dispatch = useDispatch();
    const auth = useSelector(store => store.auth);
    const business = useSelector(store => store.business.list);

    const handleRegisterBtnClick = () => {
        dispatch(setModal({component: <RegisterBusinessModal />, open:true}))
        dispatch(proceed(false));
    }

    const List = styled(ListUI)({
        color: 'darkslategray',
        '& .MuiListItem-root': {
            padding: '1px!important',
            '& .MuiListItemText-root':{
                color:'gray',
                //margin:'0 0 2px 0',
                padding:'4px 10px',
                borderRadius:'4px',
                background: '#f8f8f8',
                marginBottom:'2px',
                minHeight:'50px',
                border:'1px solid #efefef',
                '& span':{
                    fontSize: '12px',
                    display:'inline-block'
                },
                '& p':{
                    fontSize:'12px',
                    fontWeight:'bold'
                }
            }
        }
    });

    const RegistrationList = styled(ListUI)({
        color: 'darkslategray',
        width:'100%',
        '& .MuiListItem-root': {
            padding: '5px!important',
            width:'100%',
            marginBottom:'0.05rem',
            '& .MuiListItemText-root':{
                color:'gray',
                marginBottom:'2px',
                '& span':{
                    fontSize: '0.85rem',
                    display:'inline-block',
                    fontWeight: 500,
                    color: '#333333'
                },
                '& p':{
                    fontSize:'0.95rem',
                    fontWeight:'normal',
                }
            }
        }
    });

    const statusToShow = ['SUBMITTED','VERIFIED','BH_APPROVED','REVIEWED','CREDIT_APPROVED','CEO_APPROVED','BOH_APPROVED','APPROVED'];

    return (
        <>
            <Card>
                <CardContent>
                    {business.filter((value) => statusToShow.includes(value.status?.name)).length > 0 ?
                        <>
                            <Typography>
                                Thank you for taking the first step toward partnering with us. Your business registration details have been submitted successfully. Our team is currently reviewing the provided information to ensure accuracy and compliance with our requirements.
                            </Typography>

                            <Typography sx={{fontWeight:'bold'}} mt={2}>
                                What to Expect:
                            </Typography>

                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography sx={{ fontWeight: 'bold' }}>Verification Process:</Typography>}
                                        secondary="Your application is in the verification and approval phase. Our team is working diligently to review the details you provided."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography sx={{ fontWeight: 'bold' }}>Processing Time:</Typography>}
                                        secondary="The verification process may take some time as we meticulously assess each aspect of your submission. Your patience during this time is greatly appreciated."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Typography sx={{ fontWeight: 'bold' }}>Updates:</Typography>}
                                        secondary="We will keep you informed of any progress or if additional information is needed. Please keep an eye on your dashboard for notifications from us."
                                    />
                                </ListItem>
                            </List>

                            <Typography sx={{fontWeight:'bold'}} my={2} >Need Assistance?</Typography>

                            <Typography sx={{mb:2}}>
                                If you have any urgent inquiries or need to update your business information, feel free to reach out to our support team. We are here to assist you.
                            </Typography>

                        </>
                        :
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 18, mb:3 }} variant="h3" color="text.primary" gutterBottom>
                                    Business Registration
                                </Typography>
                                <Typography variant="body1" sx={{pb:1}}>
                                    Welcome, <span style={{ fontWeight: 'bold', textTransform:'capitalize' }}>{auth.user.first_name}</span>
                                </Typography>
                                <Typography variant="body1" sx={{fontSize: '0.95rem'}}>
                                    To start the business registration, you should be having all the below documents / information handy to complete the registration, please attached all the required documents. Keep the following documents handy before starting the application:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <RegistrationList>
                                    <ListItem>
                                        <ListItemText primary="Company Documents" secondary="Trade License, All MOA, Tenancy, Utility Bill, VAT Registration Certificate, AECB Report, Company Profile" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Shareholder Documents" secondary="Passport (front and back), UAE Visa for resident, Emirates ID (front and back), Residence Utility bill / Tenancy Contract, AECB Report" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Financial" secondary="Audit Report (previous 2 year) & Year to Date (YTD) Inhouse Report" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Customer & Suppliers" secondary="Top 5 customers & suppliers Profile, Trade license, Ledger (previous 1 year)" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Bank Accounts" secondary="Bank account statement for each currency (previous 1 year)" />
                                    </ListItem>

                                </RegistrationList>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <RegistrationList>
                                    <ListItem>
                                        <ListItemText primary="Bank Borrowings" secondary="Loan Agreement or Facility Offer Letter" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="VAT Returns" secondary="Last 4 quarter/months VAT Return Statement & VAT Payment Copy" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="WPS Details" secondary="Last 3 months WPS Statement / Salary Summary Statement & MOL / Employee List" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="Invoice Discounting" secondary="Fill in Customer Details for Invoice Discounting their Profile, Trade License, Ledger Copy (last 1 year)" />
                                    </ListItem>
                                </RegistrationList>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{pb:2}}>
                                    For any assistance on above or you would like to more about it, please write to help@ifundfactoring.com
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </CardContent>
                <CardActions sx={{p:3}}>
                    {business.filter((value) => ['DRAFT','RETURNED'].includes(value.status?.name)).length > 0 && (
                        <Button color="warning" variant="contained" size="large" component={Link} to="/my-business">
                            Complete Registration
                        </Button>
                    )}
                    {business.length === 0 && (
                        <Button color="primary" variant="contained" size="large" onClick={() => handleRegisterBtnClick()}>
                            Register Business
                        </Button>
                    )}
                    {business.filter((value) => ['SUBMITTED'].includes(value.status?.name)).length > 0 && (
                        <Typography sx={{textAlign:'center', display:'block', width:'100%'}}>
                            Thank you for choosing us. We value your partnership and look forward to the possibility of working together.
                        </Typography>
                    )}
                </CardActions>
            </Card>
        </>
    );
}
