import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../Common/App"
import {fetchBusiness} from "../../Business";

export const ActionButtons = () => {

    const dispatch = useDispatch();
    const service = useSelector((store) => store.services.selected);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [selectedColor, setSelectedColor] = React.useState();
    const [options, setOptions] = React.useState([]);
    const auth = useSelector((store) => store.auth);
    const permissions = useSelector((store) => store.app.permissions);

    const handleClick = async (e) => {
        switch (options[selectedIndex].key) {
            // case 'issue-loan':
            //     await fetchBusiness({dispatch, id: service.business_id}).then((res) => {
            //         return dispatch(setModal({component:<IssueLoanModal />, open:true}));
            //     });
            default:
                return <></>;
        }
    };

    React.useEffect(()=>{
        const o = [];
        // Credit Manager requests documentation team to ready the documentation for loan
        if (['issue-loan'].some(i => permissions.includes(i)) && ['FUNDED'].includes(service.status?.name)) {
            o.push({'key':'issue-loan','value':'Issue Loan','color':'info'});
        }

        const u = [...new Map(o.map(item => [item['key'], item])).values()];
        setOptions(u);
        setSelectedIndex(0);
    },[service]);

    const handleMenuItemClick = (event, index, color) => {
        setSelectedIndex(index);
        setSelectedColor(color);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            {options.length > 0 &&
                <>
                    <ButtonGroup variant="contained" color={selectedColor} ref={anchorRef} aria-label="split button">
                        <Button onClick={handleClick}>{options[selectedIndex]?.value}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option.key}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index, option.color)}
                                                >
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            }
        </>
    );
}