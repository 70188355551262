import React, { useEffect, useRef, useState, useMemo } from 'react';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import debounce from 'lodash/debounce';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';

export const SearchBox = ({ filters, setConfig, debounceDelay = 300, ...params }) => {

    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [searchValue, setSearchValue] = useState(filters?.keyword || '');

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const debouncedSearchChange = useMemo(() =>
        debounce((newSearchValue) => {
            if (newSearchValue.length >= 3 || newSearchValue.length === 0) {
                dispatch(setConfig({ filters: { ...filters, keyword: newSearchValue } }));
            }
        }, debounceDelay), [debounceDelay, dispatch, filters, setConfig]);

    const handleChange = (e) => {
        const newSearchValue = e.target.value;
        setSearchValue(newSearchValue);
        debouncedSearchChange(newSearchValue);
    };

    const handleClear = () => {
        setSearchValue('');
        dispatch(setConfig({ ...filters, keyword: '' }));
        debouncedSearchChange('');
    };

    useEffect(() => {
        return () => {
            debouncedSearchChange.cancel();
        };
    }, [debouncedSearchChange]);

    return (
        <TextField
            sx={{
                '& .MuiInputBase-input': {
                    padding: '8px 14px',
                },
                p: 0
            }}
            value={searchValue}
            onChange={handleChange}
            inputRef={inputRef}
            {...params}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {searchValue.length > 0 && (
                            <IconButton onClick={handleClear}>
                                <ClearIcon />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};
