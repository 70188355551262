import React from 'react';
import { CardContent, CardHeader, Typography, Stack, Button } from "@mui/material";
import { BusinessOverviewTable } from "../Tables/BusinessOverviewTable";
import { Panel } from "../../../../../Components";
import { useDispatch, useSelector } from "react-redux";
import { syncBusinessOverview } from "../../Redux/Action";
import useDeepEqualSelector from "../../../../../Hooks/useDeepEqualSelector";
import moment from "moment/moment";

export const CompanyWiseOutstandingPanel = () => {

    const dispatch = useDispatch();
    const pagination = useDeepEqualSelector((store) => store.dashboard?.businessOverview?.config?.pagination);
    const filters = useSelector((store) => store.dashboard?.businessOverview?.config?.filters);
    const permissions = useSelector((store) => store.app?.permissions);
    const lsa = useSelector((store) => store.dashboard?.businessOverview?.last_sync_at);
    const [lastSyncAt, setLastSyncAt] = React.useState(lsa);

    React.useEffect(() => {
        setLastSyncAt(lsa);
    }, [lsa]);

    const handleSyncBtn = async () => {

        await syncBusinessOverview({dispatch, data:{...filters, ...pagination}}).then((res) => {
            if(res['success']){
                setLastSyncAt(res.data?.data?.last_sync_at);
            }
        });
    }

    return (
        <Panel>
            <CardHeader
                title={"Company Wise Overview"}
                action={
                    <Stack direction={"row"} spacing={2}>
                        { permissions.includes('sync-business-stats') && (
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Typography color={"secondary"} fontSize={"0.85rem"}>{ moment(lastSyncAt).format("DD/MM/YYYY h:mm:ss A") }</Typography>
                                <Button onClick={handleSyncBtn} size={"small"} variant={"outlined"}>Sync</Button>
                            </Stack>
                        )}
                    </Stack>
                }
            />
            <CardContent>
                <BusinessOverviewTable pageSizeOptions={[7]} />
            </CardContent>
        </Panel>
    );
};
