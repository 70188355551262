import React from "react";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useState, useEffect} from "react";
import { useDispatch, useSelector} from "react-redux";
import { changePassword } from "../../index";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { setModal } from "../../../../Common/App";
import Toastr from "../../../../../Components/Elements/Toastr";

export const ChangePassword = () => {

    const dispatch = useDispatch();
    const items = useSelector((store)=> store.users);
    const [response, setResponse] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({
        user_id:'',
        password : '',
    })

    useEffect(() => {
        setData({
            user_id: items.selected.user_id,
        });
    },[items.selected])

    const handleChange = (e) => {
        setDisabled(false);
        setData(data => ({
            ...data,
            [e.target.id] :e.target.value,
        }));
    }

    const handleSubmit = async () => {
        setDisabled(true);
        await changePassword({dispatch, data}).then((res) => {
            setResponse(res)
            if(res['success']){
                dispatch(setModal({component:null, open:false}));
            }
        });
    };

    return(
        <>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please type the new password.
                </DialogContentText>
                <TextField
                    fullWidth
                    label="New Password"
                    name="password"
                    type="password"
                    onChange={(e) => handleChange(e)}
                    id="password"
                    value={ data['password'] }
                    variant="outlined"
                    size="large"
                    sx={{my:1, flex:1}}
                    helperText={response?.error?.data?.errors?.password}
                />
            </DialogContent>
            <DialogActions sx={{mb:2,mr:2}}>
                <Button onClick={() => dispatch(setModal({component:null, open:false}))}>Cancel</Button>
                <Button disabled={disabled} variant={"contained"} color={'primary'} onClick={ handleSubmit }>Change Password</Button>
            </DialogActions>
            <Toastr response={response} />
        </>
    )
}
