import React, {useEffect, useState} from 'react'
import {Grid, Box, CardHeader, CardContent, Card} from "@mui/material";
import {useDispatch,} from "react-redux";
import {Breadcrumbs} from "../../../../Components";
import {
    CustomerTable,
    fetchCustomers,
    setSelectedCustomer,
} from "../index";
import Button from "@mui/material/Button";
import {CustomerForm} from "./Forms/CustomerForm";
import { setModal } from "../../../Common/App";

export const Customers = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = useState({});

    useEffect(() => {
        fetchData();
        dispatch(setModal({ component:null, open: false}))
    },[]);

    let fetchData = async () => {
        await fetchCustomers({dispatch, data:{'for_invoice_discounting': 1}}).then((res) => {
            setResponse(res);
        });
    }

    const handleActionBtn = (e) => {
        dispatch(setModal({component:<CustomerForm />, open:true}));
        dispatch(setSelectedCustomer({}));
    }

    return (
        <>
            {(response !== undefined && response.hasOwnProperty('success')) &&
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box justifyContent={"space-between"} display={"flex"}>
                            <Breadcrumbs items={'DASHBOARD/CUSTOMERS'} sx={{display:'inline-block'}} />
                            <Button variant="contained" data-id={"draft"} data-action={"AddItem"} data-is_customer={1} onClick={handleActionBtn}>Add Customer</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={"Customers"} />
                            <CardContent sx={{p:0}}>
                                <CustomerTable />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </>
            }
        </>
    );
}
