import React, {useEffect, useState} from 'react'
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setModal} from "../../../Common/App";
import { fetchBusiness } from "../index";
import {Wizard} from "./Wizard";
import {useParams} from "react-router-dom";
import TabView from "./TabView";

export const BusinessView = () => {

    const dispatch = useDispatch();
    let {business_id} = useParams();
    const business = useSelector((store) => store.business);
    const [response, setResponse] = useState({});
    const auth = useSelector((store) => store.auth);

    useEffect(() => {
        fetchData();
        dispatch(setModal({ component:null, open: false}))
    },[business_id]);

    let fetchData = async () => {
        if(business_id === undefined){
            if(business.list[0] === undefined){
                return;
            }else{
                business_id = business.list[0].business_id;
            }
        }

        await fetchBusiness({dispatch, id:business_id}).then((res) => {
            setResponse(res);
        });
    }

    return (
        <>
            {
                (() => {
                    if (response?.data?.data?.status?.name !== undefined) {
                        switch (response?.data?.data?.status?.name){
                            case 'DRAFT':
                            case 'RETURNED':
                                return (auth.user.role_type === 'CLIENT') ? <Wizard /> : <TabView/> ;
                            case 'SUBMITTED':
                            case 'VERIFIED':
                            case 'DECLINED':
                            case 'APPROVED':
                                return <TabView/>;
                            default:
                                return <TabView/>;
                        }
                    } else {
                        return <Typography>Loading...</Typography>;
                    }
                })()
            }

        </>
    );
}
