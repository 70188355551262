import React, {useState} from 'react'
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import BusinessTable from './Tables/BusinessTable';
import {Breadcrumbs} from "../../../../Components";
import {useDispatch} from "react-redux";
import { resetFilter } from "../index";
import { useEffect} from "react";
import {TableActions} from "./Menus/TableActions";

export const Business = () => {

    const dispatch = useDispatch();
    const [isFiltersReset, setIsFiltersReset] = useState(false);

    useEffect(() => {
        const resetFilters = async () => {
            await dispatch(resetFilter());
            setIsFiltersReset(true);
        };

        resetFilters();
    }, [dispatch]);

    if (!isFiltersReset) {
        return null; // Or a loading indicator can be placed here
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs items={'DASHBOARD/BUSINESS'} />
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={"Business"}
                            action={<TableActions />}
                        />
                        <CardContent sx={{p:0}}>
                            <BusinessTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
