import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    DataGridPro,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    gridDetailPanelExpandedRowsContentCacheSelector,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid-pro";
import { CardContent, CardHeader} from "@mui/material";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {InvoiceDetail} from "../../../../Corporate/Invoices/Components/Cards/InvoiceDetail";
import {fetchInvoice, fetchInvoices} from "../../../../Corporate/Invoices";
import {Panel} from "../../../../../Components";
import {useNavigate} from "react-router-dom";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PreviewIcon from "@mui/icons-material/Preview";
import {StatusBall} from "../../../../../Components/Elements/StatusBall";

export const InvoicesPanel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((store) => store.auth);
    const invoices = useSelector((store) => store.invoices);
    const [isLoading, setIsLoading] = React.useState(true);
    const [response, setResponse] = React.useState({});
    const [filters, setFilters] = React.useState({
        pageSize: '',
        pageNo:1,
        keyword:'',
    });

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {

        setIsLoading(true);
        await fetchInvoices({dispatch, data:filters}).then((res) => {
            setIsLoading(false);
            setResponse(res)
        })
    }

    const viewItem = async (row) => {
        await fetchInvoice({dispatch, id:row.invoice_id});
        navigate('/invoices/' + row.invoice_id)
    }

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <InvoiceDetail data={row} />,
        [],
    );

    const getDetailPanelHeight = React.useCallback(() => 50, []);

    const columns = React.useMemo(
        () => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => (
                    <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
                ),
            },
            { field: 'invoice_no', headerName:'Invoice No', type: 'string', minWidth:100, flex: 1,},
            { field: 'invoice_date', headerName:'Invoice Date', type: 'string', minWidth:100, flex: 1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'invoice_due_date', headerName:'Due Date', type: 'string', minWidth:100, flex: 1, valueFormatter: ({ value }) => value && moment(value).format("DD/MM/YYYY"),},
            { field: 'invoice_amount', headerName:'Amount', type: 'string', minWidth:100, flex: 1,},
            { field: 'status',headerAlign:'center', align:'center',  headerName:'Status', type: 'string', flex:1, minWidth:100,
                renderCell: (params) => {

                    const status = {
                        'DRAFT':'secondary',
                        'PENDING':'warning',
                        'SUBMITTED':'info',
                        'VERIFIED':'success',
                        'PROCESSING':'primary',
                        'APPROVED':'success',
                        'REJECTED':'error',
                        'CANCELLED':'secondary',
                    }
                    return(<StatusBall
                        variant="contained"
                        sx={{fontWeight:'normal'}}
                        label={params.value?.name}
                        color={status[params.value?.name]}
                    >
                    </StatusBall>)
                },
            },
            {
                field: 'actions',
                type: 'actions',
                headerName:'Action',
                flex:1,
                minWidth:80,
                getActions: (params) => {
                    return [
                        <GridActionsCellItem
                            icon={<PreviewIcon sx={{zIndex:-1}} />}
                            label="Edit"
                            data-id={params.id}
                            data-action={"ViewItem"}
                            onClick={ () => viewItem(params.row) }
                        />
                    ];
                },
            },
        ],
        [],
    );

    return (
        <>
            <Panel>
                <CardHeader title={'Invoices'} />
                <CardContent sx={{p:0, minHeight:'300px'}}>
                    <DataGridPro
                        autoHeight={false}
                        getRowId={(row) => row.invoice_id}
                        pageSize={filters.pageSize}
                        pageSizeOptions={[5]}
                        onPageChange={(newPageNo) => setFilters({...filters, pageNo: newPageNo})}
                        onPageSizeChange={(newPageSize) => setFilters({...filters, pageSize: newPageSize})}
                        columns={columns}
                        onCellClick={(params) => {
                            if (params.field === 'invoice_no') {
                                viewItem(params.row.invoice_id);
                            }
                        }}
                        loading={isLoading}
                        rows={invoices.list || []}
                        sx={{ border:'none'}}
                        rowThreshold={0}
                        //getDetailPanelContent={getDetailPanelContent}
                        //getDetailPanelHeight={getDetailPanelHeight}
                        initialState={{
                            pinnedColumns: { right: [ 'status','actions'] },
                            sorting: {
                                sortModel: [{ field: 'created_at', sort: 'desc' }],
                            },
                            columns:{
                                columnVisibilityModel:{
                                    assigned_to: ["ADMIN","SUPERADMIN"].includes(auth.user.role_type),
                                }
                            },
                            pagination: { paginationModel: { pageSize: 5 } }
                        }}
                        pagination
                    />
                </CardContent>
            </Panel>
        </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};