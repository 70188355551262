import React from 'react';
import { CardContent } from "@mui/material";
import { Popover } from "../../../../../Components/Elements/Popover";
import {useDispatch} from "react-redux";
import {currencyFormatter} from "../../../../../Setup/Config";
import {Typography, List, ListItem, ListItemText } from "@mui/material";
import {styled} from "@mui/material/styles";

export const ChargesPopover = ({charges}) => {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const calculatedEarning = (charges) => {

        return charges.reduce((total, charge) => {
            return total + parseInt(charge.charge_amount);
        }, 0);
    };

    const prepareName = (name) => {
        return name.replace(/_/g, ' ').toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    }

    const StyledList = styled(List)(({ theme }) => ({

        padding: theme.spacing(0),
        '& .MuiListItem-root':{
            padding: theme.spacing(1, 2),
            borderBottom: '1px solid #e0e0e0',
            '& .MuiListItemText-root':{
                padding: theme.spacing(0),
                margin: theme.spacing(0),
                '& span':{
                    fontSize: '0.8rem',
                    fontWeight: 'normal',
                },
                '& p':{
                    fontSize: '1rem',
                    color:'#222',
                    fontWeight: 'normal',
                },
            },
        },
        '& .MuiListItem-root:hover':{
            background: '#f5f5f5',
        },
        '& .MuiListItem-root:last-child':{
            border:'none'
        }
    }));

    return (
        <>
            <Typography
                sx={{cursor: 'pointer'}}
                onClick={handleClick}
            >
                {currencyFormatter.format(calculatedEarning(charges))}
            </Typography>
            <Popover title={"Earnings"} anchorEl={anchorEl} onClose={handleClose}>
                <StyledList>
                    {charges.map((charge, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={prepareName(charge.charge_type)}
                                secondary={currencyFormatter.format(charge.charge_amount)}
                            />
                        </ListItem>
                    ))}
                </StyledList>
            </Popover>
        </>
    );
};
