import React from "react";
import { IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {SearchBox} from "../../../../../Components";
import {useSelector} from "react-redux";
import {setConfig} from "../../index";
import Box from "@mui/material/Box";

export const TableActions = () => {

    const filters = useSelector((store) => store.loans?.config?.filters);

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {/*<CardMenu />*/}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <SearchBox type={"loan"} placeholder={"Search"} filters={filters} setConfig={setConfig} />
                {/*<CardMenu />*/}
            </Box>
        </>
    );
}

const CardMenu = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                aria-labelledby="long-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleClose}>Add New</MenuItem>
                <MenuItem onClick={handleClose}>Export</MenuItem>
                <MenuItem onClick={handleClose}>Import</MenuItem>
            </Menu>
        </>
    );
}